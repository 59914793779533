import { combineReducers } from "redux";
import USER_REDUCER from "./user";
import METADATA_REDUCER from "./metaData";
import MAINTENANCE_REDUCER from './maintenance';
import PAYMENT_REDUCER from './payments';
import PEOPLE_REDUCER from './people';
import PARKING_REDUCER from './parking';
import NOTICE_VACATE_REDUCER from './noticeToVacate';
import OFFICEDASH_REDUCER from './officeDash'
import LOADING_REDUCER from './loading';
import TWO_FACTOR_AUTH_REDUCER from './twoFactorAuth'

const appReducer = combineReducers({ 
  USER_REDUCER, 
  METADATA_REDUCER, 
  MAINTENANCE_REDUCER,
  PAYMENT_REDUCER,
  PEOPLE_REDUCER,
  PARKING_REDUCER,
  NOTICE_VACATE_REDUCER,
  OFFICEDASH_REDUCER,
  LOADING_REDUCER,
  TWO_FACTOR_AUTH_REDUCER
});

const rootReducer = (state, action) => {
  // if (action.type === "LOGOUT") {
  //   state = {};
  // }

  return appReducer(state, action);
};

export default rootReducer;
