const initialState = {
  error: {},
  loading: false,
  paymentDetail: {},
  payments:[],
  formSubmitted: false
};

const PAYMENT_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PAYMENT_LOADING": {
      return {
        ...state,
        formSubmitted: false,
        loading: true,
      };
    }
    case "PAYMENT_COMPLETE": {
      return {
        ...state,
        loading: false,
      };
    }
    case "PAYMENT_SUBMITTED": {
      return {
        ...state,
        loading: false,
        formSubmitted: true
      };
    }
    case "VIEW_PAYMENT": {
      return {
        ...state,
        paymentDetail: {
          pdfData: payload,
        },
      };
    }
    case "CLEAR_PAYMENTDETAILS": {
      return {
        ...state,
        paymentDetail: {},
      };
    }
    case "PAYMENT_ERROR": {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case "ALL_PAYMENT": {
      return {
        ...state,
        payments: payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default PAYMENT_REDUCER;
