import React, { useState } from "react";
import { errors, spanishErrors, states } from "./constants";
import { Validation } from "../../helpers/validations";
import { useTranslation } from "react-i18next";

function PaymentAddress({
  handleSubmit,
  address,
  setAddress,
  handleNextStep,
  loading,
}) {
  const [error, setError] = useState({});
  const { t } = useTranslation();

  // validator function for payment address
  const handleValidation = (type) => {
    let { addressLine1, city, state, country, postalCode } = address;
    let lang = localStorage.getItem('i18nextLng') === "es" ? true : false
    if (type == "addressLine1") {
      if (Validation.empty(addressLine1)) {
        setError({ ...error, addressLine1: lang ? spanishErrors.addressLine1 : errors.addressLine1 });
        return false;
      }
    } else if (type == "city") {
      if (Validation.empty(city)) {
        setError({ ...error, city: lang ? spanishErrors.city : errors.city });
        return false;
      }
    } else if (type == "state") {
      if (Validation.empty(state)) {
        setError({ ...error, state: lang ? spanishErrors.state : errors.state });
        return false;
      }
    } else if (type == "postalCode") {
      if (!Validation.postalCode(postalCode)) {
        setError({ ...error, postalCode: lang ? spanishErrors.postalCode : errors.postalCode });
        return false;
      }
    }
    return true;
  };

  const handleAddressChange = (key, value) => {
    setAddress({ ...address, [key]: value });
  };

  // function to make payment with billing address
  const handleMakePayment = (e) => {
    let fields = ["addressLine1", "city", "state", "postalCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (isvalid) {
      handleSubmit(e);
    }
  };

  return (
    <section className="paymentform-container">
      {/* <div className="note bg-yellow-transparent-5 note-with-right-icon"> */}
      <div className="note note-with-right-icon">
        <h4>{t('modal.payment.info-text2')}</h4>
        {/* <div className="note-icon">
          <i className="fa fa-address-card"></i>
        </div>
        <div className="note-content text-right">
          <p>
            <br />
            {t('modal.payment.info-text2')}
            <br />
          </p>
        </div> */}
      </div>

      <div className="form-group m-b-10">
        <input
          type="text"
          className={`form-control form-control-lg ${
            error.addressLine1 && "is-invalid"
          }`}
          placeholder={t('modal.payment.addL1')}
          value={address.addressLine1}
          onChange={(e) => handleAddressChange("addressLine1", e.target.value)}
          onBlur={(e) => handleValidation("addressLine1")}
          onFocus={(e) => setError({ ...error, addressLine1: "" })}
        />
        <h5 className="invalid-feedback">{error.addressLine1}</h5>
      </div>

      <div className="form-group m-b-10">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder={t('modal.payment.addL2')}
          value={address.addressLine2}
          onChange={(e) => handleAddressChange("addressLine2", e.target.value)}
        />
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group m-b-10">
            <select
              className={`form-control form-control-lg ${
                error.state && "border border-danger"
              }`}
              value={address.state}
              onChange={(e) => handleAddressChange("state", e.target.value)}
              onBlur={(e) => handleValidation("state")}
              onFocus={(e) => setError({ ...error, state: "" })}
            >
              <option value="">Select State</option>
              {states.map((item, i) => (
                <option value={item.State}>{item.State}</option>
              ))}
            </select>
            <h5 className="invalid-feedback">{error.state}</h5>
          </div>
        </div>
        <div className="col-md-8">
          <div className="form-group m-b-10">
            <input
              type="text"
              className={`form-control form-control-lg ${
                error.postalCode && "is-invalid"
              }`}
              placeholder={t('modal.payment.postal')}
              value={address.postalCode}
              onChange={(e) =>
                setAddress({
                  ...address,
                  postalCode: e.target.value.replace(/[^0-9\-]/g, ""),
                })
              }
              onBlur={(e) => handleValidation("postalCode")}
              onFocus={(e) => setError({ ...error, postalCode: "" })}
            />
            <h5 className="invalid-feedback">{error.postalCode}</h5>
          </div>
        </div>
      </div>

      <div className="form-group m-b-10">
        <input
          type="text"
          className={`form-control form-control-lg ${
            error.city && "is-invalid"
          }`}
          placeholder={t('modal.payment.city')}
          value={address.city}
          onChange={(e) => handleAddressChange("city", e.target.value)}
          onBlur={(e) => handleValidation("city")}
          onFocus={(e) => setError({ ...error, city: "" })}
        />
        <h5 className="invalid-feedback">{error.city}</h5>
      </div>

      <div className="row">
        <div className="col-sm-2">
          <button className="btn btn-secondary" onClick={handleNextStep}>
          {t('modal.payment.back')}
          </button>
        </div>
        <div className="col-sm-10">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-warning btn-block"
            onClick={(e) => handleMakePayment(e)}
          >
            {!loading ? (
              `${t('modal.payment.make-payment')}`
            ) : (
              <i className="fas fa-spinner fa-pulse"></i>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default PaymentAddress;
