import React from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { connect } from "react-redux";
import {
  LoginStaffAction,
  GetOfficeDashData
} from "../../redux/actions/officeDash";

class LoginDash extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);

    this.state = {
      user: "",
      password: "",
      loading: false,
      remember: false,
      activeBg: "/assets/img/login-bg/login-bg-17.jpg",
    };
  }

  UNASAFE_componentWillMount() {
    if (this.props.isStaffAuth) {
      this.props.history.push("/officedash");
    }
  }

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);

    const credentials = localStorage.getItem("loginDash");
    if (credentials) {
      const { user, password } = JSON.parse(credentials)
      this.setState({ user, password, remember: true });
    }
  }

  getData = async () => {
    let promises = [];

    promises.push(this.props.GetOfficeDashData(1));
    promises.push(this.props.GetOfficeDashData(2));
    promises.push(this.props.GetOfficeDashData(3));
    promises.push(this.props.GetOfficeDashData(4));
    promises.push(this.props.GetOfficeDashData(5));
    promises.push(this.props.GetOfficeDashData(6));

    await Promise.all(promises);
    this.props.history.push("/officedash");
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.isStaffAuth) {
      this.getData();
    }
  }

  // on complete callback
  onComplete = () => {
    let payload = { user: this.state.user, password: this.state.password };
    this.state.remember
      ? localStorage.setItem("loginDash", JSON.stringify(payload))
      : localStorage.removeItem("loginDash");
    this.setState({ loading: false });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { user, password } = this.state;
    let payload = { user: user, password: password };
    this.props.LoginStaffAction(payload, this.onComplete);
    this.setState({ loading: true });
  }

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="login-cover">
          {/* <div
            className="login-cover-image"
            style={{ backgroundImage: "url(" + this.state.activeBg + ")" }}
          ></div>
          <div className="login-cover-bg"></div> */}
        </div>

        <div className="login login-v2">
          <div className="login-header">
            <div className="brand">
              <b>Ania Management</b>
              <small>Welcome</small>
            </div>
            {/* <div className="icon">
              <i className="fa fa-lock"></i>
            </div> */}
          </div>
          <div className="login-content">
            <form className="margin-bottom-0" onSubmit={this.handleSubmit}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className={`form-control form-control-lg`}
                  placeholder="Username"
                  value={this.state.user}
                  onChange={(e) => this.setState({ user: e.target.value })}
                  required
                />
              </div>
              <div className="form-group m-b-20">
                <input
                  type="password"
                  className={`form-control form-control-lg`}
                  placeholder={"password"}
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  required
                />
              </div>
              <div className="d-flex justify-content-between">
                <div className="checkbox checkbox-css m-b-20">
                  <input
                    type="checkbox"
                    id="remember_checkbox"
                    checked={this.state.remember}
                    onChange={(e) => this.setState({ remember: !this.state.remember })}
                  />
                  <label htmlFor="remember_checkbox">{"Remember Me"}</label>
                </div>
              </div>
              <div className="login-buttons">
                <button type="submit" className="btn btn-success btn-block btn-lg">
                  {!loading ? 'Sign in' : <i className="fas fa-spinner fa-pulse fa-2x"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ OFFICEDASH_REDUCER }) => ({
  isStaffAuth: OFFICEDASH_REDUCER && OFFICEDASH_REDUCER.isStaffAuth,
});

export default connect(mapStateToProps, {
  LoginStaffAction,
  GetOfficeDashData
})(withRouter(LoginDash));
