import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { Validation } from "../../helpers/validations";
import { errors, spanishErrors, states, Makes, Models } from "./constant";
import WrapperComponent from "../wrapperComponent";
import { AddParking, EditParking } from "../../redux/actions/parking";
import Select from "react-select";
import * as _ from "lodash";
import { useTranslation } from 'react-i18next';

const ParkingForm = memo(
  ({ loading, user = {}, closeModal, edit, item, AddParking, EditParking, parkingSpotList, openParkingAlert, closeModalAdd }) => {
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({ Model: "", Make: "", Year: "", Color: "", LicensePlate: "", state: "", spot: "" });
    const { t } = useTranslation();
    
    useEffect(() => {
      if (item.recordId) {
        setFormData({
          Model: item["Lease_LVEH_LeaseVehicles::Model"],
          Make: item["Lease_LVEH_LeaseVehicles::Make"],
          Year: item["Lease_LVEH_LeaseVehicles::Year"],
          Color: item["Lease_LVEH_LeaseVehicles::Color"],
          LicensePlate: item["Lease_LVEH_LeaseVehicles::LicensePlate"],
          state: item["Lease_LVEH_LeaseVehicles::RegisteredState"],
          spot: item["Lease_LVEH_LeaseVehicles::zzid_SPOT"],
        });
      }
    }, [item]);

    // function which handles input values
    const handleChange = (e, name) => {
      if (name == "Year") {
        setFormData({ ...formData, [name]: e.target.value.replace(/[^0-9]/g, "").slice(0, 4) });
      } else {
        setFormData({ ...formData, [name]: e.target.value });
      }
    };

    // function returns all models of a vehicles
    const getModels = () => {
      return Models.filter((item) => item.make == formData.Make).map((ele) => ({
        value: ele.model,
        label: ele.model
      }));
    };

    // validator function
    const handleValidation = (type) => {
      let lang = localStorage.getItem('i18nextLng') === "es" ? true : false
      if (type == "Model") {
        if (!Validation.name(formData.Model)) {
          setError({ ...error, Model: lang ? spanishErrors.Model : errors.Model });
          return false;
        }
      } else if (type == "Make") {
        if (!Validation.name(formData.Make)) {
          setError({ ...error, Make: lang ? spanishErrors.Make : errors.Make });
          return false;
        }
      } else if (type == "Color") {
        if (!Validation.name(formData.Color)) {
          setError({ ...error, Color: lang ? spanishErrors.Color : errors.Color });
          return false;
        }
      } else if (type == "Year") {
        if (!Validation.year(formData.Year)) {
          setError({ ...error, Year: lang ? spanishErrors.Year : errors.Year });
          return false;
        }
      } else if (type == "LicensePlate") {
        if (!Validation.name(formData.LicensePlate)) {
          setError({ ...error, LicensePlate: lang ? spanishErrors.LicensePlate : errors.LicensePlate });
          return false;
        }
      } else if (type == "state") {
        if (!Validation.name(formData.state)) {
          setError({ ...error, state: lang ? spanishErrors.state : errors.state });
          return false;
        }
      }
      return true;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      let fields = [ "Make", "Model", "Year", "Color", "LicensePlate", "state", "spot" ];
      let isvalid = false;
      for (let i = 0; i < fields.length; i++) {
        isvalid = handleValidation(fields[i]);
        if (!isvalid) break;
      }
      if (!isvalid) return;
      let { Color, state, Year, LicensePlate, Make, Model, spot } = formData;
      let payload = {
        Color,
        RegisteredState: state,
        Year,
        LicensePlate,
        Make: Make,
        Model,
        zzid_SPOT: spot || undefined,
        zzModifiedByWeb: !edit ? user.Email_01 : undefined,
        recordId: edit ? item.recordId : undefined,
      };
      let zzid = item["Lease_LVEH_LeaseVehicles::zzID"];
      edit
        ? EditParking(zzid, payload, closeModal)
        : AddParking(payload, closeModalAdd);
    };

    return (
      <section className="parkingform-container">
        <div className="form-group m-b-10">
          <Select
            isDisabled={edit}
            className={`${error.Make && "is-invalid"}`}
            placeholder="Select Make"
            options={Makes.map(({ make }) => ({
              value: make,
              label: make,
            }))}
            value={{
              value: formData.Make || "",
              label: formData.Make || "Select Make",
            }}
            onChange={(selectedOption) => {
              {
                setFormData({
                  ...formData,
                  Make: selectedOption.value,
                  Model: "",
                });
              }
            }}
            onBlur={(e) => handleValidation("Make")}
            onFocus={(e) => setError({ ...error, Make: "" })}
          />
          <h5 className="invalid-feedback">{error.Make}</h5>
        </div>

        <div className="form-group m-b-10">
          <Select
            isDisabled={edit}
            className={`${error.Model && "is-invalid"}`}
            options={getModels()}
            placeholder="Select Model"
            onChange={(selectedOption) =>
              setFormData({ ...formData, Model: selectedOption.value })
            }
            value={{
              value: formData.Model || "",
              label: formData.Model || "Select Model",
            }}
            onBlur={(e) => handleValidation("Model")}
            onFocus={(e) => setError({ ...error, Model: "" })}
          />
          <h5 className="invalid-feedback">{error.Model}</h5>
        </div>

        <div className="form-group m-b-10">
          <input
            type="text"
            className={`form-control form-control-lg ${
              error.Year && "is-invalid"
            }`}
            disabled={edit}
            placeholder="Year"
            value={formData.Year}
            onChange={(e) => handleChange(e, "Year")}
            onBlur={(e) => handleValidation("Year")}
            onFocus={(e) => setError({ ...error, Year: "" })}
          />
          <h5 className="invalid-feedback">{error.Year}</h5>
        </div>

        <div className="form-group m-b-10">
          <input
            type="text"
            className={`form-control form-control-lg ${
              error.Color && "is-invalid"
            }`}
            disabled={edit}
            placeholder="Color"
            value={formData.Color}
            onChange={(e) => handleChange(e, "Color")}
            onBlur={(e) => handleValidation("Color")}
            onFocus={(e) => setError({ ...error, Color: "" })}
          />
          <h5 className="invalid-feedback">{error.Color}</h5>
        </div>

        <div className="form-group m-b-10">
          <input
            type="text"
            className={`form-control form-control-lg ${
              error.LicensePlate && "is-invalid"
            }`}
            disabled={edit}
            placeholder="LicensePlate Number"
            value={formData.LicensePlate}
            onChange={(e) => handleChange(e, "LicensePlate")}
            onBlur={(e) => handleValidation("LicensePlate")}
            onFocus={(e) => setError({ ...error, LicensePlate: "" })}
          />
          <h5 className="invalid-feedback">{error.LicensePlate}</h5>
        </div>

        <div className="form-group m-b-10">
          <select
            className={`form-control form-control-lg ${
              error.state && "border border-danger"
            }`}
            disabled={edit}
            value={formData.state && formData.state}
            onChange={(e) => handleChange(e, "state")}
            onBlur={(e) => handleValidation("state")}
            onFocus={(e) => setError({ ...error, state: "" })}
          >
            <option value="">Select State</option>
            {states.map((item, i) => (
              <option key={i} value={item.Code}>{item.State}</option>
            ))}
          </select>
          <h5 className="invalid-feedback">{error.state}</h5>
        </div>
        {parkingSpotList?.length > 0 && (
          <div className="form-group m-b-10">
            <select
              className={`form-control form-control-lg m-t-10 ${
                error.spot && "border border-danger"
              }`}
              value={formData.spot}
              onChange={(e) => handleChange(e, "spot")}
              onBlur={(e) => handleValidation("spot")}
              onFocus={(e) => setError({ ...error, spot: "" })}
            >
              <option value="">Select Spot</option>
              {parkingSpotList?.map((item, i) => (
                <option
                  disabled={item.isUsed}
                  style={item.isUsed ? { color: "grey" } : { color: "black" }}
                  key={i}
                  value={item.id}
                >
                  {`${item.spot} ${
                    item.isUsed ? item.isUsedby : "(Unassigned)"
                  }`}
                </option>
              ))}
            </select>
            <h5 className="invalid-feedback">{error.spot}</h5>
          </div>
        )}

        {edit && !item["Lease_LVEH_LeaseVehicles::isArchived"] && (
          <button
            className={`btn btn-sm btn-default`}
            onClick={openParkingAlert}
          >
            {t('modal.parking.archive')}
          </button>
        )}

        <button
          type="submit"
          disabled={loading}
          className="btn btn-warning btn-block m-t-10"
          onClick={handleSubmit}
        >
          {!loading ? `${t('modal.parking.submit')}` : <i className="fas fa-spinner fa-pulse"></i>}
        </button>
      </section>
    );
  }
);

const mapStateToProps = ({
  PARKING_REDUCER,
  USER_REDUCER,
  METADATA_REDUCER,
}) => {
  return {
    loading: PARKING_REDUCER.loading,
    user: USER_REDUCER.userData,
    parkingSpotList: METADATA_REDUCER.parkingSpotList,
  };
};

const ParkingWrapper = (props) => (
  <WrapperComponent>
    <ParkingForm {...props} />
  </WrapperComponent>
);
export default connect(mapStateToProps, {
  AddParking,
  EditParking,
})(ParkingWrapper);
