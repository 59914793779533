export const cardImages = {
  visa: "fab fa-cc-visa fa-3x",
  mastercard: "fab fa-cc-mastercard fa-3x",
  maestro: "fab fa-cc-maestro fa-3x",
  "american-express": "fab fa-cc-amex fa-3x",
  "diners-club": "fab fa-cc-diners-club fa-3x",
  jcb: "fab fa-cc-jcb fa-3x",
  paypal: "fab fa-cc-paypal fa-3x",
  stripe: "fab fa-cc-stripe fa-3x",
  default: "fas fa-credit-card fa-3x"
};
export const errors = {
  name: "Name must have atleast 2 characters",
  cardNumber: `Invalid Card Number`,
  expirationDate: `Expiration Date should be format xx/20xx`,
  cardExpired: `Your card has expired.`,
  cardCode: `CVV should be of 3 or 4 characters long`,
  amount: `Amount should be greater than $`,
  addressLine1: `Address cannot be empty`,
  city: `City cannot be empty`,
  state: `State cannot be empty`,
  country: `Country cannot be empty`,
  postalCode: `Enter a valid US postal code`
};

export const spanishErrors = {
  name: "El nombre debe tener al menos 2 caracteres",
  cardNumber: `Numero de tarjeta invalido`,
  expirationDate: `La fecha de vencimiento debe tener el formato xx/20xx`,
  cardExpired: `Tu tarjeta ha caducado.`,
  cardCode: `El CVV debe tener 3 o 4 caracteres de longitud.`,
  amount: `La cantidad debe ser superior a $`,
  addressLine1: `La dirección no puede estar vacía`,
  city: `La ciudad no puede estar vacía`,
  state: `El estado no puede estar vacío`,
  country: `El país no puede estar vacío`,
  postalCode: `Ingrese un código postal válido de USA`
}

export const states = [
  {
    State: "Alabama",
    Abbrev: "Ala.",
    Code: "AL",
  },
  {
    State: "Alaska",
    Abbrev: "Alaska",
    Code: "AK",
  },
  {
    State: "Arizona",
    Abbrev: "Ariz.",
    Code: "AZ",
  },
  {
    State: "Arkansas",
    Abbrev: "Ark.",
    Code: "AR",
  },
  {
    State: "California",
    Abbrev: "Calif.",
    Code: "CA",
  },
  {
    State: "Colorado",
    Abbrev: "Colo.",
    Code: "CO",
  },
  {
    State: "Connecticut",
    Abbrev: "Conn.",
    Code: "CT",
  },
  {
    State: "Delaware",
    Abbrev: "Del.",
    Code: "DE",
  },
  {
    State: "District of Columbia",
    Abbrev: "D.C.",
    Code: "DC",
  },
  {
    State: "Florida",
    Abbrev: "Fla.",
    Code: "FL",
  },
  {
    State: "Georgia",
    Abbrev: "Ga.",
    Code: "GA",
  },
  {
    State: "Hawaii",
    Abbrev: "Hawaii",
    Code: "HI",
  },
  {
    State: "Idaho",
    Abbrev: "Idaho",
    Code: "ID",
  },
  {
    State: "Illinois",
    Abbrev: "Ill.",
    Code: "IL",
  },
  {
    State: "Indiana",
    Abbrev: "Ind.",
    Code: "IN",
  },
  {
    State: "Iowa",
    Abbrev: "Iowa",
    Code: "IA",
  },
  {
    State: "Kansas",
    Abbrev: "Kans.",
    Code: "KS",
  },
  {
    State: "Kentucky",
    Abbrev: "Ky.",
    Code: "KY",
  },
  {
    State: "Louisiana",
    Abbrev: "La.",
    Code: "LA",
  },
  {
    State: "Maine",
    Abbrev: "Maine",
    Code: "ME",
  },
  {
    State: "Maryland",
    Abbrev: "Md.",
    Code: "MD",
  },
  {
    State: "Massachusetts",
    Abbrev: "Mass.",
    Code: "MA",
  },
  {
    State: "Michigan",
    Abbrev: "Mich.",
    Code: "MI",
  },
  {
    State: "Minnesota",
    Abbrev: "Minn.",
    Code: "MN",
  },
  {
    State: "Mississippi",
    Abbrev: "Miss.",
    Code: "MS",
  },
  {
    State: "Missouri",
    Abbrev: "Mo.",
    Code: "MO",
  },
  {
    State: "Montana",
    Abbrev: "Mont.",
    Code: "MT",
  },
  {
    State: "Nebraska",
    Abbrev: "Nebr.",
    Code: "NE",
  },
  {
    State: "Nevada",
    Abbrev: "Nev.",
    Code: "NV",
  },
  {
    State: "New Hampshire",
    Abbrev: "N.H.",
    Code: "NH",
  },
  {
    State: "New Jersey",
    Abbrev: "N.J.",
    Code: "NJ",
  },
  {
    State: "New Mexico",
    Abbrev: "N.M.",
    Code: "NM",
  },
  {
    State: "New York",
    Abbrev: "N.Y.",
    Code: "NY",
  },
  {
    State: "North Carolina",
    Abbrev: "N.C.",
    Code: "NC",
  },
  {
    State: "North Dakota",
    Abbrev: "N.D.",
    Code: "ND",
  },
  {
    State: "Ohio",
    Abbrev: "Ohio",
    Code: "OH",
  },
  {
    State: "Oklahoma",
    Abbrev: "Okla.",
    Code: "OK",
  },
  {
    State: "Oregon",
    Abbrev: "Ore.",
    Code: "OR",
  },
  {
    State: "Pennsylvania",
    Abbrev: "Pa.",
    Code: "PA",
  },
  {
    State: "Rhode Island",
    Abbrev: "R.I.",
    Code: "RI",
  },
  {
    State: "South Carolina",
    Abbrev: "S.C.",
    Code: "SC",
  },
  {
    State: "South Dakota",
    Abbrev: "S.D.",
    Code: "SD",
  },
  {
    State: "Tennessee",
    Abbrev: "Tenn.",
    Code: "TN",
  },
  {
    State: "Texas",
    Abbrev: "Tex.",
    Code: "TX",
  },
  {
    State: "Utah",
    Abbrev: "Utah",
    Code: "UT",
  },
  {
    State: "Vermont",
    Abbrev: "Vt.",
    Code: "VT",
  },
  {
    State: "Virginia",
    Abbrev: "Va.",
    Code: "VA",
  },
  {
    State: "Washington",
    Abbrev: "Wash.",
    Code: "WA",
  },
  {
    State: "West Virginia",
    Abbrev: "W.Va.",
    Code: "WV",
  },
  {
    State: "Wisconsin",
    Abbrev: "Wis.",
    Code: "WI",
  },
  {
    State: "Wyoming",
    Abbrev: "Wyo.",
    Code: "WY",
  },
];
