
const initialState = {
    is_phone_verified_tenant_app: false,
    is_loading: false
};

const TWO_FACTOR_AUTH_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "START_TWO_FACTOR_AUTH_LOADING": {
      return {
        ...state,
        is_loading: true,
      };
    }
    case "STOP_TWO_FACTOR_AUTH_LOADING": {
      return {
        ...state,
        is_loading: false,
      };
    }
    case "SET_IS_PHONE_VERIFIED_TENANT_APP": {
        return {
          ...state,
          is_phone_verified_tenant_app: payload,
        };
      }
    default:
      return state;
  }
};

export default TWO_FACTOR_AUTH_REDUCER;