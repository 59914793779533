import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuth, isPasswordReset } = useSelector(({ USER_REDUCER }) => ({
    isAuth: USER_REDUCER.isAuth,
    isPasswordReset: USER_REDUCER.userData && USER_REDUCER.userData?.isNeedsPasswordReset,
  }));
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth && !isPasswordReset ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
