import Api from "../api/api";
import { Notify } from "../../helpers/notification";
import { getDashboardData } from "./user";

const PARKING_LOADING = () => {
  return { type: "PARKING_LOADING" };
};

const PARKING_COMPLETE = () => {
  return { type: "PARKING_COMPLETE" };
};

export const AddParking = (payload, cb) => (dispatch) => {
  dispatch(PARKING_LOADING());
  Api.post("api/parking/addParking", payload)
    .then((response) => {
      dispatch(getDashboardData());
      dispatch(PARKING_COMPLETE());
      cb && cb()  // callback to close modal
      // process.env.NODE_ENV == "development" &&
        Notify("success", response.data.message);
    })
    .catch((err) => {
      dispatch(PARKING_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const ViewParking = (id) => (dispatch) => {
  dispatch(PARKING_LOADING());
  Api.get(`api/parking/viewParking/${id}`)
    .then((response) => {
      dispatch(PARKING_COMPLETE());
      dispatch({ type: "VIEW_PARKING", payload: response.data.data });
      process.env.NODE_ENV == "development" &&
        Notify("success", response.data.message);
    })
    .catch((err) => {
      dispatch(PARKING_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const EditParking = (id, payload, cb) => (dispatch) => {
  dispatch(PARKING_LOADING());
  Api.post(`api/parking/editParking/${id}`, payload)
    .then((response) => {
      dispatch(getDashboardData());
      dispatch(PARKING_COMPLETE());
      cb && cb()
      // process.env.NODE_ENV == "development" &&
        Notify("success", response.data.message);
    })
    .catch((err) => {
      dispatch(PARKING_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const ToggleArchiveParking = (id, payload, cb) => (dispatch) => {
  dispatch(PARKING_LOADING());
  Api.post(`api/parking/archiveParking/${id}`, payload)
    .then((response) => {
      dispatch(getDashboardData());
      dispatch(PARKING_COMPLETE());
      // process.env.NODE_ENV == "development" &&
        Notify("success", response.data.message);
        cb && cb()
    })
    .catch((err) => {
      dispatch(getDashboardData());
      dispatch(PARKING_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};


export const GetCarInfoAction = (cb) => (dispatch) => {
  Api.get(`api/parking/get-car-info`)
    .then((response) => {
        dispatch({type:'SET_CAR_INFO', payload: response?.data?.data || []})
        cb && cb()
    })
    .catch((err) => {
    })
    .catch(() => {
    });
};