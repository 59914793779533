/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { Step, Stepper } from "react-form-stepper";
import PersonalInformation from "./PersonalInformation";
import HousingInformation from "./HousingInformation";
import References from "./References";
import EmploymentHistory from "./EmploymentHistory";
import Documents from "./Documents";
import logo from "../../assets/img/logo/AniaLogo-full.svg";
import Agreement from "./Agreement";
import { useDispatch, useSelector } from "react-redux";
import { Notify } from "../../helpers/notification";
import { GetCarInfoAction } from "../../redux/actions/parking";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { onBoardTenantFromOfficeAction, uploadSignatureToFileMakerAction } from "../../redux/actions/webqueue";
import { useHistory } from "react-router-dom";

const OnBoardTenantFromOffice = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const context = useContext(PageSettings);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") === "es" ? "Spanish" : "English"
  );
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const styleConfig = {
    activeBgColor: "#007cba",
    completedBgColor: "#00acac",
  };

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
    dispatch(GetCarInfoAction());
  }, []);

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleNavigation = async (direction, navigate = true, signature = null) => {
    if (direction === "previous") {
      setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
    }
    if (direction === "next") {
      setActiveStep(activeStep < 4 ? activeStep + 1 : activeStep);
    }
    if (direction === "documents") {
      try {
        let details = {
          ...form,
        };
        if (!form.Car) {
          details.Car = "No";
        }
        const result = await dispatch(onBoardTenantFromOfficeAction(details));
        const formData = new FormData();
        formData.append("recordId", result.recordId);
        formData.append("Signature_Applicant", signature)
        await dispatch(uploadSignatureToFileMakerAction(formData));
        history.push("/thank-you");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderLanguageMenu = () => {
    return (
      <Dropdown
        isOpen={dropDownOpen}
        toggle={toggle}
        className="dropdown navbar-languager"
      >
        <DropdownToggle className="dropdown-toggle" tag="a">
          <span
            className={`flag-icon ${
              selectedLanguage === "Spanish" ? `flag-icon-es` : `flag-icon-us`
            } m-r-5`}
          ></span>
          <span className="name d-none d-sm-inline">{selectedLanguage}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right">
          <DropdownItem
            onClick={(e) => {
              setSelectedLanguage("English");
              i18n.changeLanguage("en");
            }}
          >
            <span className="flag-icon flag-icon-us m-r-5"></span> English
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              setSelectedLanguage("Spanish");
              i18n.changeLanguage("es");
            }}
          >
            <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderApplicationContent = () => {
    return (
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-xl-3 col-12">
            <img src={logo} alt="" />
          </div>
          <div className="col-sm-8 col-xl-9 col-12">
            <div className="row align-items-center justify-content-end">
              <div className="col-sm-3 col-xl-2 col-4 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
                {renderLanguageMenu()}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <Stepper activeStep={activeStep}>
              <Step
                label={t("tenantApp.personal_info")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.housing_infor")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.references")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.employment")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.agreement")}
                styleConfig={styleConfig}
              />
            </Stepper>
          </div>
        </div>
        {activeStep === 0 ? (
          <PersonalInformation
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            loading={loading}
            hideSave={true}
          />
        ) : activeStep === 1 ? (
          <HousingInformation
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            hideSave={true}
          />
        ) : activeStep === 2 ? (
          <References
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            hideSave={true}
          />
        ) : activeStep === 3 ? (
          <EmploymentHistory
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            hideSave={true}
          />
        ) : activeStep === 4 ? (
          <Agreement
            handleNavigationForOffice={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            mainLoading={loading}
            hideSave={true}
          />
        ) : null}
      </div>
    );
  };

  return renderApplicationContent();
};

export default OnBoardTenantFromOffice;
