export const errors = {
  firstName: `FirstName is required`,
  lastName: "LastName is required",
  Mobile: `Enter a valid Mobile Number`,
  Email: `Enter a valid Email`,
};

export const spanishErrors = {
  firstName: `Se requiere el primer nombre`,
  lastName: "Apellido es obligatorio",
  Mobile: `Ingrese un número de móvil válido`,
  Email: `Introduzca un correo electrónico válido`,
}
