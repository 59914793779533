const list = [
    "Agriculture, Forestry, Fishing and Hunting",
    "Arts, Entertainment, and Recreation",
    "Broadcasting",
    "College, University, and Adult Education",
    "Computer and Electronics Manufacturing",
    "Construction",
    "Finance and Insurance",
    "Government and Public Administration",
    "Health Care and Social Assistance",
    "Homemaker",
    "Hotel and Food Services",
    "Information Services and Data Processing",
    "Legal Services",
    "Military",
    "Mining",
    "Other Education Industry",
    "Other Information Industry",
    "Other Manufacturing",
    "Primary/Secondary (K-12) Education",
    "Publishing",
    "Real Estate, Rental and Leasing",
    "Religious",
    "Retail",
    "Scientific or Technical Services",
    "Software",
    "Telecommunications",
    "Transportation and Warehousing",
    "Utilities",
    "Wholesale",
    "Other Industry"
]

export default list;