import * as _ from "lodash";

export const errors = {
  Model: "Model must have atleast 2 characters",
  brand: `Brand is required`,
  Color: `Color is required`,
  Year: `Enter a valid Year`,
  LicensePlate: `LicensePlate Number is required`,
  state: `State is required`,
};

export const spanishErrors = {
  Model: "El modelo debe tener al menos 2 caracteres.",
  brand: `Se requiere marca`,
  Color: `Se requiere color`,
  Year: `Ingrese un año válido`,
  LicensePlate: `Se requiere el número de placa de licencia`,
  state: `El estado es obligatorio`,
}

const selectOptions = [
  {
    year: 2020,
    make: "Acura",
    model: "ILX",
  },
  {
    year: 2020,
    make: "Acura",
    model: "MDX",
  },
  {
    year: 2020,
    make: "Acura",
    model: "MDX Sport Hybrid",
  },
  {
    year: 2020,
    make: "Acura",
    model: "NSX",
  },
  {
    year: 2020,
    make: "Acura",
    model: "RDX",
  },
  {
    year: 2020,
    make: "Acura",
    model: "RLX",
  },
  {
    year: 2020,
    make: "Acura",
    model: "RLX Sport Hybrid",
  },
  {
    year: 2020,
    make: "Acura",
    model: "TLX",
  },
  {
    year: 2020,
    make: "Alfa Romeo",
    model: "4C Spider",
  },
  {
    year: 2020,
    make: "Alfa Romeo",
    model: "Giulia",
  },
  {
    year: 2020,
    make: "Alfa Romeo",
    model: "Stelvio",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A3",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A4",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A5",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A6",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A6 allroad",
  },
  {
    year: 2020,
    make: "Audi",
    model: "A7",
  },
  {
    year: 2020,
    make: "Audi",
    model: "Q3",
  },
  {
    year: 2020,
    make: "Audi",
    model: "Q5",
  },
  {
    year: 2020,
    make: "Audi",
    model: "Q7",
  },
  {
    year: 2020,
    make: "Audi",
    model: "Q8",
  },
  {
    year: 2020,
    make: "Audi",
    model: "R8",
  },
  {
    year: 2020,
    make: "Audi",
    model: "S4",
  },
  {
    year: 2020,
    make: "Audi",
    model: "S8",
  },
  {
    year: 2020,
    make: "Audi",
    model: "SQ5",
  },
  {
    year: 2020,
    make: "Audi",
    model: "TT",
  },
  {
    year: 2020,
    make: "BMW",
    model: "2 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "3 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "4 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "5 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "7 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "8 Series",
  },
  {
    year: 2020,
    make: "BMW",
    model: "M2",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X1",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X2",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X3",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X3 M",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X4",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X5",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X6",
  },
  {
    year: 2020,
    make: "BMW",
    model: "X7",
  },
  {
    year: 2020,
    make: "BMW",
    model: "Z4",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Enclave",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Encore",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Encore GX",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Envision",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Regal Sportback",
  },
  {
    year: 2020,
    make: "Buick",
    model: "Regal TourX",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "CT4",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "CT5",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "CT6",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "CT6-V",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "Escalade",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "Escalade ESV",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "XT4",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "XT5",
  },
  {
    year: 2020,
    make: "Cadillac",
    model: "XT6",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Blazer",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Bolt EV",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Camaro",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Colorado Crew Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Colorado Extended Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Corvette",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Equinox",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Express 2500 Cargo",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Express 3500 Cargo",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Impala",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Malibu",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 1500 Crew Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 1500 Double Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 1500 Regular Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 2500 HD Crew Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 2500 HD Double Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Silverado 3500 HD Crew Cab",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Sonic",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Spark",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Suburban",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Tahoe",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Traverse",
  },
  {
    year: 2020,
    make: "Chevrolet",
    model: "Trax",
  },
  {
    year: 2020,
    make: "Chrysler",
    model: 300,
  },
  {
    year: 2020,
    make: "Chrysler",
    model: "Pacifica",
  },
  {
    year: 2020,
    make: "Chrysler",
    model: "Pacifica Hybrid",
  },
  {
    year: 2020,
    make: "Chrysler",
    model: "Voyager",
  },
  {
    year: 2020,
    make: "Dodge",
    model: "Challenger",
  },
  {
    year: 2020,
    make: "Dodge",
    model: "Charger",
  },
  {
    year: 2020,
    make: "Dodge",
    model: "Durango",
  },
  {
    year: 2020,
    make: "Dodge",
    model: "Grand Caravan Passenger",
  },
  {
    year: 2020,
    make: "Dodge",
    model: "Journey",
  },
  {
    year: 2020,
    make: "FIAT",
    model: "124 Spider",
  },
  {
    year: 2020,
    make: "FIAT",
    model: "500L",
  },
  {
    year: 2020,
    make: "FIAT",
    model: "500X",
  },
  {
    year: 2020,
    make: "Ford",
    model: "EcoSport",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Edge",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Escape",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Expedition",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Expedition MAX",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Explorer",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F150 Regular Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F150 Super Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F150 SuperCrew Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F250 Super Duty Crew Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F250 Super Duty Regular Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F250 Super Duty Super Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F350 Super Duty Crew Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F350 Super Duty Super Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "F450 Super Duty Crew Cab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Fusion",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Fusion Plug-in Hybrid",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Mustang",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Ranger SuperCab",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Ranger SuperCrew",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Transit 250 Cargo Van",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Transit 350 Passenger Van",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Transit Connect Cargo Van",
  },
  {
    year: 2020,
    make: "Ford",
    model: "Transit Connect Passenger Wagon",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 1500 Cargo",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 1500 Passenger",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 2500 Cargo",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 2500 Crew",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 2500 Passenger",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 3500 Cargo",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 3500 Crew",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 3500 XD Crew",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 3500XD Cargo",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 4500 Cargo",
  },
  {
    year: 2020,
    make: "Freightliner",
    model: "Sprinter 4500 Crew",
  },
  {
    year: 2020,
    make: "Genesis",
    model: "G70",
  },
  {
    year: 2020,
    make: "Genesis",
    model: "G80",
  },
  {
    year: 2020,
    make: "Genesis",
    model: "G90",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Acadia",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Canyon Crew Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Sierra 1500 Crew Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Sierra 1500 Double Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Sierra 1500 Regular Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Sierra 2500 HD Crew Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Sierra 3500 HD Crew Cab",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Terrain",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Yukon",
  },
  {
    year: 2020,
    make: "GMC",
    model: "Yukon XL",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Accord",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Accord Hybrid",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Civic",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Civic Type R",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Clarity Fuel Cell",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Clarity Plug-in Hybrid",
  },
  {
    year: 2020,
    make: "Honda",
    model: "CR-V",
  },
  {
    year: 2020,
    make: "Honda",
    model: "CR-V Hybrid",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Fit",
  },
  {
    year: 2020,
    make: "Honda",
    model: "HR-V",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Insight",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Odyssey",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Passport",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Pilot",
  },
  {
    year: 2020,
    make: "Honda",
    model: "Ridgeline",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Accent",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Elantra",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Elantra GT",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Ioniq Electric",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Ioniq Hybrid",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Ioniq Plug-in Hybrid",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Kona",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Kona Electric",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "NEXO",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Palisade",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Santa Fe",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Sonata",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Sonata Hybrid",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Tucson",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Veloster",
  },
  {
    year: 2020,
    make: "Hyundai",
    model: "Venue",
  },
  {
    year: 2020,
    make: "INFINITI",
    model: "Q50",
  },
  {
    year: 2020,
    make: "INFINITI",
    model: "Q60",
  },
  {
    year: 2020,
    make: "INFINITI",
    model: "QX50",
  },
  {
    year: 2020,
    make: "INFINITI",
    model: "QX60",
  },
  {
    year: 2020,
    make: "INFINITI",
    model: "QX80",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "E-PACE",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "F-PACE",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "F-TYPE",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "I-PACE",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "XE",
  },
  {
    year: 2020,
    make: "Jaguar",
    model: "XF",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Cherokee",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Compass",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Gladiator",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Grand Cherokee",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Renegade",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Wrangler",
  },
  {
    year: 2020,
    make: "Jeep",
    model: "Wrangler Unlimited",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Cadenza",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Forte",
  },
  {
    year: 2020,
    make: "Kia",
    model: "K900",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Niro",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Niro EV",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Niro Plug-in Hybrid",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Optima",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Optima Hybrid",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Optima Plug-in Hybrid",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Rio",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Sedona",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Sorento",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Soul",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Sportage",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Stinger",
  },
  {
    year: 2020,
    make: "Kia",
    model: "Telluride",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Defender 110",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Defender 90",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Discovery",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Discovery Sport",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Range Rover",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Range Rover Evoque",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Range Rover Sport",
  },
  {
    year: 2020,
    make: "Land Rover",
    model: "Range Rover Velar",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "ES",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "GS",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "GX",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "IS",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "LC",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "LS",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "LX",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "NX",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "RC",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "RX",
  },
  {
    year: 2020,
    make: "Lexus",
    model: "UX",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Aviator",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Continental",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Corsair",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "MKZ",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Nautilus",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Navigator",
  },
  {
    year: 2020,
    make: "Lincoln",
    model: "Navigator L",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "CX-3",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "CX-30",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "CX-5",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "CX-9",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "MAZDA3",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "MAZDA6",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "MX-5 Miata",
  },
  {
    year: 2020,
    make: "MAZDA",
    model: "MX-5 Miata RF",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "A-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "C-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "CLA",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "CLS",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "E-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "G-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "GLA",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "GLB",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "GLC",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "GLE",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "GLS",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Mercedes-AMG A-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Mercedes-AMG C-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Mercedes-AMG CLS",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Mercedes-AMG GLC",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Mercedes-AMG GLC Coupe",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Metris WORKER Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Metris WORKER Passenger",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "S-Class",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "SLC",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 1500 Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 1500 Passenger",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 2500 Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 2500 Crew",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 2500 Passenger",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 3500 Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 3500 Crew",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 3500 XD Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 3500 XD Crew",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 4500 Cargo",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    model: "Sprinter 4500 Crew",
  },
  {
    year: 2020,
    make: "MINI",
    model: "Clubman",
  },
  {
    year: 2020,
    make: "MINI",
    model: "Countryman",
  },
  {
    year: 2020,
    make: "MINI",
    model: "Hardtop 2 Door",
  },
  {
    year: 2020,
    make: "MINI",
    model: "Hardtop 4 Door",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Eclipse Cross",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Mirage",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Mirage G4",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Outlander",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Outlander PHEV",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    model: "Outlander Sport",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "370Z",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Altima",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Armada",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Frontier Crew Cab",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Frontier King Cab",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "GT-R",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Kicks",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "LEAF",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Maxima",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Murano",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "NV1500 Cargo",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "NV200",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "NV2500 HD Cargo",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "NV3500 HD Cargo",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "NV3500 HD Passenger",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Pathfinder",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Rogue",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Rogue Sport",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Sentra",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Titan Crew Cab",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Titan King Cab",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "TITAN XD Crew Cab",
  },
  {
    year: 2020,
    make: "Nissan",
    model: "Versa",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "718 Boxster",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "718 Cayman",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "718 Spyder",
  },
  {
    year: 2020,
    make: "Porsche",
    model: 911,
  },
  {
    year: 2020,
    make: "Porsche",
    model: "Cayenne",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "Cayenne Coupe",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "Macan",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "Panamera",
  },
  {
    year: 2020,
    make: "Porsche",
    model: "Taycan",
  },
  {
    year: 2020,
    make: "Ram",
    model: "1500 Classic Crew Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "1500 Classic Quad Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "1500 Crew Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "1500 Quad Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "2500 Crew Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "3500 Crew Cab",
  },
  {
    year: 2020,
    make: "Ram",
    model: "ProMaster Cargo Van",
  },
  {
    year: 2020,
    make: "Ram",
    model: "ProMaster City",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Ascent",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "BRZ",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Crosstrek",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Forester",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Impreza",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Legacy",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "Outback",
  },
  {
    year: 2020,
    make: "Subaru",
    model: "WRX",
  },
  {
    year: 2020,
    make: "Tesla",
    model: "Model 3",
  },
  {
    year: 2020,
    make: "Tesla",
    model: "Model S",
  },
  {
    year: 2020,
    make: "Tesla",
    model: "Model X",
  },
  {
    year: 2020,
    make: "Tesla",
    model: "Model Y",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "4Runner",
  },
  {
    year: 2020,
    make: "Toyota",
    model: 86,
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Avalon",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Avalon Hybrid",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Camry",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Camry Hybrid",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "C-HR",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Corolla",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Corolla Hatchback",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Corolla Hybrid",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "GR Supra",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Highlander",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Highlander Hybrid",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Land Cruiser",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Mirai",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Prius",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Prius Prime",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "RAV4",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "RAV4 Hybrid",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Sequoia",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Sienna",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Tacoma Access Cab",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Tacoma Double Cab",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Tundra CrewMax",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Tundra Double Cab",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Yaris",
  },
  {
    year: 2020,
    make: "Toyota",
    model: "Yaris Hatchback",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Arteon",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Atlas",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Atlas Cross Sport",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Golf",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Golf GTI",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Jetta",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Jetta GLI",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Passat",
  },
  {
    year: 2020,
    make: "Volkswagen",
    model: "Tiguan",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "S60",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "S90",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "V60",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "V90",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "XC40",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "XC60",
  },
  {
    year: 2020,
    make: "Volvo",
    model: "XC90",
  },
];

var arr = [{ value: "", label: "Select Make" }];
arr.push(..._.uniqBy(selectOptions, "make"));

export const Makes = _.uniqBy(selectOptions, "make")

var list=[{ value: "", label: "Select Model" }];
list.push(...selectOptions);
export const Models =selectOptions

export const states = [
  {
    State: "Alabama",
    Abbrev: "Ala.",
    Code: "AL",
  },
  {
    State: "Alaska",
    Abbrev: "Alaska",
    Code: "AK",
  },
  {
    State: "Arizona",
    Abbrev: "Ariz.",
    Code: "AZ",
  },
  {
    State: "Arkansas",
    Abbrev: "Ark.",
    Code: "AR",
  },
  {
    State: "California",
    Abbrev: "Calif.",
    Code: "CA",
  },
  {
    State: "Colorado",
    Abbrev: "Colo.",
    Code: "CO",
  },
  {
    State: "Connecticut",
    Abbrev: "Conn.",
    Code: "CT",
  },
  {
    State: "Delaware",
    Abbrev: "Del.",
    Code: "DE",
  },
  {
    State: "District of Columbia",
    Abbrev: "D.C.",
    Code: "DC",
  },
  {
    State: "Florida",
    Abbrev: "Fla.",
    Code: "FL",
  },
  {
    State: "Georgia",
    Abbrev: "Ga.",
    Code: "GA",
  },
  {
    State: "Hawaii",
    Abbrev: "Hawaii",
    Code: "HI",
  },
  {
    State: "Idaho",
    Abbrev: "Idaho",
    Code: "ID",
  },
  {
    State: "Illinois",
    Abbrev: "Ill.",
    Code: "IL",
  },
  {
    State: "Indiana",
    Abbrev: "Ind.",
    Code: "IN",
  },
  {
    State: "Iowa",
    Abbrev: "Iowa",
    Code: "IA",
  },
  {
    State: "Kansas",
    Abbrev: "Kans.",
    Code: "KS",
  },
  {
    State: "Kentucky",
    Abbrev: "Ky.",
    Code: "KY",
  },
  {
    State: "Louisiana",
    Abbrev: "La.",
    Code: "LA",
  },
  {
    State: "Maine",
    Abbrev: "Maine",
    Code: "ME",
  },
  {
    State: "Maryland",
    Abbrev: "Md.",
    Code: "MD",
  },
  {
    State: "Massachusetts",
    Abbrev: "Mass.",
    Code: "MA",
  },
  {
    State: "Michigan",
    Abbrev: "Mich.",
    Code: "MI",
  },
  {
    State: "Minnesota",
    Abbrev: "Minn.",
    Code: "MN",
  },
  {
    State: "Mississippi",
    Abbrev: "Miss.",
    Code: "MS",
  },
  {
    State: "Missouri",
    Abbrev: "Mo.",
    Code: "MO",
  },
  {
    State: "Montana",
    Abbrev: "Mont.",
    Code: "MT",
  },
  {
    State: "Nebraska",
    Abbrev: "Nebr.",
    Code: "NE",
  },
  {
    State: "Nevada",
    Abbrev: "Nev.",
    Code: "NV",
  },
  {
    State: "New Hampshire",
    Abbrev: "N.H.",
    Code: "NH",
  },
  {
    State: "New Jersey",
    Abbrev: "N.J.",
    Code: "NJ",
  },
  {
    State: "New Mexico",
    Abbrev: "N.M.",
    Code: "NM",
  },
  {
    State: "New York",
    Abbrev: "N.Y.",
    Code: "NY",
  },
  {
    State: "North Carolina",
    Abbrev: "N.C.",
    Code: "NC",
  },
  {
    State: "North Dakota",
    Abbrev: "N.D.",
    Code: "ND",
  },
  {
    State: "Ohio",
    Abbrev: "Ohio",
    Code: "OH",
  },
  {
    State: "Oklahoma",
    Abbrev: "Okla.",
    Code: "OK",
  },
  {
    State: "Oregon",
    Abbrev: "Ore.",
    Code: "OR",
  },
  {
    State: "Pennsylvania",
    Abbrev: "Pa.",
    Code: "PA",
  },
  {
    State: "Rhode Island",
    Abbrev: "R.I.",
    Code: "RI",
  },
  {
    State: "South Carolina",
    Abbrev: "S.C.",
    Code: "SC",
  },
  {
    State: "South Dakota",
    Abbrev: "S.D.",
    Code: "SD",
  },
  {
    State: "Tennessee",
    Abbrev: "Tenn.",
    Code: "TN",
  },
  {
    State: "Texas",
    Abbrev: "Tex.",
    Code: "TX",
  },
  {
    State: "Utah",
    Abbrev: "Utah",
    Code: "UT",
  },
  {
    State: "Vermont",
    Abbrev: "Vt.",
    Code: "VT",
  },
  {
    State: "Virginia",
    Abbrev: "Va.",
    Code: "VA",
  },
  {
    State: "Washington",
    Abbrev: "Wash.",
    Code: "WA",
  },
  {
    State: "West Virginia",
    Abbrev: "W.Va.",
    Code: "WV",
  },
  {
    State: "Wisconsin",
    Abbrev: "Wis.",
    Code: "WI",
  },
  {
    State: "Wyoming",
    Abbrev: "Wyo.",
    Code: "WY",
  },
];
