import React from "react";
import { Route, Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import NoticetoVacate from '../NoticetoVacate/index.js';
import { connect } from 'react-redux';
import { updateNoticeToVacateSuccess } from "../../redux/actions/user";
import { withTranslation } from 'react-i18next';

class SidebarNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState((state) => ({ active: this.state.active === i ? -1 : i, clicked: 1 }));
  }

  onSuccess = () => {
    this.props.toggleModal();
    this.props.updateNoticeToVacateSuccess(true);
  }

  render() {
    const { data } = this.props;
    var icon = data.icon && <i className={data.icon}></i>;
    var img = data.img && (
      <div className="icon-img">
        <img src={data.img} alt="" />
      </div>
    );
    var caret = data.children && !data.badge && <b className="caret"></b>;
    // var label = data.label && (
    //   <span className="label label-theme m-l-5">{data.label}</span>
    // );
    var badge = data.badge && (
      <span className="badge pull-right">{data.badge}</span>
    );
    var title = data.title && (
      <span>
        {data.title} {/* {label} */}
      </span>
    );
    var highlight = data.highlight && (
      <i className="fa fa-paper-plane text-theme"></i>
    );

    return (
      <>
      <Modal isOpen={this.props.modalToggle} toggle={() => this.props.toggleModal()}>
        <ModalHeader toggle={() => this.props.toggleModal()}>
          {/* File A Notice To Vacate */}
          {this.props.t && this.props.t('modal.notice.header')}
        </ModalHeader>
        <ModalBody>
          <NoticetoVacate onSuccess={this.onSuccess}/>
        </ModalBody>
      </Modal>
      <PageSettings.Consumer>
        {({
          handleSidebarOnMouseOver,
          handleSidebarOnMouseOut,
          pageSidebarMinified,
        }) => (
          <Route
            path={data.path}
            exact={data.exact}
            children={({ match }) => (
              <li
                onClick={(e) => {
                  this.props.handleSwitchLease && this.props.handleSwitchLease(e, data.zzId);
                  !data.children && this.props.handleClose();
                  this.props.toggleModal && this.props.toggleModal();
                }}
                className={
                  (data.zzId && data.zzId == this.props.activeLease ? "active " : "") +
                  (match ? "active " : "") +
                  (this.props.active ||
                  (this.props.clicked === -1 && match) ||
                  data.search ? "expand " : "closed ") +
                  (data.children ? "has-sub " : "")
                }
              >
                {data.children ? (
                  <Link
                    to={data.path}
                    onMouseOver={(e) => handleSidebarOnMouseOver(e, data)}
                    onMouseOut={(e) => handleSidebarOnMouseOut(e, data)}
                    onClick={this.props.expand}
                  >
                    {caret} {badge} {img} {icon} {title} {highlight}
                  </Link>
                ) : (
                  <Link to={data.path}  style={{color: 'black'}}>
                    {caret} {img} {icon} {badge} {title} {highlight}
                  </Link>
                )}
                {data.children && (
                  <ul
                    className={
                      "sub-menu " +
                      ((this.props.active || (this.props.clicked === -1 && match) || data.search) &&
                      !pageSidebarMinified ? "d-block " : "d-none")
                    }
                  >
                    {data.children?.map((submenu, i) => (
                      <SidebarNavList
                        data={submenu}
                        key={i}
                        activeLease={this.props.activeLease}
                        handleSwitchLease={this.props.handleSwitchLease}
                        handleClose={this.props.handleClose}
                        expand={(e) => this.handleExpand(e, i, match)}
                        active={i === this.state.active}
                        clicked={this.state.clicked}
                      />
                    ))}
                  </ul>
                )}
              </li>
            )}
          />
        )}
      </PageSettings.Consumer>
      </>
    );
  }
}

export default connect(null, { updateNoticeToVacateSuccess })(withTranslation()(SidebarNavList));
