import React, { useState, useEffect, useRef } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { Modal, ModalHeader, ModalBody, Alert, ModalFooter } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  getDashboardData,
  clearGlobalzzId,
  setGlobalzzId,
} from "../../redux/actions/user";
import { GetTicket } from "../../redux/actions/maintainance";
import { ToggleArchiveParking } from "../../redux/actions/parking";
import {
  ViewPayment,
  clearPaymentDetails,
  cancelRecurringPayment,
} from "../../redux/actions/payments";
import { ViewPeople, clearPeopleDetails } from "../../redux/actions/people";
import Maintenance from "../maintenance";
import PaymentForm from "../payments/paymentForm.jsx";
import RecurringForm from "../payments/recurringBillingForm";
import ParkingForm from "../parking/parkingForm";
import PeopleForm from "../people/peopleForm";
import CancelRecurringList from "../payments/cancelRecurringList";
import WrapperComponent from "../wrapperComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { Notify } from "../../helpers/notification";
import { useTranslation } from "react-i18next";
// import WeatherWidget from '../WeatherWidget';

const Dashboard = React.memo(
  ({
    history,
    getDashboardData,
    ViewPayment,
    ViewPeople,
    clearPaymentDetails,
    cancelRecurringPayment,
    clearPeopleDetails,
    metaData = {},
    user = {},
    paymentDetail,
    ToggleArchiveParking,
    globalZZID,
    clearGlobalzzId,
    setGlobalzzId,
    GetTicket,
    Leases,
    appLoading,
    isMaintenanceBlocked
  }) => {
    // const _6MonthsMiliseconds = 15778800000;
    const [displayData, setDisplayData] = useState({});
    const [modalDialog, setModalDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("");
    const [edit, setEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [modalBody, setModalBody] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [download, setDownload] = useState(false);
    const [selectedArchived, setSelectedArchived] = useState("");
    const [selectedPayment, setSelectedPayment] = useState("");
    const [action, setAction] = useState("");
    const [, setIsCompleted] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [openPaymentAlert, setOpenPaymentAlert] = useState(false);
    const [transactionResponse, setTransactionResponse] = useState("");
    const [cross, setCross] = useState(true);
    const [showNotAassiged, setShowNotAssigned] = useState(false);
    const [resfreshData, setRefreshData] = useState(false);
    
    const [modalError, setModalError] = useState('');
    const myRef = useRef();
    let query = new URLSearchParams(useLocation().search);
    let zzid = query.get("id");
    let urlAction = query.get("action");

    const { t } = useTranslation();

    const onSuccess = () => {
      setLoading(false);
    };

    useEffect(() => {
      if (metaData && !metaData.payments.length && Leases && Leases.length) {
        getDashboardData(onSuccess);
        setLoading(true);
      }
      zzid && setGlobalzzId({ zzid });
      urlAction &&
        setAction(
          urlAction === "verify" ? "Verified By Tenant" : "Re-Opened by Tenant"
        );
      if (globalZZID.action) {
        setAction(
          globalZZID.action === "verify"
            ? "Verified By Tenant"
            : "Re-Opened by Tenant"
        );
      }
      // eslint-disable-next-line
    }, [resfreshData]);

    // function to sort metaData object
    const sorter = (metaData) => {
      let obj = {};
      let b = moment().format("MM/DD/YYYY");
      let data = { ...metaData };
      data["maintainence_tickets"] = data["maintainence_tickets"].filter(
        (item) => {
          let a = moment(item["Lease_MAIN::Date_Max"], "MM/DD/YYYY");
          return (
            Math.abs(a.diff(b, "days")) < 22 ||
            [
              "new",
              "verified",
              "closed",
              "scheduled",
              "scheduled - confirmed",
              "re-opened by tenant",
            ].includes(item["Lease_MAIN::Status"]?.toLowerCase())
          );
        }
      );
      console.log(data);
      const myFun = (item) => {
        obj[item] = data[item].slice(0, 5);
      };
      data = Object.keys(data).forEach(myFun);
      data = obj;
      setDisplayData(data);
    };

    useEffect(() => {
      sorter(metaData);
    }, [metaData]);
    console.log(action);
    /********
      function which will run only for
      maintenance id present in url
    ********/
    const openMaintenanceTicket = (data) => {
      if (data) {
        if (data.fieldData["isVerifiedByTenant"] && action) {
          Notify("danger", "This ticket was already verified");
          history.push("/dashboard");
          return;
        } else if (data.fieldData["isReOpenedByTenant"] && action) {
          Notify("danger", "This ticket has already been re-opened");
          history.push("/dashboard");
          return;
        } else if (!data.fieldData["isClosed"] && action) {
          Notify("danger", "This ticket is still in progress");
          history.push("/dashboard");
          return;
        }
        setHeaderTitle("Maintenance Ticket");
        setEdit(true);
        toggleModal("maintenance");
      }
    };

    useEffect(() => {
      if (
        metaData?.maintainence_tickets?.length &&
        globalZZID &&
        globalZZID.zzid
      ) {
        let ele = [...metaData.maintainence_tickets];
        let item = ele.filter((e) => e["Lease_MAIN::zzID"] === globalZZID.zzid);
        clearGlobalzzId();
        if (item.length) {
          let b = moment().format("MM/DD/YYYY");
          let a = moment(item[0]["Lease_MAIN::Date_Max"], "MM/DD/YYYY");
          if (
            Math.abs(a.diff(b, "days")) > 21 &&
            ![
              "new",
              "verified",
              "closed",
              "scheduled",
              "scheduled - confirmed",
              "re-opened by tenant",
            ].includes(item[0]["Lease_MAIN::Status"].toLowerCase())
          ) {
            Notify("danger", "This ticket is too old");
            history.push("/dashboard");
            return;
          }
          GetTicket(
            {
             ...(item[0]?.mongo_id && {_id: item[0]?.mongo_id }), 
             ...(item[0]['Lease_MAIN::zzID'] && {zzID: item[0]['Lease_MAIN::zzID']})
            }, openMaintenanceTicket);
          setSelectedItem(item[0]);
        } else {
          Notify("danger", "No record found with Id provided");
          history.push("/dashboard");
        }
      }
      // eslint-disable-next-line
    }, [displayData]);
    /************ END OF FUNCTION ****************/

    // function to toggle modal
    const toggleModal = (value) => {
      if (value) {
        setModalBody(value);
        if (value !== "maintenance") {
          setCross(true);
        }
      }
      setModalDialog(!modalDialog);
    };

    
    // function which runs on modal close
    const closeModal = (bool) => {
      setEdit(false);
      setModalDialog(false);
      setSelectedItem({});
      setHeaderTitle("");
      setCross(true);
      modalBody === "payment" && clearPaymentDetails();
      modalBody === "people" && clearPeopleDetails();
      setModalBody("");
      if (zzid) {
        setAction("");
        history.push("/dashboard");
      }
    };

    const closeModalAdd = (bool) => {
      setEdit(false);
      setModalDialog(false);
      setSelectedItem({});
      setHeaderTitle("");
      setCross(true);
      modalBody === "payment" && clearPaymentDetails();
      modalBody === "people" && clearPeopleDetails();
      setModalBody("");
      if (zzid) {
        setAction("");
        history.push("/dashboard");
      }
      setShowNotAssigned(true);
    };

    // function to toggle parking
    const handleToggleParking = () => {
      let item = displayData.parking[selectedArchived];
      if (item) {
        let id = item["Lease_LVEH_LeaseVehicles::zzID"];
        let isArchived = item["Lease_LVEH_LeaseVehicles::isArchived"]
          ? ""
          : "1";
        ToggleArchiveParking(id, { isArchived }, closeModal);
        setOpenAlert(false);
      }
    };

    // function gets run on parking alert
    const openParkingAlert = (e, index) => {
      e.stopPropagation();
      index && setSelectedArchived(index);
      setOpenAlert(true);
    };

    useEffect(() => {
      if (paymentDetail.pdfData && download) {
        myRef.current.click();
      }
      // eslint-disable-next-line
    }, [paymentDetail]);

    // function to download payment pdf file
    const handlePdfDownload = (id) => {
      clearPaymentDetails();
      setSelectedPayment(id);
      ViewPayment(id, `email=${user.Email_01}`);
      setDownload(true);
    };

    // function to cancel recurring payment
    const handleCancelPayment = (item) => {
      cancelRecurringPayment(
        item.recordId,
        {
          subscriptionId: item["Lease_SUBC_active::subscriptionID"],
          customerProfileId: item["Lease_SUBC_active::customerProfileId"],
        },
        closeModal
      );
    };

    // function to toggle payment alert closing
    const togglePaymentAlert = () => {
      setOpenPaymentAlert(false);
    };

    const statusTranslation = (text) => {
      return text === "New"
        ? t("maintenance-status.new")
        : text === "Verified"
        ? t("maintenance-status.verified")
        : text === "Verified By Tenant"
        ? t("maintenance-status.verified-tenant")
        : text === "Closed"
        ? t("maintenance-status.closed")
        : text === "Scheduled"
        ? t("maintenance-status.scheduled")
        : text === "Re-Opened by Tenant"
        ? t("maintenance-status.re-opened")
        : text === "Scheduled - Confirmed"
        ? t("maintenance-status.scheduled-confirmed")
        : text === "Canceled"
        ? t("maintenance-status.canceled")
        : text;
    };

    const categoryTranslation = (text) => {
      return text === "Cleaning"
        ? t("maintenance-category.cleaning")
        : text === "Plumbing"
        ? t("maintenance-category.plumbing")
        : text === "Extermination"
        ? t("maintenance-category.extermination")
        : text === "Electricity"
        ? t("maintenance-category.electricity")
        : text === "Scheduled"
        ? t("maintenance-category.scheduled")
        : text === "Carpentry"
        ? t("maintenance-category.carpentry")
        : text === "Landscaping"
        ? t("maintenance-category.landscaping")
        : text;
    };

    // const shouldUpdateEmailOrPhone=()=>{
    //   if(!user?.web_verifiedEmailTs || !user?.web_verifiedPhoneTs){
    //     return true;
    //   }
    //   const emailTsDate = new Date(user?.web_verifiedEmailTs)?.getTime();
    //   const phoneTsDate = new Date(user?.web_verifiedPhoneTs)?.getTime();
    //   if(!emailTsDate || !phoneTsDate){
    //     return true;
    //   }
    //   const dateMiliseconds = new Date().getTime();
    //   if(Math.abs(dateMiliseconds-emailTsDate)>_6MonthsMiliseconds || Math.abs(dateMiliseconds-phoneTsDate)>_6MonthsMiliseconds){
    //    return true; 
    //   }
    //   return false
    // }


    const renderExterminationErrorModal = () => {
      return (
        <Modal size="md" centered isOpen={modalError} toggle={()=> setModalError('')}>
           <ModalBody>
              <p style={{fontSize: 16}}>{modalError}</p>
           </ModalBody>
           <ModalFooter>
            <button className="btn btn-primary" onClick={()=> setModalError('')}>Close</button>
           </ModalFooter>
        </Modal>
      )
    }
  

    return (
      <div>
        {renderExterminationErrorModal()}
        {openAlert && (
          <SweetAlert
            showCancel
            confirmBtnText={t("sweetalert.continue")}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={t("sweetalert.title1")}
            onConfirm={() => handleToggleParking()}
            onCancel={() => {
              setSelectedArchived("");
              setOpenAlert(false);
            }}
          />
        )}
        {showNotAassiged && (
          <SweetAlert
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={t("modal.parking.no-designated-spot")}
            onConfirm={() => setShowNotAssigned(false)}
            onCancel={() => setShowNotAssigned(false)}
          />
        )}
        
        {openPaymentAlert ? (
          isFailed ? (
            <SweetAlert
              error
              confirmBtnText={t("sweetalert.okay")}
              confirmBtnBsStyle="error"
              cancelBtnBsStyle="default"
              title={t("sweetalert.failed")}
              onConfirm={() => togglePaymentAlert()}
            >
              {transactionResponse}
            </SweetAlert>
          ) : (
            <SweetAlert
              success
              confirmBtnText={t("sweetalert.okay")}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="default"
              title={t("sweetalert.success")}
              onConfirm={() => togglePaymentAlert()}
            >
              {transactionResponse}
            </SweetAlert>
          )
        ) : (
          ""
        )}
        <div className="d-none">
          <a
            download={`Payment${selectedPayment}_LEASE${
              user.activeLease
            }_${moment().format("MM/DD/YYYY_hh:mm A")}`}
            href={"data:application/pdf;base64," + paymentDetail?.pdfData}
          >
            <i className="fa fa-download" ref={myRef} />
          </a>
        </div>

        <Modal isOpen={modalDialog} toggle={() => toggleModal()}>
          {modalBody !== "pdf" && (
            <ModalHeader
              toggle={() => closeModal()}
              close={
                <button
                  className={
                    (edit || modalBody !== "maintenance") && cross
                      ? "close"
                      : "close d-none"
                  }
                  onClick={(toggle) => closeModal()}
                >
                  x
                </button>
              }
            >
              {headerTitle || "Ania Management"}{" "}
              {(headerTitle === "Maintenance Ticket" &&
                selectedItem["Lease_MAIN::zzIDFriendly"]) ||
                ""}
            </ModalHeader>
          )}
          <ModalBody className={`${modalBody === "pdf" ? "p-0" : ""}`}>
            {modalBody === "maintenance" && (
              <Maintenance
                item={selectedItem}
                edit={edit}
                action={action}
                closeModal={closeModal}
                setHeaderTitle={setHeaderTitle}
                setCross={setCross}
                setRefreshData={setRefreshData}
                resfreshData={resfreshData}
                setModalError={setModalError}
              />
            )}
            {modalBody === "payment" && (
              <PaymentForm
                // item={selectedItem}
                // edit={edit}
                closeModal={closeModal}
                setIsCompleted={setIsCompleted}
                setIsFailed={setIsFailed}
                setOpenPaymentAlert={setOpenPaymentAlert}
                setTransactionResponse={setTransactionResponse}
                setRefreshData={setRefreshData}
                resfreshData={resfreshData}
              />
            )}
            {modalBody === "recurring_payment" && (
              <RecurringForm
                closeModal={closeModal}
                setIsFailed={setIsFailed}
                setOpenPaymentAlert={setOpenPaymentAlert}
                setTransactionResponse={setTransactionResponse}
                setRefreshData={setRefreshData}
                resfreshData={resfreshData}
              />
            )}
            {modalBody === "cancel_recurring_payment" && (
              <CancelRecurringList
                closeModal={closeModal}
                handleCancelPayment={handleCancelPayment}
              />
            )}
            {modalBody === "parking" && (
              <ParkingForm
                item={selectedItem}
                edit={edit}
                openParkingAlert={openParkingAlert}
                closeModal={closeModal}
                closeModalAdd={closeModalAdd}
                setRefreshData={setRefreshData}
                resfreshData={resfreshData}
              />
            )}
            {modalBody === "people" && (
              <PeopleForm
                edit={edit}
                closeModal={closeModal}
                setRefreshData={setRefreshData}
                resfreshData={resfreshData}
              />
            )}
          </ModalBody>
          
        </Modal>
        {user &&
        user.leaseDetails &&
        (!user.leaseDetails["Lease_PROP::Address_Address01_finalDisplay"] ||
          !user.leaseDetails["Lease_PROP::Address_City"] ||
          !user.leaseDetails["Lease_PROP::Address_State"] ||
          !user.leaseDetails["Lease_PROP::Address_PostalCode"]) ? (
          <div className="row mb-2">
            <div className="col-12">
              <Alert color="pink" className="m-b-10">
              {t("dashboard.property_info_alert")}
							</Alert>
            </div>
          </div>
        ) : null}

      {user &&
        user.leaseDetails && user?.leaseDetails?.EvictionStopBoolean ? (
          <div className="row mb-2">
            <div className="col-12">
              <Alert color="pink" className="m-b-10">
                  {t('dashboard.eviction_stop_alert')}
                 </Alert>
            </div>
          </div>
        ) : null}

        {user &&
        user.leaseDetails && user?.leaseDetails?.['Lease__LESE::zzGLOBAL37_YYYYMM'] && !user?.leaseDetails?.EvictionStopBoolean ? (
          <div className="row mb-2">
            <div className="col-12">
              {user.leaseDetails?.['Lease_LEDG_balanceThisMonthWeb::zs_amountTotal'] 
                 && user.leaseDetails?.['Lease_LEDG_balanceThisMonthWeb::zs_amountTotal']<0 
               ? <Alert color="pink" className="m-b-10">
                  {t("dashboard.balance_due_for")} {moment(user.leaseDetails?.['Lease__LESE::zzGLOBAL37_YYYYMM']).format("MMMM YYYY")} <strong style={{color:'red'}}>: -${parseFloat(''+Math.abs(user.leaseDetails?.['Lease_LEDG_balanceThisMonthWeb::zs_amountTotal'])).toFixed(2)}</strong>
                 </Alert>
                : null
               }
            </div>
          </div>
        ) : null}




        {/* {shouldUpdateEmailOrPhone() ? (
          <div className="row mb-2">
            <div className="col-12">
              <div class="alert alert-pink" role="alert">
                {t("dashboard.expired_email_phone_alert")}
              </div>
            </div>
          </div>
        ) : null} */}

        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="stats-title text-uppercase text-white font-weight-bold">
                      {t("card-title.payments")}
                    </div>
                    <div className="stats-desc">
                      {t("card-subtitle.payments")}
                    </div>
                  </div>

                  {appLoading ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : null}

                  {user &&
                    user.leaseDetails &&
                    user.leaseDetails[
                      "Lease_PROP::Address_Address01_finalDisplay"
                    ] &&
                    user.leaseDetails["Lease_PROP::Address_City"] &&
                    user.leaseDetails["Lease_PROP::Address_State"] &&
                    user.leaseDetails["Lease_PROP::Address_PostalCode"] &&
                    !user.leaseDetails.EvictionStopBoolean &&
                    Leases &&
                    Leases.length > 0 && (
                      <UncontrolledDropdown className="pull-right">
                        <DropdownToggle caret className="btn btn-warning">
                          {t("payment-card-button.make-payment")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <>
                            <DropdownItem
                              onClick={() => {
                                if (!loading) {
                                  toggleModal("payment");
                                  setHeaderTitle(t("modal.payment.header"));
                                  setEdit(false);
                                  setSelectedItem({});
                                }
                              }}
                            >
                              {t("payment-card-button.credit-card")}
                            </DropdownItem>
                            {/* <DropdownItem divider /> */}
                          </>
                          {/* <DropdownItem
                            onClick={() => {
                              if (!loading) {
                                setEdit(false);
                                setSelectedItem({});
                                let subscriptions =
                                  user?.leaseDetails?.subscription;
                                if (!subscriptions?.length) {
                                  toggleModal("recurring_payment");
                                  setHeaderTitle(
                                    t("modal.payment.header-recurr")
                                  );
                                } else {
                                  subscriptions?.length > 1
                                    ? (() => {
                                        toggleModal("cancel_recurring_payment");
                                        setHeaderTitle(
                                          t("modal.payment.header-recurrCancel")
                                        );
                                      })()
                                    : handleCancelPayment(subscriptions[0]);
                                }
                              }
                            }}
                          >
                            {!user?.leaseDetails?.subscription?.length
                              ? `${t("payment-card-button.auto-bill")}`
                              : `${t("payment-card-button.cancel")}`}
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                </div>
              </PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-nowrap">
                          {t("card-table.payment.date")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.payment.amount")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.payment.status")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.payment.receipt")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData.payments?.map((item, i) => (
                        <tr
                          key={i}
                          className={`text-nowrap ${
                            !item["Lease_PAYM_WebApp::isApproved"] &&
                            "text-muted font-italic"
                          }`}
                        >
                          <td>
                            {item["Lease_PAYM_WebApp::zzCreatedDate"] || "-"}
                          </td>
                          <td>
                            $
                            {item["Lease_PAYM_WebApp::zzc_Tenders_total"]
                              ? parseInt(
                                  item["Lease_PAYM_WebApp::zzc_Tenders_total"]
                                ).toFixed(2)
                              : "-"}
                          </td>
                          <td>
                            {item["Lease_PAYM_WebApp::isApproved"]
                              ? "Approved"
                              : "Pending" || "-"}
                          </td>
                          <td>
                            {item["Lease_PAYM_WebApp::isApproved"] === "1" ? (
                              <button
                                className={`btn btn-info btn-xs ${
                                  !item["Lease_PAYM_WebApp::isApproved"] &&
                                  "hover-not"
                                }`}
                                onClick={() => handlePdfDownload(item.recordId)}
                              >
                                <i className="fa fa-download" /> PDF
                              </button>
                            ) : (
                              (item["Lease_PAYM_WebApp::isApproved"] === "" || item["Lease_PAYM_WebApp::isApproved"] === "0")
                              && (item["Lease_PAYM_WebApp::isSettled"] === "" || item["Lease_PAYM_WebApp::isSettled"] === "0")
                              ? (<button
                                className={`btn btn-muted btn-xs ${
                                  !item["Lease_PAYM_WebApp::isApproved"] &&
                                  "hover-not"
                                }`}
                                onClick={() => {}}
                              >
                                Cancel
                              </button>)
                              : (item["Lease_PAYM_WebApp::isApproved"] === "" || item["Lease_PAYM_WebApp::isApproved"] === "0") && (item["Lease_PAYM_WebApp::isSettled"] === "1")
                              ? "-": "-"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
                {!loading && metaData.payments.length > 5 && (
                  <div className="row">
                    <span className="col-md-12 d-flex justify-content-end hover-pointer">
                      <b
                        className="text-warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push("/payments");
                        }}
                      >
                        {t("card-viewmore")}
                      </b>
                    </span>
                  </div>
                )}
              </PanelBody>
            </Panel>
          </div>
                    
            {
              isMaintenanceBlocked !=="1" ?  <div className="col-xl-6 col-md-6 col-sm-12">
              <Panel>
                <PanelHeader noButton={true}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="stats-title text-uppercase text-white font-weight-bold">
                        {t("card-title.maintenance")}
                      </div>
                      <div className="stats-desc">
                        {t("card-subtitle.maintenance")}
                      </div>
                    </div>
                    {Leases && Leases.length > 0 && (
                      <button
                        className="btn btn-warning btn-icon"
                        onClick={() => {
                          if (!loading) {
                            toggleModal("maintenance");
                            setHeaderTitle(
                              t("modal.maintenance.header-add-maintenance")
                            );
                            setEdit(false);
                            setSelectedItem({});
                          }
                        }}
                      >
                        <i
                          className={
                            !loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"
                          }
                        />
                      </button>
                    )}
                  </div>
                </PanelHeader>
                <PanelBody>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-nowrap">
                            {t("card-table.maintenance.ticketno")}
                          </th>
                          <th className="text-nowrap">
                            {t("card-table.maintenance.date")}
                          </th>
                          <th className="text-nowrap">
                            {t("card-table.maintenance.category")}
                          </th>
                          <th className="text-nowrap">
                            {t("card-table.maintenance.status")}
                          </th>
                          <th className="text-nowrap"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayData.maintainence_tickets?.map((item, i) => (
                          <tr
                            key={i}
                            className="hover-pointer"
                            onClick={(e) => {
                              setEdit(true);
                              setSelectedItem(item);
                              setHeaderTitle("Maintenance Ticket");
                              toggleModal("maintenance");
                            }}
                          >
                            <td className="text-capitalize">
                              {item["Lease_MAIN::zzIDFriendly"] || "-"}
                            </td>
                            <td>{item["Lease_MAIN::zzCreatedDate"] || "-"}</td>
                            <td className="text-capitalize">
                              {categoryTranslation(item["Lease_MAIN::Type"]) ||
                                "-"}
                            </td>
                            <td>
                              {statusTranslation(item["Lease_MAIN::Status"]) ||
                                "-"}
                            </td>
                            <td className="text-center">
                              <i className="fa fa-chevron-right" />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {!loading && metaData.maintainence_tickets.length > 5 && (
                    <div className="row">
                      <span className="col-md-12 d-flex justify-content-end hover-pointer">
                        <b
                          className="text-warning"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("/maintenance");
                          }}
                        >
                          {t("card-viewmore")}
                        </b>
                      </span>
                    </div>
                  )}
                </PanelBody>
              </Panel>
            </div> : null
            }
         

          <div className="col-xl-6 col-md-6 col-sm-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="stats-title text-uppercase text-white font-weight-bold">
                      {t("card-title.parking")}
                    </div>
                    <div className="stats-desc">
                      {t("card-subtitle.parking")}
                    </div>
                  </div>
                  {Leases && Leases.length > 0 && (
                    <button
                      className="btn btn-warning btn-icon"
                      onClick={(e) => {
                        if (!loading) {
                          toggleModal("parking");
                          setHeaderTitle(t("modal.parking.header-add-parking"));
                          setEdit(false);
                          setSelectedItem({});
                        }
                      }}
                    >
                      <i
                        className={
                          !loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"
                        }
                      />
                    </button>
                  )}
                </div>
              </PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-nowrap">
                          {t("card-table.parking.year")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.parking.make")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.parking.model")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.parking.spot")}
                        </th>
                        <th className="text-nowrap"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData.parking?.map(
                        (item, i) =>
                          !item["Lease_LVEH_LeaseVehicles::isArchived"] && (
                            <tr
                              key={i}
                              className={`text-nowrap hover-pointer`}
                              onClick={(e) => {
                                setSelectedArchived(i);
                                setEdit(true);
                                setSelectedItem(item);
                                setHeaderTitle(
                                  t("modal.parking.header-edit-parking")
                                );
                                toggleModal("parking");
                              }}
                            >
                              <td>
                                {item["Lease_LVEH_LeaseVehicles::Year"] || "-"}
                              </td>
                              <td>
                                {item["Lease_LVEH_LeaseVehicles::Make"] || "-"}
                              </td>
                              <td>
                                {item["Lease_LVEH_LeaseVehicles::Model"] || "-"}
                              </td>
                              <td>
                                {item["Lease_LVEH_LeaseVehicles::SpotNumber"] ||
                                  "-"}
                              </td>

                              <td className="text-center">
                                <i className="fa fa-chevron-right" />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                {/* {!loading && metaData.parking.length > 5 && (
                  <div className="row">
                    <span className="col-md-12 d-flex justify-content-end hover-pointer">
                      <b
                        className="text-warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push("/parking");
                        }}
                      >
                        {t("card-viewmore")}
                      </b>
                    </span>
                  </div>
                )} */}
              </PanelBody>
            </Panel>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12">
            <Panel>
              <PanelHeader noButton={true}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="stats-title text-uppercase text-white font-weight-bold">
                      {t("card-title.people")}
                    </div>
                    <div className="stats-desc">
                      {t("card-subtitle.people")}
                    </div>
                  </div>
                  {/* <button
                    className="btn btn-warning btn-icon "
                    onClick={(e) => {
                      if (!loading) {
                        toggleModal("people");
                        setHeaderTitle(t('modal.people.header-add-people'));
                        setEdit(false);
                        setSelectedItem({});
                      }
                    }}
                  >
                    <i
                      className={!loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"}
                    ></i>
                  </button> */}
                </div>
              </PanelHeader>
              <PanelBody>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <th className="text-nowrap">Status</th> */}
                        <th className="text-nowrap">
                          {t("card-table.people.name")}
                        </th>
                        <th className="text-nowrap">
                          {t("card-table.people.email")}
                        </th>
                        <th className="text-nowrap"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData.people?.map(
                        (item, i) =>
                          !item["Lease_LEPE_LeasePeople_all::isArchived"] && (
                            <tr
                              key={i}
                              className={`text-nowrap ${
                                item["Lease_PEPL_LeasePeople_all::zzID"] !==
                                user.zzID
                                  ? "hover-pointer"
                                  : "hover-not"
                              } 
                                        ${
                                          item[
                                            "Lease_LEPE_LeasePeople_all::isPending"
                                          ] && "text-muted font-italic"
                                        }`}
                              onClick={() => {
                                if (
                                  item["Lease_PEPL_LeasePeople_all::zzID"] !==
                                  user.zzID
                                ) {
                                  setEdit(true);
                                  setHeaderTitle(
                                    t("modal.people.header-edit-people")
                                  );
                                  toggleModal("people");
                                  ViewPeople(
                                    item["Lease_PEPL_LeasePeople_all::zzID"]
                                  );
                                }
                              }}
                            >
                              <td>
                                {item["Lease_PEPL_LeasePeople_all::NameFull"] ||
                                  "-"}
                                {item["Lease_PEPL_LeasePeople_all::zzID"] ===
                                  user?.zzID && <i className="fa fa-star" />}
                              </td>
                              <td>
                                {item["Lease_PEPL_LeasePeople_all::Email_01"] ||
                                  "-"}
                              </td>
                              <td className="text-center">
                                <i className="fa fa-chevron-right" />
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                {/* {!loading && metaData.people.length > 5 && (
                  <div className="row">
                    <span className="col-md-12 d-flex justify-content-end hover-pointer">
                      <b
                        className="text-warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push("/people");
                        }}
                      >
                        {t("card-viewmore")}
                      </b>
                    </span>
                  </div>
                )} */}
              </PanelBody>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({
  METADATA_REDUCER,
  USER_REDUCER,
  PAYMENT_REDUCER,
  MAINTENANCE_REDUCER,
  LOADING_REDUCER,
}) => {
  return {
    metaData: METADATA_REDUCER,
    user: USER_REDUCER.userData,
    paymentDetail: PAYMENT_REDUCER.paymentDetail,
    globalZZID: MAINTENANCE_REDUCER.globalZZID,
    Leases: USER_REDUCER.AllLeases,
    pendingPayments: PAYMENT_REDUCER.pendings_payments,
    appLoading: LOADING_REDUCER.appLoading,
    isMaintenanceBlocked: USER_REDUCER?.userData?.leaseDetails && USER_REDUCER.userData?.leaseDetails['Lease_PROP::isMaintenanceBlcked']
  };
};

const DashboardWrapper = (props) => (
  <WrapperComponent>
    <Dashboard {...props} />
  </WrapperComponent>
);

export default connect(mapStateToProps, {
  getDashboardData,
  ViewPayment,
  ViewPeople,
  clearPaymentDetails,
  cancelRecurringPayment,
  clearPeopleDetails,
  ToggleArchiveParking,
  clearGlobalzzId,
  setGlobalzzId,
  GetTicket,
})(withRouter(DashboardWrapper));
