import React, { useContext, useEffect, useState } from 'react';
import { PageSettings } from '../config/page-settings';
import logo from "../assets/img/logo/AniaLogo-full.svg";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const FullPageLayout = ({ children }) => {
    const { i18n } = useTranslation();
    const context = useContext(PageSettings);

    const [selectedLanguage, setSelectedLanguage] = useState(
      localStorage.getItem("i18nextLng") === "es" ? "Spanish" : "English"
    );
    const [dropDownOpen, setDropDownOpen] = useState(false);

    useEffect(() => {
        context.handleSetPageSidebar(false);
        context.handleSetPageHeader(false);
    }, []);

    const toggle = () => {
      setDropDownOpen(!dropDownOpen);
    };

    const renderLanguageMenu = () => {
      return (
        <Dropdown
          isOpen={dropDownOpen}
          toggle={toggle}
          className="dropdown navbar-languager"
          style={{cursor: 'pointer'}}
        >
          <DropdownToggle className="dropdown-toggle" tag="a">
            <span
              className={`flag-icon ${
                selectedLanguage === "Spanish" ? `flag-icon-es` : `flag-icon-us`
              } m-r-5`}
            ></span>
            <span className="name d-none d-sm-inline">{selectedLanguage}</span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right">
            <DropdownItem
              onClick={(e) => {
                setSelectedLanguage("English");
                i18n.changeLanguage("en");
              }}
            >
              <span className="flag-icon flag-icon-us m-r-5"></span> English
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                setSelectedLanguage("Spanish");
                i18n.changeLanguage("es");
              }}
            >
              <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      );
    };

    return <>
    <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-xl-3 col-12">
            <img src={logo} alt="" />
          </div>
          <div className="col-sm-2 col-xl-2 col-12 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
              {renderLanguageMenu()}
          </div>
        </div>
        <div
          className="row justify-content-center"
        >
                {children}
        </div>
      </div>
    </>
}

export default FullPageLayout;