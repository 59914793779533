/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useCallback } from "react";
import { PageSettings } from "../../config/page-settings";
import { Step, Stepper } from "react-form-stepper";
import PersonalInformation from "./PersonalInformation";
import HousingInformation from "./HousingInformation";
import References from "./References";
import EmploymentHistory from "./EmploymentHistory";
import Documents from "./Documents";
import logo from "../../assets/img/logo/AniaLogo-full.svg";
import Agreement from "./Agreement";
import { useDispatch, useSelector } from "react-redux";
import { getWebQueue, updateWebQueue } from "../../redux/actions/webqueue";
import { useParams } from "react-router-dom";
import { Notify } from "../../helpers/notification";
import { GetCarInfoAction } from "../../redux/actions/parking";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  SendOtpTwoFActorAuthAction,
  VerifyOtpTwoFActorAuthAction,
} from "../../redux/actions/twoFactorAuth";

const OnBoardTenant = () => {
  const { t, i18n } = useTranslation();
  const context = useContext(PageSettings);
  const dispatch = useDispatch();
  const { _id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(true);
  const [brokenLink, setBrokenLink] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") === "es" ? "Spanish" : "English"
  );
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(true);
  const [otpPhone, setOtpPhone] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [queue, setQueue] = useState(null);

  const { is_phone_verified_tenant_app, is_loading } = useSelector(
    (state) => state.TWO_FACTOR_AUTH_REDUCER
  );

  const styleConfig = {
    activeBgColor: "#007cba",
    completedBgColor: "#00acac",
  };

  const getQueue = useCallback(async () => {
    try {
      setLoading(true);
      setAppLoading(true);
      const result = await dispatch(getWebQueue(_id));
      if (result && result.details) {
        setForm({ ...result.details });
        setQueue({ ...result });
      } else {
        setBrokenLink(true);
      }
    } catch (error) {
      console.error(error);
      setBrokenLink(true);
    } finally {
      setLoading(false);
      setAppLoading(false);
    }
  }, []);

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
    getQueue();
    dispatch(GetCarInfoAction());
  }, []);

  useEffect(()=>{
    if(!is_phone_verified_tenant_app && form?.Phone){
      const phone = form?.Phone?.replace('(','')?.replace(')','')?.replace(' ','')?.replace('-','')
      sendOtp(phone);
      setOtpPhone(phone);
    }

  }, [is_phone_verified_tenant_app, form])

  const sendOtp = (phone) => {
    dispatch(
      SendOtpTwoFActorAuthAction({ phone }, (response) => {
        if (response?.data?.data?.status === "pending") {
          setShowVerifyOtp(true);
        }
      })
    );
  };

  const verifyOtp = () => {
    dispatch(
      VerifyOtpTwoFActorAuthAction(
        {
          phone: otpPhone
            .replaceAll("(", "")
            ?.replaceAll(")", "")
            ?.replaceAll("-", "")
            ?.replaceAll(" ", ""),
          code: otpCode,
        },
        (response) => {
          if (response?.data?.data?.status === "approved") {
            dispatch({
              type: "SET_IS_PHONE_VERIFIED_TENANT_APP",
              payload: true,
            });
          }
        }
      )
    );
  };

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleNavigation = async (direction, navigate = true) => {
    if (!is_phone_verified_tenant_app) {
      return;
    }
    if (direction === "previous") {
      setActiveStep(activeStep > 0 ? activeStep - 1 : 0);
    }

    if (direction === "next") {
      try {
        setLoading(true);
        let details = {
          ...form,
        };
        if (!form.Car) {
          details.Car = "No";
        }
        await dispatch(updateWebQueue(_id, { details }));
        if (!navigate) {
          Notify("success", "Details Saved!");
        } else {
          setActiveStep(activeStep < 5 ? activeStep + 1 : activeStep);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (direction === "documents") {
      setActiveStep(activeStep < 5 ? activeStep + 1 : activeStep);
    }
  };

  const renderLanguageMenu = () => {
    return (
      <Dropdown
        isOpen={dropDownOpen}
        toggle={toggle}
        className="dropdown navbar-languager"
      >
        <DropdownToggle className="dropdown-toggle" tag="a">
          <span
            className={`flag-icon ${
              selectedLanguage === "Spanish" ? `flag-icon-es` : `flag-icon-us`
            } m-r-5`}
          ></span>
          <span className="name d-none d-sm-inline">{selectedLanguage}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right">
          <DropdownItem
            onClick={(e) => {
              setSelectedLanguage("English");
              i18n.changeLanguage("en");
            }}
          >
            <span className="flag-icon flag-icon-us m-r-5"></span> English
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              setSelectedLanguage("Spanish");
              i18n.changeLanguage("es");
            }}
          >
            <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const renderBrokenLinkMessage = () => {
    return (
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-xl-3 col-12">
            <img src={logo} alt="" />
          </div>
          <div className="col-sm-2 col-xl-2 col-12 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
            {renderLanguageMenu()}
          </div>
        </div>
        <div
          className="row align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="col-md-6">
            <h4>{t("tenantApp.no_records_found")}</h4>
            <p>{t("tenantApp.this_could_happen_if")}</p>
            <ul>
              <li>{t("tenantApp.application_has_finalise")}</li>
              <li>{t("tenantApp.the_link_is_broken_or_expired")}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderApplicationContent = () => {
    return (
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-xl-3 col-12">
            <img src={logo} alt="" />
          </div>
          <div className="col-sm-8 col-xl-9 col-12">
            <div className="row align-items-center justify-content-end">
              <div className="col-sm-9 col-xl-10 col-8 pt-2 pt-sm-0 d-flex align-items-center justify-content-end text-right">
                <span className="font-weight-bold text-primary">
                  Agent: {queue?.USER_Name}
                </span>
              </div>
              <div className="col-sm-3 col-xl-2 col-4 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
                {renderLanguageMenu()}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <Stepper activeStep={activeStep}>
              <Step
                label={t("tenantApp.personal_info")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.housing_infor")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.references")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.employment")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.agreement")}
                styleConfig={styleConfig}
              />
              <Step
                label={t("tenantApp.documents")}
                styleConfig={styleConfig}
              />
            </Stepper>
          </div>
        </div>
        {activeStep === 0 ? (
          <PersonalInformation
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
            loading={loading}
          />
        ) : activeStep === 1 ? (
          <HousingInformation
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
          />
        ) : activeStep === 2 ? (
          <References
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
          />
        ) : activeStep === 3 ? (
          <EmploymentHistory
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
          />
        ) : activeStep === 4 ? (
          <Agreement
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
          />
        ) : activeStep === 5 ? (
          <Documents
            handleNavigation={handleNavigation}
            form={form}
            setForm={(e) => setForm(e)}
          />
        ) : null}
      </div>
    );
  };

  const renderPhoneVerifyModal = () => {
    return (
      <Modal
        isOpen={!is_phone_verified_tenant_app}
        centered
        size="md"
        backdropClassName="otp-modal-backdrop"
      >
        <ModalBody className="d-flex flex-column align-items-center bg-white">
          <div className="container mb-5">
            <div className="row justify-content-between align-items-center">
              <div className="col-6">
                <img src={logo} alt="" />
              </div>
              <div className="col-6 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
                {renderLanguageMenu()}
              </div>
            </div>
          </div>
          {!is_phone_verified_tenant_app && showVerifyOtp ? (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="font-weight-bold">
                    For security and verification Purposes, a four digit code
                    has been sent to the mobile number you supplied originally,
                    please type that code here to continue.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    value={otpCode}
                    onChange={(event) => setOtpCode(event.target.value)}
                  />
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-primary w-100 mt-2 mt-md-0"
                    disabled={is_loading}
                    onClick={() => {
                      if (otpCode) {
                        verifyOtp();
                      } else {
                        Notify("danger", "Enter code!");
                      }
                    }}
                  >
                    {is_loading ? "Please Wait" : "Verify"}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-link pl-0"
                    onClick={() => sendOtp(otpPhone)}
                  >
                    Resend Code
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="font-weight-bold">
                    Enter Phone number to receive code
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder={"Enter phone"}
                      value={otpPhone}
                      onChange={(e) => {
                        let x = e.target.value
                          .replace(/\D/g, "")
                          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        e.target.value = !x[2]
                          ? x[1]
                          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                        setOtpPhone(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if (
                        otpPhone &&
                        otpPhone
                          .replaceAll("(", "")
                          ?.replaceAll(")", "")
                          ?.replaceAll("-", "")
                          ?.replaceAll(" ", "")?.length == 10
                      ) {
                        sendOtp(
                          otpPhone
                            .replaceAll("(", "")
                            ?.replaceAll(")", "")
                            ?.replaceAll("-", "")
                            ?.replaceAll(" ", "")
                        );
                      } else {
                        Notify("warning", "Enter valid phone!");
                      }
                    }}
                    disabled={is_loading}
                  >
                    {is_loading ? "sending" : "Send Code"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  };

  return appLoading ? (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <span className="spinner-grow text-primary"></span>
      </div>
    </div>
  ) : brokenLink ? (
    renderBrokenLinkMessage()
  ) : (
    <>
      {renderPhoneVerifyModal()}
      {renderApplicationContent()}
    </>
  );
};

export default OnBoardTenant;
