import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { PageSettings } from "./../../config/page-settings";
import { connect } from "react-redux";
import NVD3Chart from "react-nvd3";
import {
  LogoutStaffAction,
  GetOfficeDashData,
} from "../../redux/actions/officeDash";
import moment from "moment";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DonughtChartComponent = ({ title, datum, options }) => {
  return (
    <Panel>
      <PanelHeader noButton={true}>{title}</PanelHeader>
      <PanelBody>
        <NVD3Chart
          datum={datum}
          type="pieChart"
          id="donutChart"
          height="450"
          width="100%"
          options={options}
          x="label"
          y="value"
        />
      </PanelBody>
    </Panel>
  )
}
const _filterData = (data, key) => {
  let object = [];
  let undefinedSum = 0;
  let counter = 0;
  data.forEach((item) => {
    let myData = {};
    counter = counter + 1;
    if (item?.label && counter < 10) {
      let firstName = item.label.split(" ");
      myData["label"] = firstName[0] + " (" + item.count + ")";
      myData["value"] = item && item.count;
      myData["color"] = colors[key][counter];
      object.push(myData);
    } else {
      undefinedSum = undefinedSum + item?.count;
    }
  });
  object.push({ label: "Others", value: undefinedSum, color: "#b2bec3" });
  return object;
};

const colors = {
  closed: {
    0: "#FAEBEB",
    1: "#FF856F",
    2: "#F5D0C4",
    3: "#B8B85A",
    4: "#DBC8AC",
    5: "#FFCDCD",
    6: "#FFFF41",
    7: "#F3A079",
    8: "#D1FF36",
    9: "#FFC264",
  },
  verified: {
    0: "#C5FF6C",
    1: "#A4C9C9",
    2: "#ECEC57",
    3: "#4DFFC3",
    4: "#D1FF36",
    5: "#FAEBEB",
    6: "#99B2FF",
    7: "#0BF2ED",
    8: "#FFFF41",
    9: "#E599E5",
  },
  mainManager: {
    0: "#4DFFC3",
    1: "#0BF2ED",
    2: "#FFFF41",
    3: "#E599E5",
    4: "#C5FF6C",
    5: "#A4C9C9",
    6: "#D1FF36",
    7: "#FAEBEB",
    8: "#99B2FF",
    9: "#C2E9FF",
  },
};

function OfficeDash({
  LogoutStaffAction,
  GetOfficeDashData,
  month,
  lastWeek,
  weekToDate,
  sevenDays,
  today,
  survey,
}) {
  const contextType = useContext(PageSettings);
  const [activeChart, setActiveChart] = useState(1);
  const [activeView, setActiveView] = useState({});
  const [time, setTime] = useState(0);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [text, setText] = useState("Today");

  const view = {
    1: today,
    2: weekToDate,
    3: sevenDays,
    4: lastWeek,
    5: month,
    6: survey,
  };

  const viewText = {
    1: "Today",
    2: "Week To Date",
    3: "Seven Days",
    4: "Last Week",
    5: "30 Days",
    6: "Survey",
  };

  useEffect(() => {
    contextType.handleSetPageSidebar(false);
    contextType.handleSetPageHeader(false);
    contextType.handleSetPageContentFullWidth(true);
    setActiveView(view[1]);
    return () => {
      contextType.handleSetPageSidebar(true);
      contextType.handleSetPageHeader(true);
      contextType.handleSetPageContentFullWidth(false);
    };
    // eslint-disable-next-line
  }, []);

  const donutChart_data = (list, key) => ({
    data: list?.length !== 0
      ? _filterData(activeView.closedByList || [], key)
      : [{ label: "NO DATA FOUND", value: 1, color: "#7f8c8d" }],
    options: {
      donut: true,
      growOnHover: false,
      donutRatio: 0.3,
    },
  });

  useEffect(() => {
    if (time === 900) {
      GetOfficeDashData(1);
      GetOfficeDashData(2);
      GetOfficeDashData(3);
      GetOfficeDashData(4);
      GetOfficeDashData(5);
      GetOfficeDashData(6);
      setTime(0);
    }

  });

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveView(view[(activeChart % 6) + 1]);
      setText(viewText[(activeChart % 6) + 1]);

      if (activeChart === 6) {
        setActiveChart(1);
      } else {
        setActiveChart((prev) => ++prev);
      }
      !Object.keys(view[activeChart]).length && GetOfficeDashData(activeChart);
      setTime((t) => t + 30);
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [activeChart]);

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const changeViewText = (n) => {
    setText(viewText[n]);
    setActiveChart(n);
    setActiveView(view[n]);
  };

  return (
    <div>
      <div
        id="header"
        className="header navbar-default w-full d-flex align-items-center"
      >
        <div className="navbar-header">
          <Link to="/officedash" className="navbar-brand">
            {" "}
            <b>Ania</b> Management
          </Link>
        </div>
        <ul className="navbar-nav navbar-right pr-3">
          <li
            className="breadcrumb-item active"
            onClick={() => {
              LogoutStaffAction();
            }}
          >
            <i className="fas fa-power-off fa-2x" />
          </li>
        </ul>
      </div>
      <div className="container-fluid">
        <h1 className="page-header d-flex align-items-end">
          <Dropdown isOpen={dropDownOpen} toggle={toggle} className="dropdown">
            <DropdownToggle tag="a">
              <button className="btn btn-purple d-none d-sm-inline">
                <i className="fa fa-calendar-alt"></i> {text}
              </button>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu dropdown-menu-right"
              tag="ul"
            >
              {['Today', 'Week to Date', 'Seven Days', 'Last Week', '30 days', 'Survey'].map((item, i) => (
                <DropdownItem key={i} onClick={() => changeViewText(i + 1)}>
                  {item}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          &nbsp;&nbsp;
          <div>
            {activeChart === 1 ? (
              <>
                Today{" "}
                <small className="text-black-darker f-w-600 f-s-17">
                  ({moment().utcOffset(-300).format("MM/DD")})
                </small>
              </>
            ) : activeChart === 2 ? (
              <>
                Week to Date{" "}
                <small className="text-black-darker f-w-600 f-s-17">
                  ({moment().utcOffset(-300).startOf("week").format("MM/DD")} -{" "}
                  {moment().utcOffset(-300).format("MM/DD")})
                </small>
              </>
            ) : activeChart === 3 ? (
              <>
                Seven Days{" "}
                <small className="text-black-darker f-w-600 f-s-17">
                  (
                  {moment().utcOffset(-300).subtract(7, "days").format("MM/DD")}{" "}
                  - {moment().utcOffset(-300).format("MM/DD")})
                </small>
              </>
            ) : activeChart === 4 ? (
              <>
                Last Week{" "}
                <small className="text-black-darker f-w-600 f-s-17">
                  (
                  {moment().utcOffset(-300).startOf("week").subtract(7, "days").format("MM/DD")}{" "}
                  - {moment().utcOffset(-300).startOf("week").format("MM/DD")})
                </small>
              </>
            ) : activeChart === 5 ? (
              <>
                30 Days{" "}
                <small className="text-black-darker f-w-600 f-s-17">
                  (
                  {moment().utcOffset(-300).subtract(30, "days").format("MM/DD")}{" "}
                  - {moment().utcOffset(-300).format("MM/DD")})
                </small>
              </>
            ) : (
              <>Five Star Surveys</>
            )}
          </div>
        </h1>

        {activeChart === 6 ? (
          <div className="row">
            <div className="col-xl-6 col-md-12 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-info">

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-nowrap f-s-15">#</th>
                          <th className="text-nowrap f-s-15">Name</th>
                          <th className="text-nowrap f-s-15">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {survey && Object.keys(survey).map((item, i) => {
                          return item !== "questionsAvg" ?
                            (
                              <tr key={i}>
                                <td className="text-nowrap f-s-15">
                                  {i + 1}.
                            </td>
                                <td className="text-nowrap f-s-15">
                                  {item}
                                </td>
                                <td className="text-nowrap f-s-15">
                                  {survey[item]}
                                </td>
                              </tr>
                            ) : null
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12 col-sm-12">
              <div className="widget widget-stats">
                <div className="stats-info">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-nowrap f-s-15">#</th>
                          <th className="text-nowrap f-s-15">Question</th>
                          <th className="text-nowrap f-s-15">Avg</th>
                        </tr>
                      </thead>
                      <tbody>
                        {survey && survey.questionsAvg && survey.questionsAvg.map((item, i) => (
                          <tr key={i}>
                            <td className="text-nowrap f-s-15">
                              {i + 1}.
                            </td>
                            <td className="text-nowrap f-s-15">
                              {item.que}
                            </td>
                            <td className="text-nowrap f-s-15">
                              {parseFloat(item.avg).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-red">
                  <div className="stats-icon">
                    <i className="fa fa-ticket-alt"></i>
                  </div>
                  <div className="stats-info">
                    <h4>NEW MAINTENANCE TICKETS OPENED</h4>
                    <p>{activeView.noticketsCreated || 0}</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-orange">
                  <div className="stats-icon">
                    <i className="fa fa-clock"></i>
                  </div>
                  <div className="stats-info">
                    <h4>AVERAGE TURN AROUND TIME(TAT)</h4>
                    <p>{activeView.averageTAT || 0} Days</p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-green">
                  <div className="stats-icon">
                    <i className="fa fa-dollar-sign"></i>
                  </div>
                  <div className="stats-info">
                    <div className="row">
                      <div className="col-5">
                        <h4>PAYMENTS</h4>
                        <p>{activeView.totalPayments || 0}</p>
                      </div>
                      <div className="col-5">
                        <h4>WEB PAYMENTS</h4>
                        <p>{activeView.webPayments || 0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-md-6">
                <div className="widget widget-stats bg-black-lighter">
                  <div className="stats-icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <div className="stats-info">
                    <h4>RENTAL APPLICATIONS PLACED</h4>
                    <p>{activeView.applicationsPlaced || 0}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {[{
                title: `Closed (${activeView.noticketsClosed || 0})`,
                key: "closed",
                listKey: "closedByList"
              },
              {
                title: `Verified (${activeView.noticketsVerified || 0})`,
                key: "verified",
                listKey: "verifiedByList"
              },
              {
                title: `Maintenance Manager (Closed)`,
                key: "mainManager",
                listKey: "mainManager"
              }
              ].map((item, i) => (
                <div key={i} className="col-xl-4 col-md-4 col-sm-12">
                  <DonughtChartComponent
                    title={item.title}
                    datum={donutChart_data(activeView?.[item.listKey] || [], item.key).data}
                    options={donutChart_data(activeView?.[item.listKey] || [], item.key).options}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = ({ OFFICEDASH_REDUCER }) => {
  return {
    month: OFFICEDASH_REDUCER.month,
    lastWeek: OFFICEDASH_REDUCER.lastWeek,
    weekToDate: OFFICEDASH_REDUCER.weekToDate,
    sevenDays: OFFICEDASH_REDUCER.sevenDays,
    today: OFFICEDASH_REDUCER.today,
    survey: OFFICEDASH_REDUCER.survey,
  };
};

export default connect(mapStateToProps, {
  GetOfficeDashData,
  LogoutStaffAction,
})(withRouter(OfficeDash));
