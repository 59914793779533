import React from "react";

function errorPage({errorMessage}) {
  return (
    <div className="error">
      <div className="error-code m-b-10">:(</div>
      <div className="error-content">
        <div className="error-message">Website Down</div>
        <div className="error-desc m-b-30">
          Our server is down for maintenance at the moment. Please try again
          later.
        </div>
      </div>
    </div>
  );
}

export default errorPage;