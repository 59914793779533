const initialState = {
  error: {},
  loading: false,
};

const NOTICE_VACATE_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "NOTICE_VACATE_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "NOTICE_VACATE_LOADING_START": {
      return {
        ...state,
        loading: true,
      };
    }
    case "NOTICE_VACATE_LOADING_STOP": {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default NOTICE_VACATE_REDUCER;
