import Api from "../api/api";
import { Notify } from "../../helpers/notification";
import { getDashboardData } from "./user";

export const PEOPLE_UPDATED = 'PEOPLE_UPDATED';

const PEOPLE_LOADING = () => {
  return { type: "PEOPLE_LOADING" };
};

const PEOPLE_COMPLETE = () => {
  return { type: "PEOPLE_COMPLETE" };
};

export const AddPeople = (payload, cb) => (dispatch) => {
  dispatch(PEOPLE_LOADING());
  Api.post("api/people/addPeople", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      dispatch(getDashboardData());
      dispatch(PEOPLE_COMPLETE());
      cb && cb(); // callback to close modal
      Notify("success", response.data.message);
    })
    .catch((err) => {
      dispatch(PEOPLE_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const ViewPeople = (id) => (dispatch) => {
  dispatch(PEOPLE_LOADING());
  Api.get(`api/people/viewPeople/${id}`)
    .then((response) => {
      dispatch(PEOPLE_COMPLETE());
      dispatch({ type: "VIEW_PEOPLE", payload: response.data.data });
    })
    .catch((err) => {
      dispatch(PEOPLE_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const EditPeople = (id, payload, cb) => (dispatch) => {
  dispatch(PEOPLE_LOADING());
  Api.post(`api/people/editPeople/${id}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      dispatch({ type: PEOPLE_UPDATED })
    })
    .catch((err) => {
      dispatch(PEOPLE_COMPLETE());
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const clearPeopleDetails = () => (dispatch) => {
  dispatch({ type: "CLEAR_PEOPLEDETAILS" });
};
