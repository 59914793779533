export const subCategoryList = {
  plumbing: ["Leaks", "Air conditioning", "Heat"],
  electricity: ["Lights", "Sockets"],
  carpentry: ["Doors", "Walls", "Ceiling"],
  cleaning: ["Entryway", "Hallway", "Trash area"],
  landscaping: ["Front yard", "Side yard", "Back yard"],
  extermination: ["Rats/Mice", "Cockroaches", "Ants"],
};

export const timeSlotsMF = [
  "8:00 AM - 10:00 AM",
  "10:00 AM - 12:00 PM",
  "1:00 PM - 3:00 PM",
  "3:00 PM - 5:00 PM",
];

export const timeSlotsS = [
  "8:00 AM - 10:00 AM",
  "10:00 AM - 12:00 PM",
  "1:00 PM - 2:00 PM",
];