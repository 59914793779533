import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Validation } from "../../helpers/validations";
import { timeSlotsMF, timeSlotsS } from "./constant";
import { useTranslation } from "react-i18next";

const MaintenanceSchedule = ({
  closeModal,
  date,
  setDate,
  timeSlot,
  setTimeSlot,
  loading,
  error,
  setError,
  handleUpdateTicket,
  edit,
}) => {
  const [day, setDay] = useState({
    day1: moment(date.date1).format("dddd"),
    day2: moment(date.date2).format("dddd"),
    day3: moment(date.date3).format("dddd"),
  });

  const [duplicate,  setDuplicate] = useState(false);

  const { t } = useTranslation();

  const handleChangeDateDay = (dateKey, dayKey, e) => {
    setDate({ ...date, [dateKey]: e });
    setDay({ ...day, [dayKey]: moment(e).format("dddd") });
  };

  const handleTimeslotChange = (key, value) => {
    setTimeSlot({ ...timeSlot, [key]: value });
  };

  // function to filter out Sundays as non-selectable
  const isWeekday = (date) => {
    const days = date.getDay();
    return days !== 0;
  };

  // validator function for 3 maintenance schedules
  const handleValidation = ({
    date1,
    date2,
    date3,
    tSlot1,
    tSlot2,
    tSlot3,
  }) => {
    if (Validation.empty(date1 ? date1.toString() : "")) {
      setError({ ...error, date1: t('modal.maintenance.validation.date') });
      return false;
    } else if (Validation.empty(date2 ? date2.toString() : "")) {
      setError({ ...error, date2: t('modal.maintenance.validation.date') });
      return false;
    } else if (Validation.empty(date3 ? date3.toString() : "")) {
      setError({ ...error, date3: t('modal.maintenance.validation.date') });
      return false;
    } else if (Validation.empty(tSlot1)) {
      setError({ ...error, tSlot1: t('modal.maintenance.validation.slot') });
      return false;
    } else if (Validation.empty(tSlot2)) {
      setError({ ...error, tSlot2: t('modal.maintenance.validation.slot') });
      return false;
    } else if (Validation.empty(tSlot3)) {
      setError({ ...error, tSlot3: t('modal.maintenance.validation.slot') });
      return false;
    }
    return true;
  };

  // function to validate and update maintenance schedules
  const handleUpdate = () => {
    let { date1, date2, date3 } = date;
    let { tSlot1, tSlot2, tSlot3 } = timeSlot;

    if (date1.toString() == date2.toString() && tSlot1 == tSlot2 ||
    date2.toString() == date3.toString() && tSlot2 == tSlot3 ||
    date1.toString() == date3.toString() && tSlot1 == tSlot3 ) {
      setDuplicate(true)
      return
    }

    setDuplicate(false)
    if (handleValidation({ date1, date2, date3, tSlot1, tSlot2, tSlot3 })) {
      handleUpdateTicket();
    }
  };

  // function to disable timeslot dropdown options for date 1
  const disableTimeSlot = (slot, d) => {
    if (date[d].getDate() === new Date().getDate()) {
      let a = date[d]
        .toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(/([\d]+:[\d]{2})(:[\d]{2})(. *)/, "$1$3");
      a = a.replace(":", "");

      let sub = slot.substr(0, slot.indexOf(" -"));
      sub = moment(sub, ["h:mm A"]).format("HH:mm");
      sub = sub.replace(":", "");

      if (parseInt(sub) < parseInt(a)) return true;
      else return false;
    }
  };

  return (
    <div className="maintenance-container">
      <div className="row m-1">
        <div className="note note-warning note-with-right-icon p-5">
          <div className="note-icon">
            <i className="fa fa-lightbulb"></i>
          </div>
          <div className="note-content text-right">
            <h5>
              <b>
                {t('modal.maintenance.info-text2')}
              </b>
            </h5>
          </div>
        </div>
        
        {duplicate &&  <div className="row m-1">
        <div className="note note-danger note-with-right-icon p-2">
          <div className="note-icon">
            <i className="fa fa-times"></i>
          </div>
          <div className="note-content text-right">
            <h5>
              <b>
              {t('modal.maintenance.validation.duplicate')}
              </b>
            </h5>
          </div>
        </div>
        </div>}

        <label className="col-md-12 col-form-label">
        {t('modal.maintenance.option')} 1<small className="text-danger"> *</small>
        </label>
        <div className="col-md-6">
          <div className="form-group">
            <DatePicker
              // isClearable={true}
              minDate={new Date()}
              // maxDate={addDays(date.date1, 20)}
              selected={date.date1}
              onChange={(e) => handleChangeDateDay("date1", "day1", e)}
              className="form-control"
              placeholderText="Pick a date"
              filterDate={isWeekday}
              onFocus={(e) => setError({ ...error, date1: "" })}
              // showDisabledMonthNavigation
              // highlightDates={addDays(date.date1, 7)}
            />
            <p className="col-md-12 text-danger">{error.date1}</p>
          </div>
        </div>
        <div className="col-md-6">
          <select
            className={`form-control ${error.tSlot1 && "border border-danger"}`}
            value={timeSlot.tSlot1}
            onChange={(e) => handleTimeslotChange("tSlot1", e.target.value)}
            onFocus={(e) => setError({ ...error, tSlot1: "" })}
          >
            <option value="">Select Time Slot</option>
            {day.day1 === "Saturday"
              ? timeSlotsS.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date1")}>
                    {slot}
                  </option>
                ))
              : timeSlotsMF.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date1")}>
                    {slot}
                  </option>
                ))}
          </select>
          <p className="col-md-12 text-danger">{error.tSlot1}</p>
        </div>
      </div>

      <div className="row m-1">
        <label className="col-md-12 col-form-label">
        {t('modal.maintenance.option')} 2<small className="text-danger"> *</small>
        </label>
        <div className="col-md-6">
          <div className="form-group">
            <DatePicker
              // isClearable={true}
              minDate={new Date()}
              // maxDate={addDays(date.date2, 20)}
              selected={date.date2}
              onChange={(e) => handleChangeDateDay("date2", "day2", e)}
              placeholderText="Pick a date"
              className="form-control"
              filterDate={isWeekday}
              onFocus={(e) => setError({ ...error, date2: "" })}
            />
            <p className="col-md-12 text-danger">{error.date2}</p>
          </div>
        </div>
        <div className="col-md-6">
          <select
            className={`form-control ${error.tSlot2 && "border border-danger"}`}
            value={timeSlot.tSlot2}
            onChange={(e) => handleTimeslotChange("tSlot2", e.target.value)}
            onFocus={(e) => setError({ ...error, tSlot2: "" })}
          >
            <option value="">Select Time Slot</option>
            {day.day2 === "Saturday"
              ? timeSlotsS.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date2")}>
                    {slot}
                  </option>
                ))
              : timeSlotsMF.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date2")}>
                    {slot}
                  </option>
                ))}
          </select>
          <p className="col-md-12 text-danger">{error.tSlot2}</p>
        </div>
      </div>

      <div className="row m-1">
        <label className="col-md-12 col-form-label">
        {t('modal.maintenance.option')} 3<small className="text-danger"> *</small>
        </label>
        <div className="col-md-6">
          <div className="form-group">
            <DatePicker
              // isClearable={true}
              minDate={new Date()}
              // maxDate={addDays(date.date3, 20)}
              selected={date.date3}
              onChange={(e) => handleChangeDateDay("date3", "day3", e)}
              placeholderText="Pick a date"
              className="form-control"
              filterDate={isWeekday}
              onFocus={(e) => setError({ ...error, date3: "" })}
            />
            <p className="col-md-12 text-danger">{error.date3}</p>
          </div>
        </div>
        <div className="col-md-6">
          <select
            className={`form-control ${error.tSlot3 && "border border-danger"}`}
            value={timeSlot.tSlot3}
            onChange={(e) => handleTimeslotChange("tSlot3", e.target.value)}
            onFocus={(e) => setError({ ...error, tSlot3: "" })}
          >
            <option value="">Select Time Slot</option>
            {day.day3 === "Saturday"
              ? timeSlotsS.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date3")}>
                    {slot}
                  </option>
                ))
              : timeSlotsMF.map((slot, i) => (
                  <option key={i} value={slot} disabled={disableTimeSlot(slot, "date3")}>
                    {slot}
                  </option>
                ))}
          </select>
          <p className="col-md-12 text-danger">{error.tSlot3}</p>
        </div>
      </div>

      <div className="row">
        {/* {edit ? (
          ""
        ) : (
          <p className="text-left m-b-0 col-sm-2">
            <button className="btn btn-secondary" onClick={handleSchedule}>
              Back
            </button>
          </p>
        )} */}

        <p className="text-right m-b-0 col-sm-12">
          {!loading ? (
            <>
              <button className="btn btn-primary" onClick={handleUpdate}>
              {t('modal.maintenance.submit')}
              </button>
              {edit && (
                <button
                  className="btn btn-white m-l-5"
                  onClick={() => closeModal(false)}
                >
                  {t('modal.maintenance.close')}
                </button>
              )}
            </>
          ) : (
            <i className="fas fa-spinner fa-pulse fa-3x text-orange-transparent-8"></i>
          )}
        </p>
      </div>
    </div>
  );
};

export default MaintenanceSchedule;
