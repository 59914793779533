import React from "react";
import { Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { connect } from "react-redux";

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileActive: 0,
    };
    this.handleProfileExpand = this.handleProfileExpand.bind(this);
  }

  handleProfileExpand(e) {
    e.preventDefault();
    this.setState((state) => ({
      profileActive: !this.state.profileActive,
    }));
  }

  render() {
    const { user: { NameFull, Email_01, Photo_Thumbnail, leaseDetails }} = this.props;
    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li
              className={"nav-profile " + (this.state.profileActive ? "expand " : "")}
            >
              <div className="cover with-shadow"></div>
              {/* <div className="image image-icon bg-black text-grey-darker">
                <img src={Photo_Thumbnail || "/assets/img/user/user.png"} />
              </div> */}
              <div className="info">
                {/* <b className="caret pull-right"></b> */}
                {NameFull}
                <div className="overflow-auto"><p className="text-grey">{Email_01}</p></div>
                {leaseDetails && leaseDetails['Lease_PROP::Address_Address01_finalDisplay'] && (
                  <>
                    <small>
                      {leaseDetails["Lease_PROP::Address_Address01_finalDisplay"]}
                    </small>
                    <small>
                      {leaseDetails["Lease_PROP::Address_State"] + " " + leaseDetails["Lease_PROP::Address_City"] +
                        " " + leaseDetails["Lease_PROP::Address_PostalCode"]}
                    </small>
                    <small>UNIT: {leaseDetails["Lease_PROP::Number"]}</small>
                  </>
                )}
              </div>
            </li>
            {/* <li>
              <ul
                className={
                  "nav nav-profile " +
                  (this.state.profileActive && !pageSidebarMinify
                    ? "d-block "
                    : "")
                }
              >
                <li>
                  <Link to="/">
                    <i className="fa fa-cog"></i> Settings
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-pencil-alt"></i> Send Feedback
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-question-circle"></i> Helps
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        )}
      </PageSettings.Consumer>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return { user: USER_REDUCER.userData };
};

export default connect(mapStateToProps, {})(SidebarProfile);
