import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { LogoutAction } from "../../../redux/actions/user";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Profile from "../../../pages/user/profile";

class DropdownProfile extends React.Component {
  _6MonthsMiliseconds = 15778800000;
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      showProfileModal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleSweetAlert() {
    this.setState({ openModal: false });
  }

  isPhoneExpired() {
    if (
      !this.props?.user?.web_verifiedPhoneTs
    ) {
      return true;
    }

    const phoneTsDate = new Date(
      this.props?.user?.web_verifiedPhoneTs
    )?.getTime();

    if (!phoneTsDate) {
      return true;
    }

    const dateMiliseconds = new Date().getTime();
    if (
      Math.abs(dateMiliseconds - phoneTsDate) > this._6MonthsMiliseconds
    ) {
      return true;
    }

    return false;
  }

  isEmailExpired(){
    if (
      !this.props?.user?.web_verifiedEmailTs
    ) {
      return true;
    }
    const emailTsDate = new Date(
      this.props?.user?.web_verifiedEmailTs
    )?.getTime();
    if (!emailTsDate) {
      return true;
    }
    const dateMiliseconds = new Date().getTime();
    if (
      Math.abs(dateMiliseconds - emailTsDate) > this._6MonthsMiliseconds 
    ) {
      return true;
    }
    return false;
  }

  renderProfileModal() {
    const { t } = this.props;

    return (
      <Modal
        isOpen={this.state.showProfileModal || this.isPhoneExpired() || this.isEmailExpired()}
        toggle={() =>
          this.setState({
            ...this.state,
            showProfileModal: !this.state.showProfileModal,
          })
        }
        size="xl"
        className="edit-profile-modal"
      >
        <ModalBody className="p-0">
          {!this.isPhoneExpired() && !this.isEmailExpired() ? (
            <button
              onClick={() =>
                this.setState({
                  ...this.state,
                  showProfileModal: !this.state.showProfileModal,
                })
              }
              className="close"
              style={{
                position: "absolute",
                color: "white",
                right: 40,
                top: 35,
              }}
            >
              x
            </button>
          ) :  <button
          onClick={() =>
           this.props.LogoutAction()
          }
          className={"btn btn-xs btn-secondary"}
          style={{
            position: "absolute",
            color: "white",
            right: 40,
            top: 35,
          }}
        >
          Logout
        </button>}
          <Profile isEmailExpired={()=>this.isEmailExpired()} isPhoneExpired={()=> this.isPhoneExpired()} />
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { t, i18n } = this.props;
    const {
      user: { NameFull = "", Photo_Thumbnail },
    } = this.props;
    return (
      <>
        {this.state.openModal && (
          <SweetAlert
            showCancel
            confirmBtnText={t("sweetalert.continue")}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={t("sweetalert.title2")}
            onConfirm={() => this.props.LogoutAction()}
            onCancel={() => this.toggleSweetAlert("info")}
          />
        )}
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          className="dropdown navbar-user"
          tag="li"
        >
          <DropdownToggle tag="a">
            <div className="image image-icon bg-black text-grey-darker">
              <img src={Photo_Thumbnail || "/assets/img/user/user.png"} />
            </div>
            <span className="d-none d-md-inline notranslate">{NameFull}</span>{" "}
            <b className="caret"></b>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
            {/* <DropdownItem onClick={(e) => this.props.history.push("/profile")}> */}
            <DropdownItem
              onClick={(e) =>
                this.setState({
                  ...this.state,
                  showProfileModal: !this.state.showProfileModal,
                })
              }
            >
              {t("profile.edit")}
            </DropdownItem>
            <div className="dropdown-divider"></div>
            <DropdownItem onClick={(e) => this.setState({ openModal: true })}>
              {t("profile.logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {this.renderProfileModal()}
      </>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return { user: USER_REDUCER.userData };
};

export default connect(mapStateToProps, { LogoutAction })(
  withRouter(withTranslation()(DropdownProfile))
);
