const initialState = {
  payments: [],
  maintainence_tickets: [],
  parking: [],
  people: [],
  parkingSpotList: [],
};

const METADATA_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_METADATA": {
      const {
        payments = [],
        Lease_PAYM_NonVoided = [],
        Lease_MAIN = [],
        Lease_LVEH_LeaseVehicles = [],
        Lease_LEPE_LeasePeople_all = [],
        Lease_LSJN_LeaseSpotJoinfromLease = [],
      } = payload;
      return {
        ...state,
        payments: Lease_PAYM_NonVoided && Lease_PAYM_NonVoided.length ? Lease_PAYM_NonVoided : payments,
        maintainence_tickets: Lease_MAIN,
        parking: Lease_LVEH_LeaseVehicles.reverse(),
        people: _peopleParser(Lease_LEPE_LeasePeople_all.reverse()),
        parkingSpotList: Lease_LSJN_LeaseSpotJoinfromLease.map((item) => ({
          id: item["Lease_LSJN_LeaseSpotJoinfromLease::zzID_SPOT"],
          spot: item["Lease_LSJN_LeaseSpotJoinfromLease::Spot"],
          isUsed: item["Lease_LSJN_LeaseSpotJoinfromLease::isUsed"],
          isUsedby: item["Lease_LSJN_LeaseSpotJoinfromLease::isUsedby"],
        })),
      };
    }
    case 'LOGOUT': {
      return { ...initialState }
    }
    default:
      return state;
  }
};

export default METADATA_REDUCER;

const _peopleParser = (data) => {
  let appr = [],
    pend = [],
    archive = [];
  for (let ele of data) {
    if (ele["Lease_LEPE_LeasePeople_all::isArchived"]) {
      archive.push(ele);
    } else if (ele["Lease_LEPE_LeasePeople_all::isPending"]) {
      pend.push(ele);
    } else {
      appr.push(ele);
    }
  }
  return [...appr, ...pend, ...archive];
};
