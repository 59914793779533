const initialState = {
  error: {},
  loading: false,
  parkingDetails: {},
  carInfo: []
};

const PARKING_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PARKING_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "PARKING_COMPLETE": {
      return {
        ...state,
        loading: false,
      };
    }
    case "VIEW_PARKING": {
      return {
        ...state,
        parkingDetails: payload,
      };
    }
    case "PARKING_ERROR": {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case "SET_CAR_INFO": {
      return {
        ...state, 
        carInfo: payload
      }
    }
    default:
      return state;
  }
};

export default PARKING_REDUCER;
