import Axios from "axios";
import { store } from "../store";
import { Notify } from "../../helpers/notification";

const URL = process.env.REACT_APP_API_ENDPOINT;
export const baseURL = {
  URL
};

const Api = () => {
  // if (navigator.onLine) {
  const defaultOptions = {
    baseURL: baseURL.URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 300000,
  };

  let instance = Axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("flora_token");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  });

  const { dispatch } = store;
  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      const { status, message } = err.response.data;
      Notify("danger", message);
      if (status == 420) {
        dispatch({ type: "LOGOUT" });
      }
      return Promise.reject(err);
    }
  );
  return instance;
  // }
};

export default Api();
