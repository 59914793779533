import React, { Component } from "react";
import { connect } from "react-redux";
import { VerifyEmailAction, updateMsg } from "../../redux/actions/user";
import { withRouter } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import SweetAlert from "react-bootstrap-sweetalert";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      loading: false,
    };
    this.onSuccess = this.onSuccess.bind(this);
  }
  static contextType = PageSettings;
  url = new URLSearchParams(this.props.location.search);
  id = this.url.get("id");
  response = this.url.get("response");

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
    this.context.handleSetPageContentFullWidth(true);
    if(this.response){
      this.props.VerifyEmailAction(`?id=${this.id}&response=${this.response}`, this.onSuccess);
      this.setState({ loading: true});
    }
  }

  // on success callback runs after email verification
  onSuccess(data) {
    this.setState({ message: data.message, success: data.status == 200, loading: false });
    this.props.history.push('/verifyEmail')
  }

  toggleSweetAlert() {
    this.props.updateMsg(false)
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
    this.context.handleSetPageContentFullWidth(false);
  }

  render() {
    const { message, success, loading } = this.state;
    return (
      // <div
      //   className="login-cover-image row"
      //   style={{ backgroundImage: "url('/assets/img/login-bg/login-bg-17.jpg')", minHeight: "100vh" }}
      // >
      <div
        className="login-cover"
      >
        <div className="col-sm-12 d-flex justify-content-center align-items-center">
          
          {!loading ? (
            (this.props.success === "success" || this.props.success === "danger") && <SweetAlert
              success={this.props.success === "success" }
              danger={this.props.success === "danger" }
              confirmBtnText="Okay!"
              confirmBtnBsStyle={this.props.success}
              cancelBtnBsStyle="default"
              title="Thank You"
              onConfirm={() => this.toggleSweetAlert()}
            >
              <b>{message}</b>
            </SweetAlert>
          ) : (
            <i className="fas fa-spinner fa-pulse fa-3x" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ PEOPLE_REDUCER }) => {
  return {
    success: PEOPLE_REDUCER.success
  }
}

export default connect(mapStateToProps, { VerifyEmailAction, updateMsg })(withRouter(VerifyEmail));
