import React, { memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import { getDashboardData } from "../../redux/actions/user";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ViewPeople, clearPeopleDetails } from "../../redux/actions/people";
import PeopleForm from "./peopleForm";
import { useTranslation } from "react-i18next";

const PeopleList = memo(
  ({ people = [], user, getDashboardData, ViewPeople, clearPeopleDetails, Leases }) => {
    const [loading, setLoading] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const [edit, setEdit] = useState(false);
    const {t} = useTranslation();
    useEffect(() => {
      if (!people.length && Leases && Leases.length) {
        getDashboardData(onSuccess);
        setLoading(true);
      }
    }, []);

    // on success callback
    const onSuccess = () => {
      setLoading(false);
    };

    // function which controls toggling
    const toggleModal = (e) => {
      e && e.stopPropagation();
      setModalDialog(!modalDialog);
    };

    const closeModal = (bool) => {
      if (bool) {
        getDashboardData();
      }
      setModalDialog(false);
      clearPeopleDetails();
      setEdit(false);
    };

    return (
      <div>
        <Modal isOpen={modalDialog} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => closeModal()}>
            {edit ? `${t('modal.people.header-edit-people')}` : `${t('modal.people.header-add-people')}`}
          </ModalHeader>
          <ModalBody>
            <PeopleForm edit={edit} closeModal={closeModal} />
          </ModalBody>
        </Modal>
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="stats-title text-uppercase text-white font-weight-bold">
                  {t('card-title.people')}
                </div>
                <div className="stats-desc">
                  {t('card-subtitle.people')}
                </div>
              </div>
              {/* <button
                className="btn btn-warning btn-icon"
                onClick={(e) => !loading && toggleModal(e)}
              >
                <i
                  className={!loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"}
                ></i>
              </button> */}
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-nowrap">{t('card-table.people.name')}</th>
                    <th className="text-nowrap">{t('card-table.people.phone')}</th>
                    <th className="text-nowrap">{t('card-table.people.email')}</th>
                    <th className="text-nowrap">{t('card-table.people.status')}</th>
                    <th className="text-nowrap">{t('card-table.people.type')}</th>
                    <th className="text-nowrap"></th>
                  </tr>
                </thead>
                <tbody>
                  {people?.map((item, i) => (
                    <tr
                      key={i}
                      className={`text-nowrap ${item["Lease_PEPL_LeasePeople_all::zzID"] === user?.zzID ||
                        item["Lease_LEPE_LeasePeople_all::isArchived"] ? "hover-not" : "hover-pointer"
                      } ${item["Lease_LEPE_LeasePeople_all::isArchived"] && "text-muted font-italic text-strikethrough"
                      } ${item["Lease_LEPE_LeasePeople_all::isPending"] && 'text-muted font-italic'}`}

                      onClick={(e) => {
                        if (item["Lease_PEPL_LeasePeople_all::zzID"] !== user?.zzID && !item["Lease_LEPE_LeasePeople_all::isArchived"]) {
                          setEdit(true);
                          toggleModal(e);
                          ViewPeople(item["Lease_PEPL_LeasePeople_all::zzID"]);
                        }
                      }}
                    >
                      <td>
                        {item["Lease_PEPL_LeasePeople_all::NameFull"] || "-"}
                        {item["Lease_PEPL_LeasePeople_all::zzID"] === user?.zzID && <i className="fa fa-star" />}
                      </td>
                      <td>
                        {item["Lease_PEPL_LeasePeople_all::Phone_Mobile"] || "-"}
                      </td>
                      <td>
                        {item["Lease_PEPL_LeasePeople_all::Email_01"] || "-"}
                      </td>
                      <td>
                        {(item["Lease_LEPE_LeasePeople_all::isArchived"] && "Archived") ||
                          (item["Lease_LEPE_LeasePeople_all::isPending"] && "Pending") || "Approved"}{" "}
                      </td>
                      <td>{item["Lease_LEPE_LeasePeople_all::Type"] || "-"}</td>
                      <td className="text-center">
                        <i className="fa fa-chevron-right" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  }
);

const mapStateToProps = ({ METADATA_REDUCER, USER_REDUCER, PEOPLE_REDUCER }) => {
  return { people: METADATA_REDUCER.people, user: USER_REDUCER.userData,
           peopleUpdated: PEOPLE_REDUCER.peopleUpdated, peopleDetail: PEOPLE_REDUCER.peopleDetails,  Leases: USER_REDUCER.AllLeases,
          };
};

export default connect(mapStateToProps, {
  getDashboardData,
  ViewPeople,
  clearPeopleDetails,
})(PeopleList);
