/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FullPageLayout from "../../layout/FullPageLayout";
import NoticetoVacate from "../../components/NoticetoVacate";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileWithLeaseAction } from "../../redux/actions/user";
import { useHistory } from "react-router-dom";

const NoticeToVacatePage = () => {
  const { t } = useTranslation();
  const navigate = useHistory();
  const { leaseId, id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.NOTICE_VACATE_REDUCER?.loading);
  const user = useSelector((state) => state?.USER_REDUCER.userData);

  useEffect(() => {
    if (leaseId && id) {
      dispatch(getProfileWithLeaseAction(leaseId, id));
    }
  }, [leaseId, id]);

  const renderBrokenLinkMessage = () => {
    return (
      <div className="">
        <h4>{t("modal.notice.no_records_found")}</h4>
        <p>{t("modal.notice.this_could_happen_if")}</p>
        <ul>
          <li>{t("modal.notice.application_has_finalise")}</li>
          <li>{t("modal.notice.the_link_is_broken_or_expired")}</li>
        </ul>
      </div>
    );
  };

  return (
    <FullPageLayout>
      {loading || !user?.NameFull ? (
        <div className="d-flex align-items-center justify-content-center flex-column mt-5 vh-100">
          <span className="spinner-grow text-info"></span>
          <span className="">Please Wait...</span>
        </div>
      ) : (
        <div className="col-6 mt-4 d-flex align-items-center justify-content-center flex-column" style={{ minHeight: '50vh'}}>
          {user?.leaseDetails?.zz_lease_change_choice === 1 || !user?.leaseDetails["Lease_RINC::zzk_1"] || user?.leaseDetails["Lease_RINC::isTenantReplied"] ? renderBrokenLinkMessage() : (
            <>
              <h1 className="mb-4">{t("modal.notice.header")}</h1>
              <NoticetoVacate
                onSuccess={(result) => {
                  navigate.push("/thank-you-notice-to-vacate", result);
                }}
                withoutToken={true}
                leaseId={leaseId}
              />
            </>
          )}
        </div>
      )}
    </FullPageLayout>
  );
};

export default NoticeToVacatePage;
