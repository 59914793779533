import React, { memo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Panel, PanelHeader, PanelBody } from "../../components/panel/panel.jsx";
import { ToggleArchiveParking } from "../../redux/actions/parking";
import { getDashboardData } from "../../redux/actions/user";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ParkingForm from "./parkingForm";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";

const ParkingList = memo(({ parking = [], getDashboardData, ToggleArchiveParking, loader, Leases }) => {
    const [loading, setLoading] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [selectedArchived, setSelectedArchived] = useState("");
    const [edit, setEdit] = useState(false);
    const [showNotAassiged, setShowNotAssigned] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      if (!parking.length && Leases && Leases.length) {
        getDashboardData(onSuccess);
        setLoading(true);
      }
    }, []);

    const onSuccess = () => {
      setLoading(false);
    };

    const toggleModal = (e) => {
      e && e.stopPropagation();
      setModalDialog(!modalDialog);
    };

    const closeModal = (bool) => {
      if (bool) {
        getDashboardData();
      }
      setModalDialog(false);
      setEdit(false);
      setSelectedItem({});
    };

    const closeModalAdd = (bool) => {
      if (bool) {
        getDashboardData();
      }
      setModalDialog(false);
      setEdit(false);
      setSelectedItem({});
      setShowNotAssigned(true);
    };

    const handleToggleParking = () => {
      let item = parking[selectedArchived];
      let id = item["Lease_LVEH_LeaseVehicles::zzID"];
      let isArchived = item["Lease_LVEH_LeaseVehicles::isArchived"] ? "" : "1";
      ToggleArchiveParking(id, { isArchived }, closeModal);
      setOpenAlert(false);
    };

    // function which opens confirmation box for parking
    const openParkingAlert = (e, index) => {
      e.stopPropagation();
      index && setSelectedArchived(index);
      setOpenAlert(true);
    };

    return (
      <div>
        {openAlert && (
          <SweetAlert
            showCancel
            confirmBtnText={t('modal.parking.sweetalert.continue')}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={t('modal.parking.sweetalert.title')}
            onConfirm={() => handleToggleParking()}
            onCancel={() => {
              setSelectedArchived("");
              setOpenAlert(false);
            }}
          />
        )}

        {showNotAassiged && (
          <SweetAlert
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={t('modal.parking.no-designated-spot')}
            onConfirm={() => setShowNotAssigned(false)}
            onCancel={() => setShowNotAssigned(false)}
          />
        )}

        <Modal isOpen={modalDialog} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => closeModal()}>
            {edit ? `${t('modal.parking.header-edit-parking')}` : `${t('modal.parking.header-add-parking')}`}
          </ModalHeader>
          <ModalBody>
            <ParkingForm
              item={selectedItem}
              edit={edit}
              openParkingAlert={openParkingAlert}
              closeModal={closeModal}
              closeModalAdd={closeModalAdd}
            />
          </ModalBody>
        </Modal>
        <Panel>
          <PanelHeader noButton={true}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="stats-title text-uppercase text-white font-weight-bold">
                  {t('card-title.parking')}
                </div>
                <div className="stats-desc">
                  {t('card-subtitle.parkingv2')}
                </div>
              </div>
              {
                Leases && Leases.length>0 &&  <button
                className="btn btn-warning btn-icon"
                onClick={(e) => !loading && toggleModal(e)}
              >
                <i
                  className={
                    !loading ? "fa fa-plus" : "fas fa-spinner fa-pulse"
                  }
                ></i>
              </button>
              }
             
            </div>
          </PanelHeader>
          <PanelBody>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-nowrap">{t('card-table.parking.year')}</th>
                    <th className="text-nowrap">{t('card-table.parking.make')}</th>
                    <th className="text-nowrap">{t('card-table.parking.model')}</th>
                    <th className="text-nowrap">{t('card-table.parking.color')}</th>
                    <th className="text-nowrap">{t('card-table.parking.license')}</th>
                    <th className="text-nowrap">{t('card-table.parking.state')}</th>
                    <th className="text-nowrap">{t('card-table.parking.spot')}</th>
                    <th className="text-nowrap">{t('card-table.parking.status')}</th>
                    <th className="text-nowrap"></th>
                  </tr>
                </thead>
                <tbody>
                  {parking?.map((item, i) => (
                    <tr
                      key={i}
                      className={`text-nowrap ${
                        !item["Lease_LVEH_LeaseVehicles::isArchived"]
                          ? "hover-pointer"
                          : "hover-not text-muted font-italic"
                      }`}
                      onClick={(e) => {
                        if (!item["Lease_LVEH_LeaseVehicles::isArchived"]) {
                          setEdit(true);
                          setSelectedItem(item);
                          setSelectedArchived(i);
                          toggleModal(e);
                        }
                      }}
                    >
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::Year"] || "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::Make"] || "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::Model"] || "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::Color"] || "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::LicensePlate"] || "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::RegisteredState"] ||
                          "-"}
                      </td>
                      <td
                        className={
                          item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                          "text-strikethrough"
                        }
                      >
                        {item["Lease_LVEH_LeaseVehicles::SpotNumber"] || "-"}
                      </td>
                      <td>
                        <div className="btn-group btn-group-justified">
                          {item["Lease_LVEH_LeaseVehicles::isArchived"] ? (
                            <button
                              disabled={
                                loader ||
                                !item["Lease_LVEH_LeaseVehicles::isArchived"]
                              }
                              className={`btn btn-sm btn-default ${
                                !item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                                "hover-not"
                              }`}
                              style={{ textDecoration: "none" }}
                              onClick={(e) => openParkingAlert(e, i)}
                            >
                              {t('modal.parking.activate')}
                            </button>
                          ) : (
                            <button
                              disabled={
                                loader ||
                                item["Lease_LVEH_LeaseVehicles::isArchived"]
                              }
                              className={`btn btn-sm btn-default ${
                                item["Lease_LVEH_LeaseVehicles::isArchived"] &&
                                "hover-not"
                              }`}
                              onClick={(e) => openParkingAlert(e, i)}
                            >
                              {t('modal.parking.archive')}
                            </button>
                          )}
                        </div>
                      </td>
                      <td className="text-center">
                        <i className="fa fa-chevron-right" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </PanelBody>
        </Panel>
      </div>
    );
  }
);

const mapStateToProps = ({
  METADATA_REDUCER,
  USER_REDUCER,
  PARKING_REDUCER,
}) => {
  return {
    parking: METADATA_REDUCER.parking,
    loader: PARKING_REDUCER.loading,
    user: USER_REDUCER.userData,
    Leases: USER_REDUCER.AllLeases,
  };
};

export default connect(mapStateToProps, {
  getDashboardData,
  ToggleArchiveParking,
})(ParkingList);
