import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Notify } from "../../helpers/notification";
import Api from "../../redux/api/api";
import { connect } from "react-redux";

function Language({ isAuth, Leases }) {
  const { i18n } = useTranslation();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    localStorage.getItem("i18nextLng") === "es"
      ? setSelectedLanguage("Spanish")
      : changeLanguage("en", "English");
  }, []);

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const changeLanguage = (language, text) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(text);
    if (isAuth && Leases && Leases.length) {
      Api.get(`api/people/preferredLanguage/${text}`)
        .then((response) => {
          // console.log(response.data)
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((err) => {
          Notify("danger", "Something bad happened :(");
        });
    }
  };

  return (
    <div>
      <Dropdown
        isOpen={dropDownOpen}
        toggle={toggle}
        className="dropdown navbar-languager"
        tag="li"
      >
        <DropdownToggle className="dropdown-toggle" tag="a">
          <span
            className={`flag-icon ${
              selectedLanguage === "Spanish" ? `flag-icon-es` : `flag-icon-us`
            } m-r-5`}
          ></span>
          <span className="name d-none d-sm-inline">{selectedLanguage}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          <DropdownItem onClick={(e) => changeLanguage("en", "English")}>
            <span className="flag-icon flag-icon-us m-r-5"></span> English
          </DropdownItem>
          <DropdownItem onClick={(e) => changeLanguage("es", "Spanish")}>
            <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => ({
  isAuth: USER_REDUCER && USER_REDUCER.isAuth,
  Leases: USER_REDUCER.AllLeases,
});

export default connect(mapStateToProps)(Language);
