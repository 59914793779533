import React from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "../../config/page-settings.js";
import { connect } from "react-redux";
import {
  AdminLoginAction
} from "../../redux/actions/user";
import { Validation } from "../../helpers/validations";
import { withTranslation } from 'react-i18next';
import ErrorPage from './errorPage';

class Login extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
      error: { email: "", password: "" },
      activeBg: "/assets/img/login-bg/login-bg-17.jpg",
      isError: false,
      errorMessage: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  componentDidMount() {
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);
  }

  componentDidUpdate(prevProps, prevState) { 
    if (prevProps.error !== this.props.error) {
      this.setState({ error: prevProps.error });
      return;
    }
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
  }

  reset() {
    this.setState({
      email: "",
      password: "",
    });
  }

  // validator function
  handleValid = ({ email, password }) => {
    if (!Validation.email(email)) {
      this.setState({ error: { email: this.props.t('login.validate.email') } });
      return false;
    } else if (Validation.empty(password) || password.length < 8) {
      this.setState({
        error: {
          password: this.props.t('login.validate.password'),
        },
      });
      return false;
    }
    return true;
  };

  // on complete callback
  onComplete() {
    this.setState({ loading: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    let payload = { email: email, password: password ,client: 1};
    if (this.handleValid(payload)) {
      this.props.AdminLoginAction(payload, this.onComplete);
      this.setState({ loading: true });
    }
  }

  render() {
    const { error, isError, errorMessage } = this.state;
    const { t } = this.props;
    if (isError) {
      return <ErrorPage errorMessage={errorMessage}/>
    }

    return (
      <React.Fragment>
        <div className="login-cover">
          <div
            className="login-cover-image"
            style={{ backgroundImage: "url(" + this.state.activeBg + ")" }}
          ></div>
          <div className="login-cover-bg"></div>
        </div>

        <div className="login login-v2">
          <div className="login-header">
            <div className="brand">
              <b>Florio Admin</b>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>
          <div className="login-content">
              <form
                className="margin-bottom-0"
                onSubmit={this.handleSubmit}
              >
                <div className="form-group m-b-20">
                  <input
                    type="email"
                    className={`form-control form-control-lg ${error.email && "is-invalid"}`}
                    placeholder={t('login.email')}
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    required
                    onFocus={(e) => this.setState({ error: { ...this.state.error, email: "" }})
                    }
                  />
                  <h5 className="invalid-feedback">{this.state.error.email}</h5>
                </div>
                <div className="form-group m-b-20">
                  <input
                    type="password"
                    className={`form-control form-control-lg ${error.password && "is-invalid"}`}
                    placeholder={t('login.password')}
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    required
                    onFocus={(e) => this.setState({ error: { ...this.state.error, password: "" }})}
                  />
                  <h5 className="invalid-feedback">
                    {this.state.error.password}
                  </h5>
                </div>
                <div className="login-buttons">
                  <button
                    disabled={this.state.loading}
                    type="submit"
                    className="btn btn-success btn-block btn-lg"
                  >
                    {!this.state.loading ? (
                      `${t('login.submit')}`
                    ) : (
                      <i className="fas fa-spinner fa-pulse fa-2x"></i>
                    )}
                  </button>
                </div>
              </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER }) => ({
  isAuth: USER_REDUCER && USER_REDUCER.isAuth,
  error: USER_REDUCER && USER_REDUCER.error,
});

export default connect(mapStateToProps, {
  AdminLoginAction
})(withRouter(withTranslation()(Login)));
