import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { finishQueue } from "../../redux/actions/webqueue";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Documents = (props) => {
  const { _id } = useParams();
  const dispath = useDispatch();
  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const handleSubmit = async () => {
    setLoading(true);
    const tempError = { ...errors };

    if (!documents?.Photo_ID) {
      tempError.Photo_ID = "Photo ID is required.";
    } else {
      delete tempError.Photo_ID;
    }
    if (!documents?.Photo_SocialSecurity) {
      tempError.Photo_SocialSecurity = "Social Sucurity is required.";
    } else {
      delete tempError.Photo_SocialSecurity;
    }
    if (!documents?.Photo_PayStub01) {
      tempError.Photo_PayStub01 = "Pay Stub 1 is required.";
    } else {
      delete tempError.Photo_PayStub01;
    }
    if (!documents?.Photo_PayStub02) {
      tempError.Photo_PayStub02 = "Pay Stub 2 is required.";
    } else {
      delete tempError.Photo_PayStub02;
    }

    setErrors(tempError);
    if (Object.entries(tempError).length) {
      setLoading(false);
      return;
    }

    const formdata = new FormData();
    for (const key in documents) {
      if (Object.hasOwnProperty.call(documents, key)) {
        const element = documents[key];
        formdata.append(key, element);
      }
    }
    try {
      await dispath(finishQueue(_id, formdata));
      history.push("/thank-you");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderDocuments = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3>
              {t("tenantApp.documents")}
              <span className="text-secondary ml-2" style={{ fontSize: 12 }}>
                ({t("tenantApp.documents_.required_document")})
              </span>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4 ">
            <span>{t("tenantApp.documents_.photo_id")}*</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_ID ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                if (!documents?.Photo_ID) {
                  let inputFile = document.getElementById("photo_id_input");
                  if (inputFile) {
                    inputFile.click();
                  }
                }
              }}
            >
              {documents?.Photo_ID ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_ID)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_ID: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="photo_id_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_ID;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_ID: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_ID}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 ">
            <span>{t("tenantApp.documents_.social_security")}*</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_SocialSecurity ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                if (!documents?.Photo_SocialSecurity) {
                  let inputFile = document.getElementById(
                    "photo_social_security_input"
                  );
                  if (inputFile) {
                    inputFile.click();
                  }
                }
              }}
            >
              {documents?.Photo_SocialSecurity ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_SocialSecurity)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_SocialSecurity: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="photo_social_security_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_SocialSecurity;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_SocialSecurity: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_SocialSecurity}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>{t("tenantApp.documents_.pay_stub1")}*</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub01 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                if (!documents?.Photo_PayStub01) {
                  let inputFile = document.getElementById(
                    "Photo_PayStub01_input"
                  );
                  if (inputFile) {
                    inputFile.click();
                  }
                }
              }}
            >
              {documents?.Photo_PayStub01 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub01)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub01: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub01_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub01;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub01: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub01}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>{t("tenantApp.documents_.pay_stub2")}*</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub02 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                let inputFile = document.getElementById(
                  "Photo_PayStub02_input"
                );
                if (inputFile) {
                  inputFile.click();
                }
              }}
            >
              {documents?.Photo_PayStub02 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub02)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub02: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub02_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub02;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub02: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub02}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>{t("tenantApp.documents_.pay_stub3")}</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub03 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                let inputFile = document.getElementById(
                  "Photo_PayStub03_input"
                );
                if (inputFile) {
                  inputFile.click();
                }
              }}
            >
              {documents?.Photo_PayStub03 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub03)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub03: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub03_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub03;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub03: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub03}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>{t("tenantApp.documents_.pay_stub4")}</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub04 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                let inputFile = document.getElementById(
                  "Photo_PayStub04_input"
                );
                if (inputFile) {
                  inputFile.click();
                }
              }}
            >
              {documents?.Photo_PayStub04 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub04)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub04: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub04_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub04;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub04: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub04}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>{t("tenantApp.documents_.pay_stub5")}</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub05 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                let inputFile = document.getElementById(
                  "Photo_PayStub05_input"
                );
                if (inputFile) {
                  inputFile.click();
                }
              }}
            >
              {documents?.Photo_PayStub05 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub05)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub05: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub05_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub05;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub05: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub05}</span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4  mt-3">
            <span>Pay Stub 6</span>
            <div
              className={`border w-100 d-flex justify-content-center align-items-center flex-column ${
                errors?.Photo_PayStub06 ? "border-danger" : ""
              }`}
              style={{ height: 200, position: "relative" }}
              role="button"
              onClick={() => {
                let inputFile = document.getElementById(
                  "Photo_PayStub06_input"
                );
                if (inputFile) {
                  inputFile.click();
                }
              }}
            >
              {documents?.Photo_PayStub06 ? (
                <>
                  <img
                    src={URL.createObjectURL(documents.Photo_PayStub06)}
                    style={{ height: 200, objectFit: "contain" }}
                  />
                  <i
                    role="button"
                    onClick={() => {
                      setDocuments({
                        ...documents,
                        Photo_PayStub06: undefined,
                      });
                    }}
                    className="fa fa-trash text-danger"
                    style={{ position: "absolute", top: 5, right: 5 }}
                  ></i>
                </>
              ) : (
                <>
                  <i
                    role="button"
                    className="fa fa-plus"
                    style={{ fontSize: 20 }}
                  ></i>
                  <span>{t("tenantApp.documents_.click_to_upload")}</span>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="d-none"
                    id="Photo_PayStub06_input"
                    onChange={(e) => {
                      const tempError = { ...errors };
                      delete tempError.Photo_PayStub06;
                      setErrors({ ...tempError });
                      setDocuments({
                        ...documents,
                        Photo_PayStub06: e.target.files[0],
                      });
                    }}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errors?.Photo_PayStub06}</span>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-6 col-md-4 col-lg-2">
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => {
                props.handleNavigation("previous");
              }}
              disabled={loading}
            >
              {t("tenantApp.navigation.previous")}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              onClick={() => {
                handleSubmit();
              }}
              disabled={loading}
            >
              {t("tenantApp.navigation.finish")}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderDocuments()}
      {renderNavigationButton()}
    </>
  );
};

export default Documents;
