import React, { useEffect, useContext, useState, useCallback } from "react";
import { PageSettings } from "../../config/page-settings";
import logo from "../../assets/img/logo/AniaLogo-full.svg";
import { useDispatch } from "react-redux";
import { deleteQueueById } from "../../redux/actions/webqueue";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
  } from "reactstrap";


const DeleteTenantRecords = () => {
  const { t, i18n } = useTranslation();
  const context = useContext(PageSettings);
  const dispatch = useDispatch();
  const { _id } = useParams();
  const [appLoading, setAppLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('i18nextLng')==='es'?'Spanish':"English");
  const [dropDownOpen, setDropDownOpen] = useState(false);


  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const deleteById = useCallback(async ()=>{
    setAppLoading(true);
    await dispatch(deleteQueueById(_id));
    setAppLoading(false);
  },[])

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
    deleteById();
  }, []);

  const renderLanguageMenu = () =>{
    return  <Dropdown
    isOpen={dropDownOpen}
    toggle={toggle}
    className="dropdown navbar-languager"
    
  >
    <DropdownToggle className="dropdown-toggle" tag="a">
      <span
        className={`flag-icon ${
          selectedLanguage === "Spanish"
            ? `flag-icon-es`
            : `flag-icon-us`
        } m-r-5`}
      ></span>
      <span className="name d-none d-sm-inline">
        {selectedLanguage}
      </span>
    </DropdownToggle>
    <DropdownMenu
      className="dropdown-menu dropdown-menu-right"
    >
      <DropdownItem onClick={(e) => {
        setSelectedLanguage('English')
        i18n.changeLanguage("en")
        }}>
        <span className="flag-icon flag-icon-us m-r-5"></span> English
      </DropdownItem>
      <DropdownItem onClick={(e) => {
        setSelectedLanguage('Spanish')
        i18n.changeLanguage("es")
        }}>
        <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
  }

  const renderBrokenLinkMessage = () => {
    return (
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-xl-3 col-12">
            <img src={logo} alt="" />
          </div>
          <div className="col-sm-2 col-xl-2 col-12 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
          {renderLanguageMenu()}
          </div>
        </div>
        <div
          className="row align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="col-md-6">
            <h4>{t("tenantApp.records_deleted")}</h4>
          </div>
        </div>
      </div>
    );
  };

  return appLoading ? (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <span className="spinner-grow text-primary"></span>
      </div>
    </div>
  ) : (
    renderBrokenLinkMessage()
  ) 
};

export default DeleteTenantRecords;
