import React from "react";
import { PageSettings } from "./../../config/page-settings.js";
import { connect } from "react-redux";
import { updateProfile, updateMsg } from "../../redux/actions/user";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components//panel/panel.jsx";
import { Validation } from "../../helpers/validations";
import SweetAlert from "react-bootstrap-sweetalert";
import WrapperComponent from "../wrapperComponent";
import Stepper from "./stepper";
import { isEqual } from "lodash";
import { withTranslation } from "react-i18next";
class Profile extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
    this.state = {
      type: "",
      tabAbout: true,
      loading: false,
      user: this.props.user || {},
      imageChange: false,
      edit: false,
      formStep: "",
    };
  }

  componentDidMount() {
    this.context.handleSetPageContentFullHeight(true);
    this.context.handleSetPageContentFullWidth(true);
  }

  componentWillUnmount() {
    this.context.handleSetPageContentFullHeight(false);
    this.context.handleSetPageContentFullWidth(false);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.user, prevProps.user)) {
      this.setState({ user: this.props.user });
    }
    if (this.state.imageChange) {
      this.setState({ imageChange: false });
      this.handleSubmit();
    }
  }

  reset = (bool) => {
    if (bool) {
      this.setState((prevState) => ({
        loading: false,
        edit: false,
        formStep: "",
        type: "",
        user: { ...prevState.user, Photo: "", Photo_preview: "" },
      }));
    } else {
      this.setState({ loading: false });
    }
  };

  // handles image both heic, jpeg etc
  handleImage(file) {
    if (file && file.type.split("/")[0] === "image") {
      let reader = new FileReader();
      reader.onloadend = () => {
        let Photo_preview = URL.createObjectURL(file);
        this.setState((prevState) => ({
          user: { ...prevState.user, Photo_preview, Photo: file },
          imageChange: true,
        }));
        this.imgRef.current.value = null;
      };
      reader.readAsDataURL(file);
    }
  }

  // validator which checks all neccessary conditions
  checkFancyValidation = (value) => {
    let obj = Validation.fancyValidation(value);
    this.setState({ fancyValidation: obj, newPassword: value });
  };

  handleSubmit() {
    const {
      user: { Photo },
    } = this.state;
    const formdata = new FormData();
    formdata.append("Photo", Photo);
    this.props.updateProfile(formdata, this.reset);
    this.setState({ loading: true });
  }

  // toggle alert
  toggleSweetAlert() {
    this.props.updateMsg(false);
  }

  render() {
    const { user, formStep, loading } = this.state;
    const { t } = this.props;
    return (
      <>
        {this.props.success && (
          <SweetAlert
            success
            confirmBtnText={t("user.sweetalert.text")}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="default"
            title={t("user.sweetalert.title")}
            onConfirm={() => this.toggleSweetAlert()}
          >
            {t("user.sweetalert.info")}
          </SweetAlert>
        )}

        <div className="profile-content">
          <Panel>
            <PanelHeader noButton={true}>{t("user.card-title")}</PanelHeader>
            <PanelBody className={formStep ? "p-0" : ""}>
              {!formStep ? (
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <div className="profile-container">
                            <input
                              type="file"
                              className="d-none"
                              accept="image/*"
                              ref={this.imgRef}
                              onChange={(e) =>
                                this.handleImage(e.target.files[0])
                              }
                            />
                            <div className="user-profile-pic">
                              <img
                                src={`${
                                  user.Photo_preview ||
                                  user.Photo_Thumbnail ||
                                  "/assets/img/user/user.png"
                                }`}
                                alt="alt"
                              />
                              <span className="edit-pencil">
                                {!loading ? (
                                  <i
                                    className="fas fa-pencil-alt fa-fw"
                                    onClick={(e) => this.imgRef.current.click()}
                                  />
                                ) : (
                                  <i className="fas fa-spinner fa-pulse" />
                                )}
                              </span>
                            </div>
                          </div>
                          <h5 className="m-t-10 m-b-5">
                            {user && user.NameFull}
                          </h5>
                          {/* <td></td>
                          <td></td> */}
                          <br />
                          <br />
                        </td>
                      </tr>
                      {(this.props?.isEmailExpired &&
                        this.props?.isEmailExpired()) ||
                      (this.props?.isPhoneExpired &&
                        this.props?.isPhoneExpired()) ? (
                        <tr>
                          <td colSpan="3">
                            <div className="row mb-0 p-0">
                              <div className="col-12 m-0 p-0">
                                <div class="alert alert-pink mb-0" role="alert">
                                  {t("dashboard.expired_email_phone_alert")}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      <tr>
                        <td>{t("user.primary")}</td>
                        <td>
                          <i className="fa fa-envelope fa-lg m-r-5"></i>{" "}
                          {user && user.Email_01}
                        </td>
                        <td>
                          {this.props?.isEmailExpired &&
                          this.props?.isEmailExpired() ? (
                            <button
                              className="btn btn-xs btn-info"
                              onClick={(e) =>
                                this.setState({ formStep: "Email_01" })
                              }
                            >
                              verify
                            </button>
                          ) : (
                            <i
                              className="fas fa-pencil-alt fa-fw"
                              onClick={(e) =>
                                this.setState({ formStep: "Email_01" })
                              }
                            />
                          )}
                        </td>
                      </tr>
                      <tr className="table-valign-middle">
                        <td className="field">{t("user.secondary")}</td>
                        <td>
                          <i className="fa fa-envelope fa-lg m-r-5"></i>{" "}
                          {user && (user.Email_02 || "--")}{" "}
                        </td>
                        <td>
                          <i
                            className="fas fa-pencil-alt fa-fw"
                            onClick={(e) =>
                              this.setState({ formStep: "Email_02" })
                            }
                          />
                        </td>
                      </tr>
                      <tr className="table-valign-middle">
                        <td className="field">{t("user.mobile")}</td>
                        <td>
                          <i className="fa fa-mobile fa-lg m-r-5"></i>{" "}
                          {user && user.Phone_Mobile}{" "}
                        </td>
                        <td>
                          {this.props?.isPhoneExpired &&
                          this.props?.isPhoneExpired() ? (
                            <button
                              className="btn btn-xs btn-info"
                              onClick={(e) =>
                                this.setState({ formStep: "phone" })
                              }
                            >
                              verify
                            </button>
                          ) : (
                            <i
                              className="fas fa-pencil-alt fa-fw"
                              onClick={(e) =>
                                this.setState({ formStep: "phone" })
                              }
                            />
                          )}
                        </td>
                      </tr>
                      {/* <tr className="table-valign-middle">
                          <td className="field">Home</td>
                          <td>{user && user.leaseDetails["Lease_PROP::Address_Address01_finalDisplay"]}</td>
                          <td></td>
                        </tr>
                        <tr className="table-valign-middle">
                          <td className="field">State</td>
                          <td>{user && user.leaseDetails["Lease_PROP::Address_State"]}</td>
                          <td></td>
                        </tr>
                        <tr className="table-valign-middle">
                          <td className="field">City</td>
                          <td>{user && user.leaseDetails["Lease_PROP::Address_City"]}</td>
                          <td></td>
                        </tr>
                        <tr className="table-valign-middle">
                          <td className="field">Postal Code</td>
                          <td>{user && user.leaseDetails["Lease_PROP::Address_PostalCode"]}</td>
                          <td></td>
                        </tr> */}
                      <tr className="table-td-valign-middle">
                        <td className="field">{t("user.currentPass")}</td>
                        <td>
                          <input
                            type="password"
                            disabled={true}
                            className="form-control form-control-sm"
                            placeholder={"*********"}
                          />
                        </td>
                        <td>
                          <i
                            className="fas fa-pencil-alt fa-fw"
                            onClick={(e) =>
                              this.setState({ formStep: "password" })
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <Stepper
                  handleSubmit={this.handleSubmit}
                  onCancel={this.reset}
                  type={this.state.formStep}
                />
              )}
            </PanelBody>
          </Panel>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER, PEOPLE_REDUCER }) => {
  return { user: USER_REDUCER.userData, success: PEOPLE_REDUCER.success };
};

const ProfileWrapper = (props) => (
  <WrapperComponent>
    <Profile {...props} />
  </WrapperComponent>
);

export default connect(mapStateToProps, { updateProfile, updateMsg })(
  withTranslation()(ProfileWrapper)
);
