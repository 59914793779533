import React, { memo, useCallback, useState } from "react";
import { Validation } from "../../helpers/validations";
import { connect } from "react-redux";
import { ChangePasswordAction, LogoutAction } from "../../redux/actions/user";
import { useTranslation } from "react-i18next";

const ChangePassword = memo(
  ({ ChangePasswordAction, zzID, switchToLogin, handleBack, LogoutAction }) => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [fancyValidation, setFancyValidation] = useState({
      eightCharacters: false,
      oneNumber: false,
      oneCapitalLetter: false,
      oneLowerLetter: false,
      oneSpecialCharacter: false,
    });
    const { t } = useTranslation();

    const handleValid = useCallback(() => {
      if (Validation.empty(currentPassword)) {
        setError({ ...error, currentPasswordError: t('login.validate.currentPass') });
        return false;
      } else if (!Validation.password(newPassword)) {
        setError({ ...error, newPasswordError: t('login.validate.newPass') });
        return false;
      } else if (newPassword != confirmPassword) {
        setError({ ...error, confirmPasswordError: t('login.validate.confirmPass') });
        return false;
      }
      return true;
    }, [currentPassword, newPassword, confirmPassword, error]);

    const onSuccess = (bool) => {
        if (bool) {
          switchToLogin();
        }
        setLoading(false);
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValid()) {
          const payload = { currentPassword, newPassword, confirmPassword, zzID };
          ChangePasswordAction(payload, onSuccess);
          setLoading(true);
        }
      }

    const checkFancyValidation = (value) => {
      let obj = Validation.fancyValidation(value);
      setFancyValidation(obj);
      setNewPassword(value);
    }

    return (
      <div>
        <div>
          <div className="checkbox checkbox-css">
            <input
              type="checkbox"
              id="cssCheckbox1"
              checked={fancyValidation.eightCharacters}
            />
            <label htmlFor="cssCheckbox1">{t('login.passwordChange.check1')}</label>
          </div>
          <div className="checkbox checkbox-css">
            <input
              type="checkbox"
              id="cssCheckbox1"
              checked={fancyValidation.oneCapitalLetter}
            />
            <label htmlFor="cssCheckbox1">
              {t('login.passwordChange.check2')}
            </label>
          </div>{" "}
          <div className="checkbox checkbox-css">
            <input
              type="checkbox"
              id="cssCheckbox1"
              checked={fancyValidation.oneLowerLetter}
            />
            <label htmlFor="cssCheckbox1">
              {t('login.passwordChange.check3')}
            </label>
          </div>{" "}
          <div className="checkbox checkbox-css">
            <input
              type="checkbox"
              id="cssCheckbox1"
              checked={fancyValidation.oneNumber}
            />
            <label htmlFor="cssCheckbox1">{t('login.passwordChange.check4')}</label>
          </div>
          <div className="checkbox checkbox-css">
            <input
              type="checkbox"
              id="cssCheckbox1"
              checked={fancyValidation.oneSpecialCharacter}
            />
            <label htmlFor="cssCheckbox1">
              {t('login.passwordChange.check5')} ({"!@#$%&^?|"})
            </label>
          </div>
        </div>
        <br />
        <form className="m-b-20" onSubmit={handleSubmit}>
          <div className="form-group m-b-10">
            <input
              type="password"
              className={`form-control form-control-lg ${error.currentPasswordError && "is-invalid"}`}
              placeholder={t('login.tempPass')}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              onFocus={(e) => setError({ ...error, currentPasswordError: "" })}
            />
            <h6 className="invalid-feedback">{error.currentPasswordError}</h6>
          </div>
          <div className="form-group m-b-10">
            <input
              type="password"
              className={`form-control form-control-lg ${error.newPasswordError && "is-invalid"}`}
              placeholder={t('login.newPass')}
              value={newPassword}
              onChange={(e) => checkFancyValidation(e.target.value)}
              required
              onFocus={(e) => setError({ ...error, newPasswordError: "" })}
            />
            <h6 className="invalid-feedback">{error.newPasswordError}</h6>
          </div>
          <div className="form-group m-b-10">
            <input
              type="password"
              className={`form-control form-control-lg ${error.confirmPasswordError && "is-invalid"}`}
              placeholder={t('login.confirmPass')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              onFocus={(e) => setError({ ...error, confirmPasswordError: "" })}
            />
            <h6 className="invalid-feedback">{error.confirmPasswordError}</h6>
          </div>
          <p
            className="hover-pointer text-white"
            onClick={(e) => {
              handleBack();
              LogoutAction();
            }}
          >
            <i className="fas fa-angle-left" /> {t('login.back')}
          </p>
          <button
            disabled={loading}
            className="btn btn-primary btn-block"
            onClick={handleSubmit}
          >
            {!loading ? `${t('login.submit')}` : <i className="fas fa-spinner fa-pulse" />}
          </button>
        </form>
      </div>
    );
  }
);

export default connect(null, { ChangePasswordAction, LogoutAction })(ChangePassword);
