import Api from "../api/api";
import { Notify } from "../../helpers/notification";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

export const LoginAction = (payload, cb) => (dispatch) => {
  Api.post("api/user/login", payload)
    .then((response) => {
      if(response?.data?.status == 200){
        cb && cb(true);
        localStorage.setItem("flora_token", response?.data?.data?.token);
        return dispatch({ type: "LOGIN", payload: response?.data?.data });
      }
      let errorCodes = ["100","802","803","804","805","806","807","808","809","810","812"];
      if (response?.data?.errorCode && errorCodes.includes(response.data.errorCode)) {
        let data = {
          email: payload.email,
          password: payload.password,
          client: 2,
        };
        Api.post("api/user/login", data)
          .then((response) => {
            cb && cb(true);
            localStorage.setItem("flora_token", response?.data?.data?.token);
            dispatch({ type: "LOGIN", payload: response?.data?.data });
          })
          .catch((err) => {
            cb &&
              cb(
                false,
                err?.response?.data?.errorCode,
                err?.response?.data?.message
              );
          })
          .catch(() => {
            Notify("danger", "Something Bad Happened :(");
          });
      }else{
        Notify("danger", response?.data.message || "Something Bad Happened :(");
        cb && cb(false);
      }
    })
    .catch((err) => {
      cb &&
        cb(false, err?.response?.data?.errorCode, err?.response?.data?.message);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const AdminLoginAction = (payload, cb) => () => {
  Api.post("api/user/", payload)
    .then((response) => {
      cb && cb(response.data.data);
      Notify("success", response.data.message);
    })
    .catch((err) => {
      cb && cb(false);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const ForgotPassword = (payload, cb) => (dispatch) => {
  Api.post("api/user/forgotPassword", payload)
    .then((response) => {
      cb && cb(response.data.data);
      Notify("success", response.data.message);
    })
    .catch((err) => {
      cb && cb(false);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const ChangeLease = (payload, cb) => (dispatch) => {
  Api.post("api/user/changeLease", payload)
    .then((response) => {
      localStorage.setItem("flora_token", response.data.data.token);
      dispatch({ type: "CHANGE_LEASE", payload: response.data.data.userData });
      dispatch(getDashboardData());
    })
    .catch(() => {})
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const getProfile = () => (dispatch) => {
  Api.get("api/user/getProfile")
    .then((response) => {
      dispatch({ type: "GET_PROFILE", payload: response.data.data });
      dispatch({ type: "PEOPLE_COMPLETE" });
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const updateProfile = (payload, cb) => (dispatch) => {
  dispatch({ type: "PEOPLE_LOADING" });
  Api.post("api/user/updateProfile", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      process.env.NODE_ENV === "development" &&
        Notify("success", response.data.message);
      dispatch(getProfile());
      dispatch({ type: UPDATE_PROFILE });
      cb && cb(true);
    })
    .catch(() => {
      dispatch({ type: "PEOPLE_COMPLETE" });
      cb && cb(false);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const getDashboardData = (cb) => (dispatch) => {
  dispatch({type: "SET_APP_LOADING", payload: true})
  Api.get("api/user/metaData")
    .then((response) => {
      dispatch({ type: "GET_METADATA", payload: response.data.data });
      cb && cb();
    })
    .catch((err) => {
      cb && cb();
    })
    .catch(() => Notify("danger", "Something Bad Happened :("))
    .finally(()=>{
       dispatch({type: "SET_APP_LOADING", payload: false})
    });
};

export const ChangePasswordAction = (payload, cb) => (dispatch) => {
  Api.post("api/user/changePassword", payload)
    .then((response) => {
      cb && cb(true);
      Notify("success", response.data.message);
      dispatch({ type: "GET_PROFILE", payload: "" });
    })
    .catch((err) => {
      cb && cb(false);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const VerifyEmailAction = (requestedParams, cb) => (dispatch) => {
  Api.get(`api/user/verifyEmail${requestedParams}`)
    .then((response) => {
      cb && cb(response.data);
      dispatch({ type: UPDATE_PROFILE, payload: "success" });
      // Notify("success", response.data.message);
    })
    .catch((err) => {
      cb && cb(err.response.data);
      dispatch({ type: UPDATE_PROFILE, payload: "danger" });
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const sendOtp = (payload, cb) => (dispatch) => {
  dispatch({ type: "PEOPLE_LOADING" });
  Api.post("api/user/sendOtp", payload)
    .then((response) => {
      Notify("success", response.data.message);
      cb && cb();
      // dispatch({ type: "OTP_SENT", payload: true });
      dispatch({ type: "PEOPLE_COMPLETE" });
    })
    .catch((err) => {
      dispatch({ type: "PEOPLE_COMPLETE" });
    })
    .catch(() => Notify("danger", "Something Bad Happened :("));
};

export const verifyOtp = (payload, cb) => (dispatch) => {
  dispatch({ type: "PEOPLE_LOADING" });
  Api.post(`api/user/verifyOtp`, payload)
    .then((response) => {
      dispatch({ type: UPDATE_PROFILE, payload: true });
      dispatch(getProfile());
      dispatch({ type: "PEOPLE_COMPLETE" });
      cb && cb(true);
    })
    .catch((err) => {
      dispatch({ type: "PEOPLE_COMPLETE" });
    })
    .catch(() => Notify("danger", "Something Bad Happened :("));
};

export const fetchLocation = (params) => (dispatch) => {
  return Api.get(`api/user/location?latlan=${params}`);
};

export const updateMsg = (payload) => (dispatch) => {
  dispatch({ type: "UPDATE_PROFILE", payload });
};

export const setGlobalzzId = (payload) => (dispatch) => {
  dispatch({ type: "SET_GLOBAL_ZZID", payload });
};

export const clearGlobalzzId = () => (dispatch) => {
  dispatch({ type: "CLEAR_GLOBAL_ZZID" });
};

export const updateChangeLeaseSuccess = () => (dispatch) => {
  dispatch({ type: "CLEAR_CHANGELEASE_SUCCESS" });
};

export const updateNoticeToVacateSuccess = (payload) => (dispatch) => {
  dispatch({ type: "UPDATE_NOTICETOVACATE_SUCCESS", payload });
};

export const LogoutAction = () => (dispatch) => {
  localStorage.removeItem("flora_token");
  dispatch({ type: "LOGOUT" });
};

export const getProfileWithLeaseAction = (leaseId, id) => (dispatch) => {
  dispatch({type: "NOTICE_VACATE_LOADING_START"});
  Api.get(`api/user/get-profile/${leaseId}/${id}`)
    .then((response) => {
      dispatch({ type: "GET_PROFILE", payload: response.data.data });
      dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
    })
    .catch((error) => {
      Notify("danger", error.message);
      dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
    });
};