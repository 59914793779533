import Api, { baseURL } from "../api/api";
import { Notify } from "../../helpers/notification";
import axios from "axios";

export const OFFICEDASH_DATA = "OFFICEDASH_DATA";
export const LOGIN_STAFF = "LOGIN_STAFF";
export const LOGOUT_STAFF = "LOGOUT_STAFF";

export const GetOfficeDashData = (id) => (dispatch) => {
  return axios({
    method: "get",
    url: `${baseURL.URL}api/office_dashboard/GetOfficeDashData/${id}`,
    headers: {
      "x-auth-token": localStorage.getItem("flora_staff_token"),
    },
  })
    .then((response) => {
      dispatch({ type: OFFICEDASH_DATA, payload: response.data.data });
    })
    .catch((err) => {
      console.log(err && err.response);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const LoginStaffAction = (payload, cb) => (dispatch) => {
  axios({
    method: "post",
    url: `${baseURL.URL}api/officedash/staffLogin`,
    data: payload,
  })
    .then((response) => {
      cb && cb(true);
      localStorage.setItem("flora_staff_token", response.data.data.token);
      dispatch({ type: LOGIN_STAFF });
    })
    .catch((err) => {
      console.log(err && err.response);
      cb &&
        cb(false, err?.response?.data?.errorCode, err?.response?.data?.message);
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const LogoutStaffAction = () => (dispatch) => {
  localStorage.removeItem("flora_staff_token");
  dispatch({ type: LOGOUT_STAFF });
};
