import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { Validation } from '../../helpers/validations';
import { FileNoticeToVacate, FileNoticeToVacateWithoutTokenAction } from '../../redux/actions/noticeToVacate';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { cloneDeep } from 'lodash';
import { withTranslation } from "react-i18next";
class NoticeToVacate extends Component{
  constructor(props){
    super(props);
    this.state = this.emptyState();
    this.sigPad = React.createRef();
  }

  componentDidMount(){
    let { user } = this.props;
    let formdata = {
      ...this.state.formdata,
      name: user.NameFull, 
      location: `${user.leaseDetails["Lease_PROP::Address_Address01_finalDisplay"]} ${user.leaseDetails["Lease_PROP::Number"] || ''}`
    }
    this.setState({ formdata });
  }

  // function to initialize state and empty state
  emptyState(){
    let d = new Date
    let date = d.getDate() === 1 ? new Date(d.getFullYear(), d.getMonth(), 1) : new Date(d.getFullYear(), d.getMonth() + 1, 1)
    return {
      formdata : { date },
      showError: false
    }
  }

  // handles form values
  handleChange = (key, value) => {
    this.setState(state => ({ formdata: {...state.formdata, [key]: value }}));
  }

  // get trimmed image of signature
  getTrim = () => {
    let trimmedUrl = this.sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    let { formdata } = this.state;
    formdata["trimmedUrl"] = trimmedUrl;
    return formdata
  }

  // clears sign Pad
  clearSignPad = () => {
    this.sigPad.current.clear();
    this.setState(state => ({ formdata: {...state.formdata, trimmedUrl: "" }}))
  }

  // submits the form
  handleSubmit = async (event) => {
    let formdata = this.getTrim();
    this.setState({ showError: true, formdata });
    let payload = cloneDeep(formdata);
    payload['zzCreatedByWeb'] = this.props.user.Email_01;
    payload['mobile'] = this.props.user.Phone_Mobile;
    payload['leaseDetails'] = this.props.user.leaseDetails;
    event.preventDefault();
    if(!payload.date || !payload.trimmedUrl) return

    let d = new Date(payload['date']);
    delete payload['date'];
    delete payload.location;
    payload["dateToVacate"] = moment(d).format("MM/DD/YYYY h:mm:ss A").toString();
    this.setState({loading: true });
    if(this.props.withoutToken && this.props.leaseId){
      this.props.FileNoticeToVacateWithoutTokenAction(payload, this.props.leaseId, this.props.onSuccess);
      return;
    }
    this.props.FileNoticeToVacate(payload);
    this.props.onSuccess();
    // this.setState({ showMsg: true });
  }

  render(){
    const { formdata, showError, loading } = this.state;
    const { t } = this.props;
    return(
      <div className="notice-container">
        <div className="form-group">
          <label>{t('modal.notice.legalName')}</label>
          <input 
            className={`form-control form-control-lg ${showError && Validation.empty(formdata.name) ? "is-invalid" : ""}`}
            type="text"
            placeholder="Enter your Name"
            value={formdata.name}
            disabled
            onChange={e=> this.handleChange("name", e.target.value)}
          />
          <h5 className="invalid-feedback">{`${showError && Validation.empty(formdata.name) && "Name is required"}`}</h5>
        </div>
        <div className="form-group">
          <label>{t('modal.notice.address')}</label>
          <textarea
            className={`form-control form-control-lg ${showError && Validation.empty(formdata.location) ? "is-invalid" : ""}`}
            type="text"
            rows="3"
            disabled
            placeholder="Enter your Location"
            value={formdata.location}
            onChange={e=> this.handleChange("location", e.target.value)}
          />
          <h5 className="invalid-feedback">{`${showError && Validation.empty(formdata.location) && "Location is required"}`}</h5>
        </div>
        <label>{t('modal.notice.date')}</label>
        <div className="form-group">
          <DatePicker 
            isClearable={true}
            minDate={new Date()}
            selected={formdata.date}
            onChange={date => this.handleChange("date", date)}
            className="form-control form-control-lg"
          />
          <h5 className="invalid-feedback">{"Date to Vacate is required"}</h5>
        </div>
        <div className="form-group sign-container">
          <label>{t('modal.notice.signature')}</label>
          <div className="border">
            <SignatureCanvas 
              penColor='black'
              backgroundColor="white"
              canvasProps={{width: 500, height: 100, className: 'sigCanvas'}}
              ref={this.sigPad}
              dotSize={1}
              minWidth={1}
              maxWidth={1}
              minDistance={1}
            />
            <span className="float-right">
              <i className="fas fa-redo hover-pointer" onClick={this.clearSignPad}/>
            </span>
          </div>
          <h5 className="invalid-feedback">{'Signature is required'}</h5>
        </div>

        <button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>
          {!loading ? `${t('modal.notice.submit')}` : <i className="fas fa-spinner fa-pulse" />}
        </button>
      </div>
    )
  }
}

const mapStateToProps = ({ USER_REDUCER, NOTICE_VACATE_REDUCER }) => {
  return { user: USER_REDUCER.userData, loading: NOTICE_VACATE_REDUCER.loading }
}

export default connect(mapStateToProps, { FileNoticeToVacate, FileNoticeToVacateWithoutTokenAction })(withTranslation()(NoticeToVacate))