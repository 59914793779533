import Api from "../api/api";
import { Notify } from "../../helpers/notification";

export const SendOtpTwoFActorAuthAction = (payload, cb) => (dispatch) => {
  dispatch({type : 'START_TWO_FACTOR_AUTH_LOADING'})
  Api.post("api/twilio/sent-otp", payload)
    .then((response) => {
      cb && cb(response);
      dispatch({type : 'STOP_TWO_FACTOR_AUTH_LOADING'}) 
      if(response?.data?.data?.status === "pending"){
          Notify("success", response.data.message);
      }else{
        Notify("danger", 'Something Bad Happened');
      } 
    })
    .catch((error) => {
      dispatch({type : 'STOP_TWO_FACTOR_AUTH_LOADING'})  
      Notify("danger", "Something Bad Happened :(");
    });
};


export const VerifyOtpTwoFActorAuthAction = (payload, cb) => (dispatch) => {
    dispatch({type : 'START_TWO_FACTOR_AUTH_LOADING'})
    Api.post("api/twilio/verify-otp", payload)
      .then((response) => {
        dispatch({type : 'STOP_TWO_FACTOR_AUTH_LOADING'}) 
        if(response?.data?.data?.status !== "approved"){
            Notify("danger", "Incorrect code!");
        }else{
            cb && cb(response);
        }
      })
      .catch((error) => {
        dispatch({type : 'STOP_TWO_FACTOR_AUTH_LOADING'})  
        Notify("danger", "Something Bad Happened :(");
      });
  };