import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Validation } from "../../helpers/validations";
import InputMask from "react-input-mask";
import { cardImages, states } from "./constants";
import valid from "card-validator";
import { connect } from "react-redux";
import { handleRecurringPayment } from "../../redux/actions/payments";
import moment from "moment";
import { withTranslation } from "react-i18next";

class ReccurringBillingForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.emptyState();
  }

  componentDidUpdate(prevProps) {
    // function to close modal on successfull submit only
    if (this.props.formSubmitted && !prevProps.formSubmitted) {
      this.props.closeModal();
    }
  }

  // empty the state
  emptyState = () => {
    const { user } = this.props;
    let d = new Date();
    return {
      formdata: {
        address:
          user?.leaseDetails[
          "Lease_PROP::Address_Address01_finalDisplay"
          ],
        zip: user?.leaseDetails["Lease_PROP::Address_PostalCode"],
        state: user?.leaseDetails["Lease_PROP::Address_State"],
        city: user?.leaseDetails["Lease_PROP::Address_City"],
        startDate: new Date(d.getFullYear(), d.getMonth() + 1, "01"),
        amount: user?.leaseDetails?.Rent_Monthly_chargeTenant?.toString(),
        firstName: user?.NameFirst,
        lastName: user?.NameLast,
        email: user?.Email_01,
        addressBillTo: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
        },
      },
      cardImage: cardImages["default"],
      activeStep: 1,
    };
  };

  // handles amount change
  handleAmountChange = (value) => {
    if (value.split(".")[1] || value.split(".")[1] == "") {
      this.setState((prevState) => ({
        formdata: {
          ...prevState.formdata,
          amount:
            value.split(".")[0] +
            "." +
            value
              .slice(value.indexOf("."), value.length)
              .replace(/[^0-9]/g, "")
              .slice(0, 2),
        },
      }));
    } else {
      this.setState((prevState) => ({
        formdata: { ...prevState.formdata, amount: value },
      }));
    }
  };

  // function to validate each form step
  validateStep = () => {
    let {
      amount,
      startDate,
      expirationDate,
      cardNumber,
      cardCode,
      firstName,
      lastName,
      addressBillTo,
    } = this.state.formdata;
    switch (this.state.activeStep) {
      case 1:
        return (
          !Validation.empty(amount.toString()) &&
          !Validation.empty(startDate.toString())
        );
      case 2:
        return (
          !Validation.empty(expirationDate) &&
          !Validation.empty(cardNumber) &&
          !Validation.empty(cardCode) &&
          cardCode.length > 2
        );
      case 3:
        return (
          !Validation.empty(firstName) &&
          !Validation.empty(lastName) &&
          !Validation.empty(addressBillTo.addressLine1) &&
          !Validation.empty(addressBillTo.state) &&
          !Validation.empty(addressBillTo.postalCode) &&
          !Validation.empty(addressBillTo.city) &&
          addressBillTo.postalCode.length < 6
        );
      default:
        return false;
    }
  };

  // handles next button functionality
  handleNext = () => {
    this.setState({ showErrors: true });
    if (!this.validateStep()) return;
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      showErrors: false,
    }));
  };

  // handles previous button functionality
  handlePrev = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
  };

  // handles form values
  handleChange = (value, key) => {
    this.setState((prevState) => ({
      formdata: { ...prevState.formdata, [key]: value },
    }));
  };

  handleAddressChange = (value, key) => {
    this.setState((prevState) => {
      const { addressBillTo } = prevState.formdata;
      addressBillTo[key] = value;
      return addressBillTo;
    });
  };

  // handles card Number and images based cardNumber
  handleCardNumberChange = (value) => {
    let numberValidation = valid.number(value);
    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      this.setState((prevState) => ({
        formdata: { ...prevState.formdata, cardNumber: value },
        cardImage: cardImages[numberValidation.card.type],
      }));
    } else {
      this.setState((prevState) => ({
        formdata: { ...prevState.formdata, cardNumber: value },
        cardImage: cardImages["default"],
      }));
    }
  };

  // submit function
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ showErrors: true });
    if (!this.validateStep()) return;
    let payload = Object.assign({}, this.state.formdata);
    payload.startDate = moment(payload.startDate).format("YYYY-MM-DD");
    this.props.handleRecurringPayment(payload, this.onCompletion);
  };

  onCompletion = (data, bool) => {
    this.props.setRefreshData(!this.props.refreshData);
    this.props.closeModal();
    this.props.setOpenPaymentAlert(true);
    if (bool) {
      this.props.setIsFailed(false);
      this.props.setTransactionResponse(`Your auto billing has been set up to begin on ${moment(this.state.formdata.startDate).format("MM-DD-YYYY")}`);
    } else {
      this.props.setIsFailed(true);
      this.props.setTransactionResponse(data.message);
    }
  }

  render() {
    const { formdata, cardImage, activeStep, showErrors } = this.state;
    const { loading, t } = this.props;
    return (
      <div className="reccurring-form-container">
        <div>
          <h6>
            {activeStep > 0 && `${t('modal.payment.payment-info')}`}{" "}
            {activeStep > 1 && <i className="fas fa-chevron-right" />}
            {activeStep > 1 && `${" "}${t('modal.payment.credit-card')}`}{" "}
            {activeStep > 2 && <i className="fas fa-chevron-right" />}
            {activeStep > 2 && `${" "}${t('modal.payment.billing-contact')}`}
          </h6>
        </div>
        {activeStep === 1 && (
          <>
            {new Date(formdata.startDate).getDate() > 5 && (
              <div className="note note-warning note-with-right-icon p-5">
                <div className="note-icon">
                  <i className="fa fa-lightbulb"></i>
                </div>
                <div className="note-content text-right">
                  <h5>
                    {t('modal.payment.rent-due')}
                  </h5>
                </div>
              </div>
            )}
            <div className="row">
              <label className="col-form-label col-sm-3">{t('modal.payment.amount')}</label>
              <div className="col-sm-9 m-b-10 input-group">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.amount) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.enter-amount')}
                  value={formdata.amount}
                  onChange={(e) =>
                    this.handleAmountChange(
                      e.target.value.replace(/[^0-9|^.]/g, "")
                    )
                  }
                />
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-dollar-sign" />
                  </span>
                </div>
                <div className="invalid-feedback">
                  {showErrors && Validation.empty(formdata.amount)
                    ? `${t('modal.payment.validation.amount')}`
                    : ""}
                </div>
              </div>
            </div>
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.startDate')}</label>
              <div className="col-sm-9">
                <DatePicker
                  selected={formdata.startDate}
                  onChange={(date) => this.handleChange(date, "startDate")}
                  isClearable={true}
                  minDate={new Date()}
                  className={`form-control form-control-lg ${showErrors && !formdata.startDate && "is-invalid"
                    }`}
                />
                <div className="invalid-feedback">
                  {showErrors &&
                    !formdata.startDate &&
                    `${t('modal.payment.validation.startDate')}`}
                </div>
              </div>
            </div>
          </>
        )}
        {activeStep === 2 && (
          <>
            <div className="row">
              <label className="col-form-label col-sm-3">{t('modal.payment.cardno')}</label>
              <div className="col-sm-9 m-b-10 input-group">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.cardNumber) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.cardno')}
                  value={formdata.cardNumber}
                  onChange={(e) =>
                    this.handleCardNumberChange(
                      e.target.value.replace(/[^0-9]/g, "")
                    )
                  }
                />
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ height: 45 }}>
                    <i className={cardImage} />
                  </span>
                </div>
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.cardNumber) &&
                    `${t('modal.payment.validation.startDate')}`}
                </h5>
              </div>
            </div>
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.expiryDate')}</label>
              <div className="col-sm-9">
                <InputMask
                  mask="99/9999"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.expirationDate) &&
                    "is-invalid"
                    }`}
                  value={formdata.expirationDate}
                  placeholder="MM/YYYY"
                  onChange={(e) =>
                    this.handleChange(e.target.value, "expirationDate")
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.expirationDate) &&
                    `${t('modal.payment.validation.expiryDate')}`}
                </h5>
              </div>
            </div>
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">CVV</label>
              <div className="col-sm-9">
                <input
                  type="password"
                  className={`form-control form-control-lg ${showErrors &&
                    (Validation.empty(formdata.cardCode) ||
                      formdata.cardCode.length < 3) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.cardcode')}
                  value={formdata.cardCode}
                  onChange={(e) =>
                    this.handleChange(
                      e.target.value.replace(/[^0-9]/g, ""),
                      "cardCode"
                    )
                  }
                  maxlength="4"
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    (Validation.empty(formdata.cardCode) ||
                      formdata.cardCode.length < 3) &&
                    `${t('modal.payment.validation.cvv')}`}
                </h5>
              </div>
            </div>
          </>
        )}

        {activeStep === 3 && (
          <>
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.firstname')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.firstName) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.firstname')}
                  value={formdata.firstName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "firstName")
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.firstName) &&
                    `${t('modal.payment.validation.firstname')}`}
                </h5>
              </div>
            </div>
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.lastname')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.lastName) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.lastname')}
                  value={formdata.lastName}
                  onChange={(e) =>
                    this.handleChange(e.target.value, "lastName")
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.lastName) &&
                    `${t('modal.payment.validation.lastname')}`}
                </h5>
              </div>
            </div>

            {/* Billing Address */}
            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.addL1')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.addressBillTo.addressLine1) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.addL1')}
                  value={formdata.addressBillTo.addressLine1}
                  onChange={(e) =>
                    this.handleAddressChange(e.target.value, "addressLine1")
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.addressBillTo.addressLine1) &&
                    `${t('modal.payment.validation.addL1')}`}
                </h5>
              </div>
            </div>

            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.addL2v2')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg`}
                  placeholder={t('modal.payment.addL2')}
                  value={formdata.addressBillTo.addressLine2}
                  onChange={(e) =>
                    this.handleAddressChange(e.target.value, "addressLine2")
                  }
                />
              </div>
            </div>

            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">State</label>
              <div className="col-sm-9">
                <select
                  className={`form-control form-control-lg ${showErrors &&
                    !formdata.addressBillTo.state &&
                    "border border-danger"
                    }`}
                  value={formdata.addressBillTo.state}
                  onChange={(e) =>
                    this.handleAddressChange(e.target.value, "state")
                  }
                >
                  <option value="">Select State</option>
                  {states.map((item, i) => (
                    <option value={item.State}>{item.State}</option>
                  ))}
                </select>
                <h5 className="invalid-feedback">
                  {showErrors &&
                    !formdata.addressBillTo.state &&
                    `${t('modal.payment.validation.state')}`}
                </h5>
              </div>
            </div>

            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.postal')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    (Validation.empty(formdata.addressBillTo.postalCode) || formdata.addressBillTo.postalCode.length > 5) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.postal')}
                  value={formdata.addressBillTo.postalCode}
                  onChange={(e) =>
                    this.handleAddressChange(
                      e.target.value.replace(/[^0-9\-]/g, ""),
                      "postalCode"
                    )
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    (Validation.empty(formdata.addressBillTo.postalCode) || formdata.addressBillTo.postalCode.length > 5)
                    && `${t('modal.payment.validation.postal')}`}
                </h5>
              </div>
            </div>

            <div className="row m-b-10">
              <label className="col-form-label col-sm-3">{t('modal.payment.city')}</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className={`form-control form-control-lg ${showErrors &&
                    Validation.empty(formdata.addressBillTo.city) &&
                    "is-invalid"
                    }`}
                  placeholder={t('modal.payment.city')}
                  value={formdata.addressBillTo.city}
                  onChange={(e) =>
                    this.handleAddressChange(e.target.value, "city")
                  }
                />
                <h5 className="invalid-feedback">
                  {showErrors &&
                    Validation.empty(formdata.addressBillTo.city) &&
                    `${t('modal.payment.validation.city')}`}
                </h5>
              </div>
            </div>
          </>
        )}
        <div className="d-flex align-items-center">
          {activeStep !== 1 && (
            <button
              disabled={loading}
              className="btn btn-block btn-success mr-2"
              onClick={this.handlePrev}
            >
              {t('modal.payment.previous')}
            </button>
          )}
          <button
            disabled={loading}
            className="btn btn-block mt-0"
            onClick={(e) =>
              activeStep === 3 ? this.handleSubmit(e) : this.handleNext()
            }
          >
            {!loading ? (
              activeStep === 3 ? (
                `${t('modal.payment.submit')}`
              ) : (
                `${t('modal.payment.next')}`
              )
            ) : (
              <i className="fas fa-spinner fa-pulse" />
            )}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ USER_REDUCER, PAYMENT_REDUCER }) => {
  return {
    user: USER_REDUCER.userData,
    loading: PAYMENT_REDUCER.loading,
    formSubmitted: PAYMENT_REDUCER.formSubmitted,
  };
};

export default connect(mapStateToProps, { handleRecurringPayment })(
  withTranslation()(ReccurringBillingForm)
);