import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import App from "./app.jsx";
import { Router } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { Provider } from "react-redux";
import getStore from "./redux/store";
import { createBrowserHistory } from "history";
import ErrorBoundary from "./pages/Errorboundary";
import { PersistGate } from "redux-persist/integration/react";
import './i18n'

// css
import "react-notifications-component/dist/theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-quill/dist/quill.snow.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./assets/css/custom/index.css";
import "./scss/react.scss";
import "bootstrap-social/bootstrap-social.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const { store, persistor } = getStore;
// ========================================

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactNotification />
        <ErrorBoundary>
        <Suspense fallback={<div>Loading....</div>}>
          <App />
          </Suspense>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root")
);
