import { PEOPLE_UPDATED } from '../actions/people';

const initialState = {
  error: {},
  loading: false,
  peopleDetails: {},
  otpVerified: false,
  optSent: false,
  peopleUpdated: false,
  success: false,
};

const PEOPLE_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "PEOPLE_LOADING": {
      return {
        ...state,
        loading: true,
      };
    }
    case "PEOPLE_COMPLETE": {
      return {
        ...state,
        loading: false,
      };
    }
    case "VIEW_PEOPLE": {
      return {
        ...state,
        loading: false,
        peopleDetails: payload,
      };
    }
    case PEOPLE_UPDATED:{
      return {
        ...state,
        loading: false,
        peopleUpdated: true
      }
    }
    case "UPDATE_PROFILE": {
      return {
        ...state,
        success: payload
      }
    }
    case "CLEAR_UPDATE_PEOPLE":{
      return {
        ...state,
        peopleUpdated: false
      }
    }
    case "CLEAR_PEOPLEDETAILS": {
      return {
        ...state,
        peopleDetails: {},
      };
    }
    case "PEOPLE_ERROR": {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case "OTP_SENT": {
      return {
        ...state,
        otpSent: payload,
      };
    }
    case "OTP_VERIFIED": {
      return {
        ...state,
        otpVerified: payload,
      };
    }
    default:
      return state;
  }
};

export default PEOPLE_REDUCER;
