import React, { Component } from 'react';
import { fetchLocation } from '../../redux/actions/user';
import {connect} from 'react-redux'
class WeatherWidget extends Component{
  state = { location: "" };

  componentDidMount(){
    if("geolocation" in navigator){
      navigator.geolocation.getCurrentPosition((position) => {
        this.props.fetchLocation(`${position.coords.latitude},${position.coords.longitude}`)
        .then(res=>{
          this.setState({ location: res.data.data })
        })
        .catch(err=>{
          console.log(err.response);
        })
      }, ()=> { 
        console.log('denied permission');
        this.setState({ location: '40d92n74d17/paterson' })
      });
    }
  }

  render(){
    const { location } = this.state;
    return(
      <div className="weather-container">
        <a className="weatherwidget-io" href={`https://forecast7.com/en/${location}/?unit=us`} 
          data-label_1={location && location.split('/')[1] && location.split('/')[1].toUpperCase()} data-label_2="WEATHER" data-theme="pure" >
          {location && location.split('/')[1] && location.split('/')[1].toUpperCase()}
        </a>

        {!function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            // if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://weatherwidget.io/js/widget.min.js';
                fjs.parentNode.insertBefore(js, fjs);
            // }
        }
            (document, 'script', 'weatherwidget-io-js')
        }
    </div>
    )
  }
}

export default connect(null, {fetchLocation})(WeatherWidget)