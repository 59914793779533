import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class CancelRecurringList extends Component{
  constructor(props){
    super(props)
    this.state = {
      activeId: ""
    }
  }
  render(){
    const { recurringList, loading, t } = this.props;
    return(
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S.no.</th>
              <th>{t('modal.payment.amount')}</th>
              <th className="text-right">{t('modal.payment.amount')}</th>
            </tr>
          </thead>
          <tbody>
            {recurringList.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>${" "}{item['Lease_SUBC_active::amount'].toFixed(2)}</td>
                <td align="right">
                  <button 
                    disabled={loading}
                    className="btn btn-info btn-xs" 
                    onClick={() => {this.setState({activeId: item.recordId}); this.props.handleCancelPayment(item)}}>
                      {item.recordId === this.state.activeId && loading ? <i className="fas fa-spinner fa-pulse" /> :`${t('modal.payment.cancel')}`}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = ({ USER_REDUCER, PAYMENT_REDUCER }) => {
  return {
    recurringList: USER_REDUCER.userData?.leaseDetails?.subscription,
    loading: PAYMENT_REDUCER.loading
  }
}
export default connect(mapStateToProps, null)(withTranslation()(CancelRecurringList));