
const initialState = {
  userData: {},
  AllLeases: [],
  error: { email: "", password: "" },
  isAuth: false,
  changeleaseSuccess: false,
  loading: false,
};

const USER_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DATA": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        userData: payload.userData,
        AllLeases: payload.AllLeases,
        isAuth: true,
        loading: false,
      };
    }
    case "CHANGE_LEASE": {
      return {
        ...state,
        userData: {
          ...state.userData,
          leaseDetails: payload.leaseDetails,
          activeLease: payload.activeLease,
        },
        changeleaseSuccess: true,
        loading: false,
      };
    }
    case "GET_PROFILE": {
      return {
        ...state,
        userData: {
          ...state.userData,
          ...payload.userData,
        },
        loading: false,
      };
    }
    case "CLEAR_PROFILE": {
      return {
        ...state,
        success: false
      }
    }
    case "USER_ERROR": {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case "CLEAR_CHANGELEASE_SUCCESS": {
      return {
        ...state,
        changeleaseSuccess: false
      }
    }
    case "UPDATE_NOTICETOVACATE_SUCCESS": {
      return {
        ...state,
        noticetoVacateSuccess: payload
      }
    }
    case "UPDATE_RECURRING_PAYMENT": {
      return {
        ...state,
        // userData: { ...state.userData, leaseDetails:{ ...state.userData.leaseDetails, 
        //   subscription: updateSubscriptions(payload, state.userData.leaseDetails.subscription)}}
        userData: updateSubscriptions(payload, state.userData)
      }
    }
    case "NOTICE_VACATE_COMPLETE": {
      return {
        ...state,
        loading: false,
        userData: {...state.userData, leaseDetails: {...state.userData.leaseDetails, isNotticeToVacate: 1}}   
      };
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};

export default USER_REDUCER;

const updateSubscriptions = (id, data) => {
  if(data && id){
    return {
      ...data,
      leaseDetails: { ...data.leaseDetails, 
        subscription: data.leaseDetails.subscription.filter(item => item.recordId !== id) }
    }
  }
  // return data.filter(item => item.recordId !== id )
}
