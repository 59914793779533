import React, { useEffect, memo, useState, useRef } from "react";
import { errors, spanishErrors } from "./constant";
import { connect, useDispatch } from "react-redux";
import { Validation } from "../../helpers/validations";
import { getDashboardData } from "../../redux/actions/user";
import InputMask from "react-input-mask";
import WrapperComponent from "../wrapperComponent";
import { AddPeople, EditPeople } from "../../redux/actions/people";
import { Notify } from "../../helpers/notification.js";
import { useTranslation } from 'react-i18next';

const PeopleForm = memo(
  ({ loading, user = {}, closeModal, item, edit, AddPeople, EditPeople, peopleUpdated }) => {
    const [error, setError] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const imgRef = useRef();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
      firstName: "", lastName: "", Mobile: "", Email: "", Email2: "", photo: "", Photo_preview: ""
    });
    const { t } = useTranslation();
    
    useEffect(() => {
      if(peopleUpdated){
        dispatch(getDashboardData());
        dispatch({ type: "CLEAR_UPDATE_PEOPLE" });
        closeModal()
      }
    },[peopleUpdated])

    useEffect(() => {
      if(edit && formData.photo){
        let { photo } = formData;
        const Form = new FormData();
        Form.append("zzModifiedByWeb", user.Email_01);
        photo && Form.append("Photo", photo);
        let zzid = item["zzID"];
        EditPeople(zzid, Form, closeModal);
      }
    },[formData.photo])

    useEffect(() => {
      if (item.NameFirst) {
        let { NameFirst, NameLast, Phone_Mobile, Email_01, Email_02, Photo_Thumbnail } = item;
        setFormData({
          firstName: NameFirst,
          lastName: NameLast,
          Mobile: Phone_Mobile,
          Email: Email_01,
          Email2: Email_02,
          Photo_preview: Photo_Thumbnail,
        });
      }
    }, [item]);

    const handleChange = (e, name) => {
      setFormData({ ...formData, [name]: e.target.value });
    };

    const handleShowMessage=()=>{
      setFormSubmitted(true)
    }

    // function which handles image both heic & jpeg etc
    const handleImage = (file) => {
      if (file && file.type.split("/")[0] === "image") {
        let reader = new FileReader();
        reader.onloadend = () => {
          let imagePreviewUrl = URL.createObjectURL(file);
          setFormData({ ...formData, Photo_preview: imagePreviewUrl, photo: file });
          imgRef.current.value = null;
        };
        reader.readAsDataURL(file);
      }
    };

    // validator function
    const handleValidation = (type) => {
      let lang = localStorage.getItem('i18nextLng') === "es" ? true : false
      if (type == "lastName") {
        if (!Validation.name(formData.lastName)) {
          setError({ ...error, lastName: lang ? spanishErrors.lastName : errors.lastName });
          return false;
        }
      } else if (type == "firstName") {
        if (!Validation.name(formData.firstName)) {
          setError({ ...error, firstName: lang ? spanishErrors.firstName : errors.firstName });
          return false;
        }
      } else if (type == "Email") {
        if (!Validation.email(formData.Email)) {
          setError({ ...error, Email: lang ? spanishErrors.Email : errors.Email });
          return false;
        }
      } else if (type == "Mobile") {
        if (Validation.empty(formData.Mobile)) {
          setError({ ...error, Mobile: lang ? spanishErrors.Mobile : errors.Mobile });
          return false;
        }
      }
      return true;
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      let fields = edit ? ["firstName", "lastName", "Mobile", "Email"] : ["firstName", "lastName"];
      let isvalid = false;
      for (let i = 0; i < fields.length; i++) {
        isvalid = handleValidation(fields[i]);
        if (!isvalid) break;
      }
      if (!isvalid) return;

      let { Email, Email2, Mobile, firstName, lastName, photo, Photo_preview } = formData;
      const Form = new FormData();
      Form.append("NameFirst", firstName);
      Form.append("NameLast", lastName);
      Mobile && Form.append("Phone_Mobile", Mobile);
      Email && Form.append("Email_01", Email);
      Email2 && Form.append("Email_02", Email2);
      Form.append("zzModifiedByWeb", user.Email_01);
      Photo_preview && photo && Form.append("Photo", photo);
      let zzid = item["zzID"];
      edit ? EditPeople(zzid, Form, closeModal) : AddPeople(Form, handleShowMessage);
    };

    return (
      <section className="peopleform-container">
        {peopleUpdated && (
          Notify("success", `${formData.firstName} Updated Successfully!`)
        )}
        {formSubmitted ? (
          <div>
            <div
              className={`note ${"bg-lime-transparent-5"} note-with-right-icon`}
            >
              <div className="note-icon">
                <i className="fa fa-user"></i>
              </div>
              <div className="note-content text-right">
                <h5>
                  {formData.firstName} {t('modal.people.form-text1')}{" "}
                  {formData.firstName} {t('modal.people.form-text1-1')}
                </h5>
                <h6>
                  {t('modal.people.form-text2')} {formData.firstName} {t('modal.people.form-text2-1')}
                </h6>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="form-group m-b-10 d-flex justify-content-center">
              <div className="user-img" style={{ position: "relative" }}>
                <input
                  type="file"
                  className="d-none"
                  accept="image/*"
                  ref={imgRef}
                  onChange={(e) => handleImage(e.target.files[0])}
                />
                <img
                  className="profile-pic"
                  src={formData?.Photo_preview || "/assets/img/user/user.png"}
                  alt="ProfilePic"
                  height="100"
                />
                <span className="edit-pencil">
                  {!loading ? <i
                    className="fa fa-pencil-alt fa-fw"
                    onClick={(e) => imgRef.current.click()}
                  /> :
                  <i className="fas fa-spinner fa-pulse"/>
                  }
                </span>
              </div>
            </div>
            <div className="form-group m-b-10">
              <input
                type="text"
                className={`form-control form-control-lg ${ error.firstName && "is-invalid" }`}
                placeholder={t('modal.people.firstname')}
                value={formData.firstName}
                onChange={(e) => handleChange(e, "firstName")}
                onBlur={(e) => handleValidation("firstName")}
                onFocus={(e) => setError({ ...error, firstName: "" })}
                disabled={true}
              />
              <h5 className="invalid-feedback">{error.firstName}</h5>
            </div>
            <div className="form-group m-b-10">
              <input
                type="text"
                className={`form-control form-control-lg ${ error.lastName && "is-invalid" }`}
                placeholder={t('modal.people.lastname')}
                value={formData.lastName}
                onChange={(e) => handleChange(e, "lastName")}
                onBlur={(e) => handleValidation("lastName")}
                onFocus={(e) => setError({ ...error, lastName: "" })}
                disabled={true}
              />
              <h5 className="invalid-feedback">{error.lastName}</h5>
            </div>

            <div className="form-group m-b-10">
              <InputMask
                mask="(999) 999-9999"
                className="form-control"
                placeholder="(999) 999-9999"
                value={formData.Mobile}
                onChange={(e) => {
                  handleChange(e, "Mobile");
                }}
                onBlur={(e) => handleValidation("Mobile")}
                onFocus={(e) => setError({ ...error, Mobile: "" })}
              />
              <h5 className="invalid-feedback">{error.Mobile}</h5>
            </div>

            <div className="form-group m-b-10">
              <input
                type="email"
                className={`form-control form-control-lg ${
                  error.Email && "is-invalid"
                }`}
                placeholder="Email"
                value={formData.Email}
                onChange={(e) => handleChange(e, "Email")}
                onBlur={(e) => handleValidation("Email")}
                onFocus={(e) => setError({ ...error, Email: "" })}
              />
              <h5 className="invalid-feedback">{error.Email}</h5>
            </div>

            <div className="form-group m-b-10">
              <input
                type="email"
                className={`form-control form-control-lg ${
                  error.Email2 && "is-invalid"
                }`}
                placeholder={`Email ${t('modal.people.secondary')}`}
                value={formData.Email2}
                onChange={(e) => handleChange(e, "Email2")}
                onBlur={(e) => handleValidation("Email2")}
                onFocus={(e) => setError({ ...error, Email2: "" })}
              />
              <h5 className="invalid-feedback">{error.Email2}</h5>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-warning btn-block m-t-10"
              onClick={handleSubmit}
            >
              {!loading ? `${t('modal.people.save')}` : <i className="fas fa-spinner fa-pulse"/>}
            </button>
          </>
        )}
      </section>
    );
  }
);

const maptypeToProps = ({ PEOPLE_REDUCER, USER_REDUCER }) => {
  return {
    loading: PEOPLE_REDUCER.loading, user: USER_REDUCER.userData, item: PEOPLE_REDUCER.peopleDetails,
    peopleUpdated: PEOPLE_REDUCER.peopleUpdated,
  };
};

const PeopleWrapper = (props) => (
  <WrapperComponent>
    <PeopleForm {...props} />
  </WrapperComponent>
);
export default connect(maptypeToProps, { AddPeople, EditPeople })(
  PeopleWrapper
);
