import React, { Component } from "react";
import { connect } from "react-redux";
import { sendOtp, verifyOtp } from "../../redux/actions/user";
import InputMask from "react-input-mask";
import { updateProfile } from "../../redux/actions/user";
import { Validation } from "../../helpers/validations";
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from "../../components/panel/panel.jsx";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Stepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      // otpSent: false,
      otp: "",
      Email: "",
      phone: "",
      currentPassword: "",
      confirmPassword: "",
      newPassword: "",
      showErrors: false,
      fancyValidation: {
        eightCharacters: false,
        oneNumber: false,
        oneCapitalLetter: false,
        oneLowerLetter: false,
        oneSpecialCharacter: false,
      },
      type: this.props.type,
    };
    this.checkFancyValidation = this.checkFancyValidation.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    // if (prevProps.otpSent !== otpSent) {
    //   this.setState({ otpSent, activeStep: 2 });
    // }
    if (prevProps.type !== type) {
      this.setState({ type });
    }
  }

  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };

  // function to send otp
  handleSendOtp = (e) => {
    e.stopPropagation();
    this.setState({ showErrors: true });
    let { Email, phone, type } = this.state;
    if (
      (type === "Email_01" || type === "Email_02") &&
      !Validation.email(Email)
    )
      return;
    if (type === "phone" && Validation.empty(phone)) return;
    let payload = {
      [type]: Email || undefined,
      Phone_Mobile: phone || undefined,
    };
    this.props.sendOtp(payload, this.handleActiveStep);
    this.setState({ showErrors: false });
  };

  handleActiveStep = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }));
  };

  checkFancyValidation = (value) => {
    let obj = Validation.fancyValidation(value);
    this.setState({ fancyValidation: obj, newPassword: value });
  };

  handleVerifyOtp = (e) => {
    e.stopPropagation();
    this.setState({ showErrors: true });
    let { Email, otp, phone, type } = this.state;
    if (Validation.empty(otp)) return;
    let payload = {
      [type]: Email || undefined,
      Phone_Mobile: phone || undefined,
      otp: otp || undefined,
    };
    this.props.verifyOtp(payload, this.onSuccess);
  };

  onSuccess = (bool) => {
    if (bool) {
      this.props.onCancel(true);
    }
  };

  handlePasswordUpdate = () => {
    // !this.state.showErrors && this.setState({ showErrors: true });
    let { currentPassword, newPassword, confirmPassword } = this.state;
    if (newPassword !== confirmPassword) return;

    const formdata = new FormData();
    currentPassword && formdata.append("currentPassword", currentPassword);
    newPassword && formdata.append("newPassword", newPassword);
    confirmPassword && formdata.append("confirmPassword", confirmPassword);
    this.props.updateProfile(formdata, this.onSuccess);
  };

  handlePrevious = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleNext = () => {
    let { currentPassword, newPassword } = this.state;
    this.setState({ showErrors: true });
    switch (this.state.activeStep) {
      case 1:
        if (Validation.empty(currentPassword)) return;
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
          showErrors: false,
        }));
        return;
      case 2:
        if (!Validation.password(newPassword)) return;
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
          showErrors: false,
        }));
        return;
      default:
        this.handlePasswordUpdate();
        return;
    }
  };

  render() {
    const {
      type,
      currentPassword,
      newPassword,
      confirmPassword,
      Email,
      phone,
      showErrors,
      fancyValidation,
      activeStep,
      otp,
    } = this.state;
    const { loading, t } = this.props;
    return (
      <>
        <div className="row stepper-head mr-0 ml-0">
          <div
            className={`${
              type === "password" ? "col-sm-4" : "col-sm-6"
            } custom-nav-tab d-flex ${activeStep == 1 && "active-tab"}`}
            // onClick={(e) => this.setState({ activeStep: 1, otpSent: false })}
          >
            <div className="stepper-pill">1</div>
            &nbsp;
            {type !== "password" ? (
              <span className="info">
                {t("stepper.heading1")}
                <br />
                <small>{t("stepper.subheading1")}</small>
              </span>
            ) : (
              <span className="info">{t("stepper.heading2")}</span>
            )}
          </div>
          <div
            className={`${
              type === "password" ? "col-sm-4" : "col-sm-6"
            } custom-nav-tab d-flex ${activeStep == 2 && "active-tab"}`}
            // onClick={(e) => otpSent && this.setState({ activeStep: 2 })}
          >
            <div className="stepper-pill">3</div>
            &nbsp;
            {type !== "password" ? (
              <span className="info">
                {t("stepper.heading3")}
                <br />
                <small>{t("stepper.subheading2")}</small>
              </span>
            ) : (
              <span className="info">{t("stepper.heading4")}</span>
            )}
          </div>
          {type === "password" && (
            <div
              className={`col-sm-4 custom-nav-tab d-flex ${
                activeStep == 3 && "active-tab"
              }`}
              // onClick={(e) => otpSent && this.setState({ activeStep: 3 })}
            >
              <div className="stepper-pill">3</div>
              &nbsp;
              <span className="info">{t("stepper.heading5")}</span>
            </div>
          )}
        </div>
        <div className="row stepper-body align-items-center justify-content-center">
          <div
            id="step-1"
            className="col-xl-7 col-lg-8 col-sm-9 d-flex flex-row justify-content-center"
          >
            {activeStep === 1 &&
              (type === "Email_01" || type === "Email_02") && (
                <div className="row justify-content-center">
                  <div className="col-11">
                    <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-center">
                      {t("stepper.enterEmail")}
                    </legend>
                  </div>

                  <div className="col-lg-10 col-11">
                    <div className="form-inline w-100">
                      <label className="">{t("stepper.email")}</label>
                      <input
                        type="email"
                        name="email"
                        className={`form-control ${
                          showErrors && !Validation.email(Email) && "is-invalid"
                        }`}
                        value={Email}
                        onChange={(e) =>
                          this.handleChange(e.target.value, "Email")
                        }
                        placeholder="someone@example.com"
                        style={{ flexGrow: 1 }}
                      />
                      <div className="invalid-feedback text-right">
                        {showErrors && !Validation.email(Email)
                          ? `${t("stepper.validation.email")}`
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {activeStep === 1 && type === "phone" && (
              <div className="row justify-content-center">
                <div className="col-11">
                  <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-center">
                    {t("stepper.enterPhoneno")}
                  </legend>
                </div>
                <div className="col-lg-10 col-11">
                  <div className="form-inline w-100">
                    <label className="">{t("stepper.phoneno")}</label>
                    <InputMask
                      mask="(999) 999-9999"
                      className={`form-control ${
                        showErrors && Validation.empty(phone) && "is-invalid"
                      }`}
                      placeholder="(999) 999-9999"
                      value={phone}
                      onChange={(e) =>
                        this.handleChange(e.target.value, "phone")
                      }
                      style={{ flexGrow: 1 }}
                    />
                    <div className="invalid-feedback text-right">
                      {showErrors && Validation.empty(phone)
                        ? `${t("stepper.validation.phoneno")}`
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type === "password" && activeStep === 1 && (
              <div className="row justi-content-center">
                <div className="col-12">
                  <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16">
                    {t("stepper.changePass")}
                  </legend>
                </div>
                <div className="col-12">
                  <div className={`form-inline w-100`}>
                    <label className="">{t("stepper.currentPass")}</label>

                    <input
                      type="password"
                      className={`form-control ${
                        showErrors &&
                        Validation.empty(currentPassword) &&
                        "is-invalid"
                      }`}
                      placeholder={t("stepper.currentPass")}
                      value={currentPassword}
                      onChange={(e) =>
                        this.handleChange(e.target.value, "currentPassword")
                      }
                      style={{ flexGrow: 1 }}
                    />
                    <div className="invalid-feedback text-right">
                      {showErrors && Validation.empty(currentPassword)
                        ? `${t("stepper.validation.currPassword")}`
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type === "password" && activeStep === 2 && (
              <div className="row justify-content-center">
                <div className="col-sm-10 col-lg-8 col-xs-11">
                  <div className="form-inline w-100">
                    <label className="">{t("stepper.newPass")}</label>

                    <input
                      type="password"
                      className={`form-control ${
                        showErrors &&
                        !Validation.password(newPassword) &&
                        "is-invalid"
                      }`}
                      placeholder={t("stepper.newPass")}
                      value={newPassword}
                      onChange={(e) =>
                        this.checkFancyValidation(e.target.value)
                      }
                      style={{ flexGrow: 1 }}
                    />
                    <div className="invalid-feedback text-right">
                      {showErrors && !Validation.password(newPassword)
                        ? `${t("stepper.validation.newPassword")}`
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="checkbox checkbox-css d-flex justify-content-start col-sm-10 col-lg-8 col-xs-11 mt-3">
                  <input
                    type="checkbox"
                    id="cssCheckbox1"
                    checked={fancyValidation.eightCharacters}
                  />
                  <label htmlFor="cssCheckbox1">
                    {t("stepper.password.check1")}
                  </label>
                </div>
                <div className="checkbox checkbox-css d-flex justify-content-start col-sm-10 col-lg-8 col-xs-11">
                  <input
                    type="checkbox"
                    id="cssCheckbox1"
                    checked={fancyValidation.oneCapitalLetter}
                  />
                  <label htmlFor="cssCheckbox1">
                    {t("stepper.password.check2")}
                  </label>
                </div>{" "}
                <div className="checkbox checkbox-css d-flex justify-content-start col-sm-10 col-lg-8 col-xs-11">
                  <input
                    type="checkbox"
                    id="cssCheckbox1"
                    checked={fancyValidation.oneLowerLetter}
                  />
                  <label htmlFor="cssCheckbox1">
                    {t("stepper.password.check3")}
                  </label>
                </div>{" "}
                <div className="checkbox checkbox-css d-flex justify-content-start col-sm-10 col-lg-8 col-xs-11">
                  <input
                    type="checkbox"
                    id="cssCheckbox1"
                    checked={fancyValidation.oneNumber}
                  />
                  <label htmlFor="cssCheckbox1">
                    {t("stepper.password.check4")}
                  </label>
                </div>
                <div className="checkbox checkbox-css d-flex justify-content-start col-sm-10 col-lg-8 col-xs-11">
                  <input
                    type="checkbox"
                    id="cssCheckbox1"
                    checked={fancyValidation.oneSpecialCharacter}
                  />
                  <label htmlFor="cssCheckbox1">
                    {t("stepper.password.check5")} ({"!@#$%&^?|"})
                  </label>
                </div>
              </div>
            )}
            {type === "password" && activeStep === 3 && (
              <div className="row justify-content-center">
                <div className="col-sm-12">
                  <div className="form-inline w-100">
                    <label className="">{t("stepper.confirmPass")}</label>
                    <input
                      type="password"
                      className={`form-control ${
                        showErrors &&
                        newPassword !== confirmPassword &&
                        "is-invalid"
                      }`}
                      placeholder={t("stepper.confirmPass")}
                      value={confirmPassword}
                      onChange={(e) =>
                        this.handleChange(e.target.value, "confirmPassword")
                      }
                      style={{ flexGrow: 1 }}
                    />
                    <div className="invalid-feedback text-right">
                      {showErrors && newPassword !== confirmPassword
                        ? `${t("stepper.validation.matchPass")}`
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeStep === 2 && type !== "password" && (
              <div className="row justify-content-center">
                <div className="col-11">
                  <legend className="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-center">
                    {t("stepper.enterCode-1")}{" "}
                    {type === "phone" ? phone : Email}
                    {/* {t("stepper.enterCode-2")} */}
                  </legend>
                </div>
                <div className="col-lg-10 col-11">
                  <div className="form-inline w-100">
                    <label className="">{t("stepper.code")}</label>
                    <input
                      type="password"
                      style={{ flexGrow: 1 }}
                      name="otp"
                      className={`form-control ${
                        showErrors && Validation.empty(otp) && "is-invalid"
                      }`}
                      value={otp}
                      onChange={(e) => this.handleChange(e.target.value, "otp")}
                    />
                    <div className="invalid-feedback text-right">
                      {showErrors && Validation.empty(otp)
                        ? `${t("stepper.validation.code")}`
                        : ""}
                    </div>
                    <Link to="#" onClick={this.handleSendOtp} className="mt-2">
                      {t("stepper.resend")}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row justify-content-end mb-3 ml-0 mr-0">
          {activeStep !== 1 && (
            <div className="col-sm-3 col-lg-2">
              <button
                onClick={this.handlePrevious}
                disabled={loading}
                type="submit"
                className="btn btn-secondary w-100"
              >
                {!loading ? (
                  `${t("stepper.back")}`
                ) : (
                  <i className="fas fa-spinner fa-pulse" />
                )}
              </button>
            </div>
          )}
          {type !== "password" && (
            <div className="col-sm-3 col-lg-2 mt-2 mt-sm-0">
              <button
                onClick={
                  activeStep === 1 ? this.handleSendOtp : this.handleVerifyOtp
                }
                disabled={loading}
                type="submit"
                className="btn btn-primary w-100"
              >
                {!loading ? (
                  activeStep === 1 ? (
                    `${t("stepper.sendCode")}`
                  ) : (
                    `${t("stepper.submit")}`
                  )
                ) : (
                  <i className="fas fa-spinner fa-pulse" />
                )}
              </button>
            </div>
          )}
          {type === "password" && (
            <div className="col-sm-3 col-lg-2 mt-2 mt-sm-0">
              <button
                onClick={this.handleNext}
                disabled={loading}
                type="submit"
                className="btn btn-primary w-100"
              >
                {!loading ? (
                  activeStep === 3 ? (
                    `${t("stepper.submit")}`
                  ) : (
                    `${t("stepper.next")}`
                  )
                ) : (
                  <i className="fas fa-spinner fa-pulse" />
                )}
              </button>
            </div>
          )}
          <div className="col-sm-3 col-lg-2 mt-2 mt-sm-0">
            <button
              onClick={this.props.onCancel}
              className="btn btn-white w-100"
            >
              {t("stepper.cancel")}
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ PEOPLE_REDUCER }) => {
  return {
    // otpSent: PEOPLE_REDUCER.otpSent,
    loading: PEOPLE_REDUCER.loading,
  };
};

export default connect(mapStateToProps, { sendOtp, verifyOtp, updateProfile })(
  withTranslation()(Stepper)
);
