import { OFFICEDASH_DATA } from '../actions/officeDash'

const initialState = {
    'isStaffAuth': false,
    'month': {},
    'lastWeek': {},
    'weekToDate': {},
    'sevenDays': {},
    'today': {},
    'survey': {}
};

const OFFICEDASH_REDUCER = (state = initialState, { type, payload }) => {
    switch (type) {
        case "LOGIN_STAFF": {
            return {
              ...state,
              isStaffAuth: true,
            };
        }
        case OFFICEDASH_DATA: {
            return {
                ...state,
                ...payload
            }
        }
        case "LOGOUT_STAFF": {
            return initialState;
        }
        default: 
            return state;
    }
}

export default OFFICEDASH_REDUCER;