import React from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import routes from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
import PrivateRoute from "../../config/protectedRoute";
import StaffRoute from "../../config/staffRoute";
import WeatherWidget from '../../pages/WeatherWidget';

function setTitle(path, routeArray) {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      if (routeArray[i].path === "/officedash" || routeArray[i].path === "/login") {
        pageTitle = "Ania Management | " + routeArray[i].title;
      } else {
        pageTitle = "Ania Management | " + routeArray[i].title;
      }
    }
  }
  document.title = pageTitle ? pageTitle : "Ania Management";
}

class Content extends React.Component {
  componentDidMount() {
    setTitle(this.props.history.location.pathname, routes);
  }
  componentWillMount() {
    this.props.history.listen(() => {
      setTitle(this.props.history.location.pathname, routes);
    });
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({
          pageContentFullWidth,
          pageContentClass,
          pageContentInverseMode,
        }) => (
          <div
            className={
              "content " +
              (pageContentFullWidth ? "content-full-width " : "") +
              (pageContentInverseMode ? "content-inverse-mode " : "") +
              pageContentClass
            }
          >
            {/* <div className={this.props.location.pathname !== "/dashboard" ? "d-none" : ""}>
              <WeatherWidget />
              <br/>
            </div> */}
              <Switch>
                {routes.map((route, index) =>
                  route.protected ? (
                    <PrivateRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ) : 
                  route.isStaff ? (
                    <StaffRoute
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ) 
                  : (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  )
                )}
              </Switch>
          </div>
        )}
      </PageSettings.Consumer>
    );
  }
}

export default withRouter(Content);
