import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageSettings } from "../../config/page-settings";
import { useParams } from "react-router-dom";
import { GetTicketUnsecuredAction } from "../../redux/actions/maintainance";
import { useDispatch } from "react-redux";
import moment from "moment";
import logo from "./../../assets/img/logo/AniaLogo-full.svg";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";

const NotSecureMaintainance = () => {
  const context = useContext(PageSettings);
  const { t, i18n } = useTranslation();
  const { record_id } = useParams();
  const dispatch = useDispatch();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [ticketDetails, setTicketDetails] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") === "es" ? "Spanish" : "English"
  );

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
    dispatch(
      GetTicketUnsecuredAction(record_id, (event) => {
        setTicketDetails(event);
      })
    );
  }, []);

  const toggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const statusTranslation = (text) => {
    return text === "New"
      ? t("maintenance-status.new")
      : text === "Verified"
      ? t("maintenance-status.verified")
      : text === "Verified By Tenant"
      ? t("maintenance-status.verified-tenant")
      : text === "Closed"
      ? t("maintenance-status.closed")
      : text === "Scheduled"
      ? t("maintenance-status.scheduled")
      : text === "Re-Opened by Tenant"
      ? t("maintenance-status.re-opened")
      : text === "Scheduled - Confirmed"
      ? t("maintenance-status.scheduled-confirmed")
      : text === "Canceled"
      ? t("maintenance-status.canceled")
      : text;
  };

  const categoryTranslation = (text) => {
    return text === "Cleaning"
      ? t("maintenance-category.cleaning")
      : text === "Plumbing"
      ? t("maintenance-category.plumbing")
      : text === "Extermination"
      ? t("maintenance-category.extermination")
      : text === "Electricity"
      ? t("maintenance-category.electricity")
      : text === "Scheduled"
      ? t("maintenance-category.scheduled")
      : text === "Carpentry"
      ? t("maintenance-category.carpentry")
      : text === "Landscaping"
      ? t("maintenance-category.landscaping")
      : text;
  };

  const subCategoryTranslation = (text) => {
    return text === "Leaks"
      ? t("maintenance-subCategory.leaks")
      : text === "Air Conditioning"
      ? t("maintenance-subCategory.ac")
      : text === "Heat"
      ? t("maintenance-subCategory.heat")
      : text === "Front Yard"
      ? t("maintenance-subCategory.frontYard")
      : text === "Back Yard"
      ? t("maintenance-subCategory.backYard")
      : text === "Side Yard"
      ? t("maintenance-subCategory.sideYard")
      : text === "Rats/Mice"
      ? t("maintenance-subCategory.rat")
      : text === "Cockroaches"
      ? t("maintenance-subCategory.cockroaches")
      : text === "Ants"
      ? t("maintenance-subCategory.ants")
      : text === "Doors"
      ? t("maintenance-subCategory.doors")
      : text === "Walls"
      ? t("maintenance-subCategory.walls")
      : text === "Ceiling"
      ? t("maintenance-subCategory.ceiling")
      : text === "Lights"
      ? t("maintenance-subCategory.lights")
      : text === "Sockets"
      ? t("maintenance-subCategory.sockets")
      : text === "Entryway"
      ? t("maintenance-subCategory.entryway")
      : text === "Hallway"
      ? t("maintenance-subCategory.hallway")
      : text === "Trash Area"
      ? t("maintenance-subCategory.trash")
      : text === "Large Debris"
      ? t("maintenance-subCategory.debris")
      : text;
  };

  const whereListTranslation = (text) => {
    return text === "Bedroom"
      ? t("maintenance-where.bedroom")
      : text === "Bathroom"
      ? t("maintenance-where.bathroom")
      : text === "Kitchen"
      ? t("maintenance-where.kitchen")
      : text === "Living Room"
      ? t("maintenance-where.living-room")
      : text === "Hallway"
      ? t("maintenance-where.hallway")
      : text === "Whole Unit"
      ? t("maintenance-where.whole-unit")
      : text;
  };

  return (
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col-sm-4 col-xl-3 col-12">
          <img src={logo} alt="" />
        </div>
        <div className="col-sm-2 col-xl-2 col-12 pt-2 pt-sm-0 d-flex align-items-center justify-content-end">
          <Dropdown
            isOpen={dropDownOpen}
            toggle={toggle}
            className="dropdown navbar-languager"
          >
            <DropdownToggle className="dropdown-toggle" tag="a">
              <span
                className={`flag-icon ${
                  selectedLanguage === "Spanish"
                    ? `flag-icon-es`
                    : `flag-icon-us`
                } m-r-5`}
              ></span>
              <span className="name d-none d-sm-inline">
                {selectedLanguage}
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right">
              <DropdownItem
                onClick={(e) => {
                  setSelectedLanguage("English");
                  i18n.changeLanguage("en");
                }}
              >
                <span className="flag-icon flag-icon-us m-r-5"></span> English
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setSelectedLanguage("Spanish");
                  i18n.changeLanguage("es");
                }}
              >
                <span className="flag-icon flag-icon-es m-r-5"></span> Spanish
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      {!ticketDetails?.fieldData && !ticketDetails?.portalData && (
        <div
          className="row justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <span className="spinner-grow text-primary"></span>
        </div>
      )}

      {ticketDetails && ticketDetails.fieldData ? (
        <div className="card mt-5">
          <h5 className="card-header">Details</h5>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Ticket Number</b>
                </p>
                <p>{ticketDetails.fieldData["zzIDFriendly"] || "-"}</p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Category</b>
                </p>
                <p>
                  {categoryTranslation(ticketDetails.fieldData["Type"] || "-")}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Sub Category</b>
                </p>
                <p>
                  {subCategoryTranslation(
                    ticketDetails.fieldData["SubType"] || "-"
                  )}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Where</b>
                </p>
                <p>
                  {whereListTranslation(
                    ticketDetails.fieldData["SubTypeWhere"] || "-"
                  )}
                </p>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <p>
                  <b>Status</b>
                </p>
                <p>
                  {statusTranslation(ticketDetails.fieldData["Status"] || "-")}
                </p>
              </div>
              <div className="col-12">
                <p>
                  <b>Description</b>
                </p>
                <p>{ticketDetails.fieldData["Description_Web"] || "-"}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {ticketDetails && ticketDetails.portalData ? (
        <>
          <div className="card mt-5">
            <h5 className="card-header">Images</h5>
            <div className="card-body">
              <div className="row">
                {ticketDetails.portalData.Maintenance_NOTE_Images &&
                ticketDetails.portalData.Maintenance_NOTE_Images.length
                  ? ticketDetails.portalData.Maintenance_NOTE_Images.map(
                      (x) => {
                        return (
                          <div className="col-6 col-sm-4 col-md-3">
                            <a
                              href={`/gallery?${x["Maintenance_NOTE_Images::ArchiveURL"]}`}
                              target="_blank"
                            >
                              <img
                                src={
                                  x["Maintenance_NOTE_Images::ArchiveURL_thumb"]
                                }
                                className="img-thumbnail"
                              />
                            </a>
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            </div>
          </div>

          <div className="card mt-5">
            <h5 className="card-header">Notes</h5>
            <div className="card-body">
              <div className="widget-chat widget-chat-rounded">
                <div
                  className="widget-chat-body"
                  data-scrollbar="true"
                  data-height="235px"
                >
                  {ticketDetails.portalData.Maintenance_NOTE_text &&
                    ticketDetails.portalData.Maintenance_NOTE_text.length &&
                    ticketDetails.portalData.Maintenance_NOTE_text.map(
                      (item, i) =>
                        !item["Maintenance_NOTE_text::isAddedViaWeb"] ? (
                          <div
                            className="widget-chat-item with-media left"
                            key={i}
                          >
                            <div className="widget-chat-media">
                              <img
                                alt="alt-img"
                                src="/assets/img/logo/info.png"
                              />
                            </div>
                            <div className="widget-chat-info">
                              <div className="widget-chat-info-container">
                                <div className="widget-chat-name text-indigo">
                                  {
                                    item[
                                      "Maintenance_NOTE_text::zzCreatedByDisplay"
                                    ]
                                  }
                                </div>
                                <div className="widget-chat-message">
                                  {item["Maintenance_NOTE_text::Note_c"]}
                                </div>
                                <div
                                  className="widget-chat-time"
                                  style={{ position: "block" }}
                                >
                                  {moment(
                                    Date.parse(
                                      item[
                                        "Maintenance_NOTE_text::zzCreatedTS"
                                      ],
                                      "MM/dd/yyyy hh:mm"
                                    )
                                  ).format("MM/DD/YYYY hh:mm A")}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="widget-chat-item right" key={i}>
                            <div className="widget-chat-info">
                              <div className="widget-chat-info-container">
                                <div className="widget-chat-name text-indigo">
                                  {
                                    item[
                                      "Maintenance_NOTE_text::zzCreatedByDisplay"
                                    ]
                                  }
                                </div>
                                <div className="widget-chat-message">
                                  {item["Maintenance_NOTE_text::Note_c"]}
                                </div>
                                <div className="widget-chat-time">
                                  {moment(
                                    Date.parse(
                                      item[
                                        "Maintenance_NOTE_text::zzCreatedTS"
                                      ],
                                      "MM/dd/yyyy hh:mm"
                                    )
                                  ).format("MM/DD/YYYY hh:mm A")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NotSecureMaintainance;
