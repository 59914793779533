import Api from "../api/api";
import { Notify } from "../../helpers/notification";

const NOTICE_VACATE_LOADING = () => {
  return { type: "NOTICE_VACATE_LOADING" };
};

const NOTICE_VACATE_COMPLETE = () => {
  return { type: "NOTICE_VACATE_COMPLETE" };
};

const NOTICE_VACATE_ERROR = () => {
  return { type: "NOTICE_VACATE_ERROR" };
};

export const FileNoticeToVacate = (payload) => dispatch => {
  dispatch(NOTICE_VACATE_LOADING());
  return Api.post("api/noticeToVacate/fileNotice", payload)
    .then((response) => {
      dispatch(NOTICE_VACATE_COMPLETE());
      dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
    })
    .catch((err) => {
      dispatch(NOTICE_VACATE_ERROR());
      dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
    })
    .catch(() => {
      Notify("danger", "Something Bad Happened :(");
    });
};

export const FileNoticeToVacateWithoutTokenAction = (payload,leaseId, onsuccess) => async dispatch => {
  try {
    dispatch(NOTICE_VACATE_LOADING());
    const result = await Api.post("api/noticeToVacate/file-notice-vacate/"+leaseId, payload)
        dispatch(NOTICE_VACATE_COMPLETE());
        dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
        onsuccess(result?.data?.data);
    } catch (error) {
      dispatch(NOTICE_VACATE_ERROR());
      dispatch({type: "NOTICE_VACATE_LOADING_STOP"});
      Notify("danger", "Something Bad Happened :(");
  }
};

export const recordUserResponseOnVacateEmailAction = (recordId, option, onsuccess, onerror) => async dispatch => {
  try {
   const result =  await Api.get(`api/noticeToVacate/record-user-response-on-vacate-email/${recordId}/${option}`);
   onsuccess(result?.data?.data);
  } catch (error) {
    onerror(error);
   }
};
