import React, { useEffect, useState } from "react";
import thankyouSVG from "./../../assets/img/svg/circle-check-solid.svg";
import { useDispatch } from "react-redux";
import { recordUserResponseOnVacateEmailAction } from "../../redux/actions/noticeToVacate";
import { useParams } from "react-router-dom";
import FullPageLayout from "../../layout/FullPageLayout";
import { useTranslation } from "react-i18next";

const RecordUserResponseOnVacateEmail = () => {
  const dispatch = useDispatch();
  const { recordId, option } = useParams();
  const { t } = useTranslation();

  const [brokenLink, setIsBrokenLink] = useState(false);
  const [response, setResponse] = useState(null);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      recordUserResponseOnVacateEmailAction(
        recordId,
        option,
        (result) => {
          setLoading(false)
          setResponse(result)
          if(!result || result?.error === "1"){
             setIsBrokenLink(true);
          }
        },
        () => {
          setLoading(false);
          setIsBrokenLink(true);
          setResponse(null)
        }
      )
    );
  }, []);

  const renderBrokenLinkMessage = () => {
    return (
      <div className="">
        <h4>{t("modal.notice.no_records_found")}</h4>
        <p>{t("modal.notice.this_could_happen_if")}</p>
        <ul>
          <li>{t("modal.notice.application_has_finalise")}</li>
          <li>{t("modal.notice.the_link_is_broken_or_expired")}</li>
        </ul>
      </div>
    );
  };

  return (
    <FullPageLayout>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "75vh" }}
      >
        {loading ? (
          <>
            <span className="spinner-grow text-primary"></span>
            <span className="">Please wait...</span>
          </>
        ) : brokenLink ? renderBrokenLinkMessage() : (
          <>
            <img src={thankyouSVG} alt="" width={200} />
            <h1 className="mt-3 text-center">{response?.header}</h1>
            <p style={{ fontSize: 14 }}>{response?.message}</p>
          </>
        )}
      </div>
    </FullPageLayout>
  );
};

export default RecordUserResponseOnVacateEmail;
