import { TICKET_DETAILS, GET_SURVEY,ALL_TICKET } from "../actions/maintainance";

const initialState = {
  survey: {},
  globalZZID: "",
  ticketDetails: {},
  maintainence_tickets:[]
};

const MAINTENANCE_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case TICKET_DETAILS: {
      return {
        ...state,
        ticketDetails: payload
      }
    }
    case ALL_TICKET: {
      return {
        ...state,
        maintainence_tickets:payload
      }
    }
    case GET_SURVEY: {
      return {
        ...state,
        survey: payload,
      };
    }
    case "SET_GLOBAL_ZZID": {
      return {
        ...state,
        globalZZID: payload,
      };
    }
    case "CLEAR_GLOBAL_ZZID": {
      return {
        ...state,
        globalZZID: "",
      };
    }
    default:
      return state;
  }
};

export default MAINTENANCE_REDUCER;
