

const initialState = {
    appLoading: false
};

const LOADING_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_APP_LOADING": {
        return {...state, appLoading: payload}
      }
    default:
      return state;
  }
};

export default LOADING_REDUCER;
