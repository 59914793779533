import React, { Component } from 'react';
import { PageSettings } from "./../../config/page-settings.js";

class Gallery extends Component {
  static contextType = PageSettings;

  state = {
    activeImage: ''
  }

  componentDidMount(){
    this.context.handleSetPageSidebar(false);
    this.context.handleSetPageHeader(false);

    const img = this.props.location.search.replace('?', '');
    this.setState({ activeImage: img});
  }

  componentWillUnmount() {
    this.context.handleSetPageSidebar(true);
    this.context.handleSetPageHeader(true);
  }

  render(){
    const {activeImage} = this.state;
    return (
      <div className="gallery-container">
        <a
          href={activeImage}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn btn-info btn-xs">
            <i className="fa fa-download" />
          </button>
        </a>
        <img src={activeImage} alt="alt-img" style={{width: '100%', maxHeight: '100vh', objectFit: 'contain'}}/>
      </div>
    )
  }
}

export default Gallery;
